import dayjs from 'dayjs';
import { checkChristmasDay, isCanadianHoliday } from './holidays';

export const getDateTimeString = (date) => {
  if (!date) return "";

  if (typeof date === 'string') {
    date = new Date(date);
  }
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();
  var hour = date.getHours();
  var minute = date.getMinutes();

  var result = '';
  if (month < 10) result += '0';
  result += month.toString();
  result += '/'

  if (day < 10) result += '0';
  result += day.toString();
  result += '/'

  result += year.toString();

  result += " ";
  if (hour < 10) result += '0';
  result += hour.toString();
  result += ':'

  if (minute < 10) result += '0';
  result += minute.toString();
  return result;
}

export const getNiceFormatDateString = (date, locale = "en") => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const options = {
    month: "long",
    day: "numeric"
  };
  return new Intl.DateTimeFormat(locale, options).format(date);
}

export const getFullDateString = (date, locale = "en") => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  return new Intl.DateTimeFormat(locale, options).format(date);
}

export const getWMDDateString = (date, locale = 'en') => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();

  return {
    heading: new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric' }).format(date),
    label: `${year}-${month}-${day}`,
    date: date.toISOString()
  }
}

export const getNiceFormatDateTimeString = (date, locale = "en", type = "normal") => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  }).format(date);
}

export const getDateFormat_mmddyyyy = (date) => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();

  var result = '';
  if (month < 10) result += '0';
  result += month.toString();
  result += '/'

  if (day < 10) result += '0';
  result += day.toString();
  result += '/'

  result += year.toString();
  return result;
}

export const getDateAndTimeString = (date) => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();

  var hour = date.getHours();
  var minute = date.getMinutes();

  var dayStr = '';
  dayStr += year.toString();
  if (month < 10) dayStr += '0'; dayStr += month.toString();
  if (day < 10) dayStr += '0'; dayStr += day.toString();

  var timeStr = '';
  if (hour < 10) timeStr += '0'; timeStr += hour.toString();
  if (minute < 10) timeStr += '0'; timeStr += minute.toString();

  return { day: dayStr, time: timeStr };
}

export const isSameDay = (day1, day2) => {
  if (!day1 || !day2) return false;
  if (day1.getFullYear() !== day2.getFullYear()) return false;
  if (day1.getMonth() !== day2.getMonth()) return false;
  if (day1.getDate() !== day2.getDate()) return false;
  return true;
}

export const isTodayORTomorrowSession = (date) => {
  if (!date) return false;

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const now = new Date();

  const year = now.getFullYear(), month = now.getMonth(), day = now.getDate();
  const tomorrow = new Date(year, month, day + 2, 0, 0, 0);

  if (date >= new Date() && date < tomorrow) return true;
  return false;
}

export const getLabelGroupByMonth = (date, locale = "en") => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }
  const options = {
    year: "numeric",
    month: "long",
  };
  return new Intl.DateTimeFormat(locale, options).format(date);
}

export const getLabelGroupByDay = (date, locale = "en") => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  return new Intl.DateTimeFormat(locale, options).format(date);
}

export const delayTime = (t) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, t)
  })
}

export const getSchoolYear = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;

  if (currentMonth < 7) {
    const schoolYearStart = new Date(currentYear - 1, 7, 1);
    const schoolYearEnd = new Date(currentYear, 6, 30);
    return { start: schoolYearStart, end: schoolYearEnd };
  } else {
    const schoolYearStart = new Date(currentYear, 7, 1);
    const schoolYearEnd = new Date(currentYear + 1, 6, 30);
    return { start: schoolYearStart, end: schoolYearEnd };
  }
}

export const getShortDateTimeString = (date, locale = "en") => {
  if (!date) return '';

  if (typeof date === 'string') {
    date = new Date(date);
  }

  return Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  }).format(date);
}

export const getSimpleDateString = (currentDate) => {
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * 
 * @typedef {object} Props
 * @property {Date} date
 * @property {Date[]} availableDates 
 * @property {Date[]} notAvailableDates 
 * @property {number[]} dayLimitations
 * @property {number} dayRestriction
 * @property {string} tillDate
 * @property {*} teacher
 * 
 * @param {Props}
 */
const validateSessionDate = ({
  date,
  availableDates,
  notAvailableDates,
  dayLimitations,
  dayRestriction,
  tillDate,
  teacher
}) => {
  const jDate = dayjs(date);
  const today = new Date();

  if (tillDate) {
    const d = new Date(tillDate);
    if (date > d) {
      return {
        valid: false,
        nextDay: null
      };
    }
  }

  if (jDate.isBefore(today, 'day')) {
    return {
      valid: false,
      nextDay: null
    }
  }

  const restrictionDays = dayRestriction || 14;
  const restrictedDate = new Date();
  restrictedDate.setDate(today.getDate() + restrictionDays);
  if (jDate.isBefore(restrictedDate, 'day')) {
    const nDate = new Date(date);
    nDate.setDate(date.getDate() + 1);
    return {
      valid: false,
      nextDay: null
    }
  }

  const xmasData = checkChristmasDay(date);
  if (xmasData.status) {
    return {
      valid: false,
      nextDay: xmasData.date
    }
  }

  // Check if it's a holiday
  if (isCanadianHoliday(date, teacher)) {
    const nDate = new Date(date);
    nDate.setDate(date.getDate() + 1);
    return {
      valid: false,
      nextDay: nDate
    }
  }

  const dayOfWeek = date.getDay();
  if (dayOfWeek === 0) { // Sunday
    const nDate = new Date(date);
    nDate.setDate(date.getDate() + 1); // Move to Monday
    return {
      valid: false,
      nextDay: nDate
    }
  } else if (dayOfWeek === 6) { // Saturday
    const nDate = new Date(date);
    nDate.setDate(date.getDate() + 2); // Move to Monday
    return {
      valid: false,
      nextDay: nDate
    }
  }

  if (notAvailableDates && notAvailableDates.length) {
    const sds = getSimpleDateString(date);
    const isNotAvailable = notAvailableDates.some(nao => sds === getSimpleDateString(nao));
    if (isNotAvailable) {
      const nDate = new Date(date);
      nDate.setDate(date.getDate() + 1);
      return {
        valid: false,
        nextDay: nDate
      }
    }
  }

  if (availableDates && availableDates.length) {
    const available = availableDates.find((d) => {
      return jDate.isSame(d, 'day');
    });
    if (!available) {
      return {
        valid: false,
        nextDay: null
      }
    }
  }

  if (dayLimitations && dayLimitations.length) {
    if (dayLimitations.indexOf(dayOfWeek) >= 0) {
      return {
        valid: true
      }
    }

    const nDate = new Date(date);
    nDate.setDate(date.getDate() + 1);
    return {
      valid: false,
      nextDay: nDate
    }
  }

  return {
    valid: true
  };
}

/**
 * 
 * @param {number[]} dayLimitations 
 * @param {dayjs.Dayjs} currentDate 
 * @param {number} dayRestrictionConfig 
 * @param {*} userInfo 
 * @param {string} tillDate 
 * @param {Date[] | undefined} availableDates 
 * @param {Date[] | undefined} notAvailableDates 
 * @returns 
 */
export const isValidDateOnPicker = (
  dayLimitations,
  currentDate,
  dayRestrictionConfig,
  userInfo,
  tillDate = null,
  availableDates,
  notAvailableDates
) => {
  const date = currentDate.toDate();
  const validating = validateSessionDate({
    date,
    availableDates,
    notAvailableDates,
    dayLimitations,
    dayRestriction: dayRestrictionConfig,
    tillDate,
    teacher: userInfo
  });
  return validating.valid;
};

/**
 * 
 * @typedef {object} Props
 * @property {Date[]} availableDates 
 * @property {Date[]} notAvailableDates 
 * @property {number[]} dayLimitations
 * @property {number} dayRestriction
 * @property {string} tillDate
 * @property {*} teacher
 * 
 * @param {Props}
 */
export const selectEarliestSessionDates = ({
  availableDates,
  notAvailableDates,
  dayLimitations,
  dayRestriction,
  tillDate,
  teacher
}, n = 2) => {
  let offset = 14;
  if (dayRestriction) {
    offset = dayRestriction;
  }

  if (availableDates.length) {
    const validDates = availableDates.filter(date => {
      const jDate = dayjs(date);
      return isValidDateOnPicker(
        dayLimitations,
        jDate,
        dayRestriction,
        teacher,
        tillDate,
        availableDates,
        notAvailableDates
      )
    });
    const results = validDates.sort((a, b) => a > b ? 1 : -1).slice(0, n);
    while (results.length < n) { results.push(null); }
    return results;
  }

  const results = new Array(n).fill(null);
  for (let i = 0; i < n; i++) {
    const now = new Date();
    let targetDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset + 14 * i, 10, 0, 0);

    let attempts = 0;
    const maxAttempts = 30;
    while (attempts < maxAttempts) {
      attempts++;
      const validating = validateSessionDate({
        date: targetDate,
        availableDates,
        notAvailableDates,
        dayLimitations,
        dayRestriction,
        teacher,
        tillDate
      });

      if (validating.valid) {
        results[i] = targetDate;
        break;
      } else {
        targetDate = validating.nextDay;
      }
    }
  }
  return results;
}
