import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DateSelector = ({ shouldDisable, onConfirm, onToggle }) => {
  return (
    <Modal
      isOpen={true}
      className="modal-dialog-centered"
      size="sm"
      toggle={onToggle}
    >
      <ModalBody>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDatePicker
            shouldDisableDate={shouldDisable}
            onClose={() => onToggle()}
            onAccept={(v) => {
              if (!v) return;
              onConfirm(v.toDate())
            }}
          />
        </LocalizationProvider>
      </ModalBody>
    </Modal>
  )
}

export default DateSelector;