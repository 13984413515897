import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import SessionInfo from "../../components/SessionInfo";
import BookSessionModal from "../../pages/modals/BookSession";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Filter } from "./components";
import { useSelector } from "react-redux";
import { getDisplayInfos } from "../SessionInfoDisplay";
import { TokenBlue } from "../../pages/Credit/assets/token-blue";
import s from "./styles.module.scss";
import { useFilter } from "./useFilter";

const CurriculumDetail = (props) => {
	const {
		match: {
			params: { cId },
		},
	} = props;
	const { t } = useTranslation();
	const { userInfo, userType } = useSelector((state) => state.appInfo);
	const [showBookSessionModal, setShowBookSessionModal] = useState(false);
	const [activeSession, setActiveSession] = useState(null);

	useEffect(() => {
		window.document.title = "Curriculum Detail - Connected North";
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const p = useFilter(cId);
	const { loading, link, sessions } = p;

	return (
		<div className="main-container mb-5">
			<div className="d-flex justify-content-between align-items-center">
				<Breadcrumb heading={link["Strand"] ?? ""} />
			    {userType !== 'Team' ? (
				<p className={s.myCredit}>
				    <span>
					<TokenBlue />
				    </span>
				    &nbsp; {t("my-booking-credits")}: {userInfo["Credit Balance"]}
				</p>
			    ) : null}
			</div>

			<h1 className={s.h1}>{`${link["Strand"] ?? ""}`}</h1>

			<section className={s.container}>
				{/* Filter */}
				<div className={s.filter}>
					<Filter {...p} />
				</div>

				{/* Sessions List */}
				<div className={s.sessions}>
					{sessions && sessions.length ? (
						sessions.map((session) => (
							<div key={session["id"]}>
								<SessionInfo
									displayInfos={getDisplayInfos(session, userType)}
									actionMenu={[
										"copy-provider-session-link",
										"open-detail",
									]}
									session={session}
									sessionDetailLink={session.id}
									bookSession={() => {
										setActiveSession(session);
										setShowBookSessionModal(true);
									}}
								/>
							</div>
						))
					) : loading ? null : (
						<div className="nolist-placeholder" align="center">
							<span>{t("no-curriculum")}</span>
						</div>
					)}
				</div>
			</section>

			{loading && (
				<div style={{ marginTop: 30, textAlign: "center" }}>
					<Loading size={20} />
				</div>
			)}

			{activeSession && showBookSessionModal && (
				<BookSessionModal
					providerSessionId={activeSession.id}
					onToggle={() => {
						setShowBookSessionModal(false);
						setActiveSession(null);
					}}
				/>
			)}
		</div>
	);
};

export default CurriculumDetail;
