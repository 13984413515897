import { field_types } from "../constants/enumerated-types";

export function convertStringToType(value, type) {
	if (!field_types.includes(type)) {
		return value;
	}

	switch (type) {
		case "number":
			return value;
		case "date":
			return new Date(value);
		case "multi select":
			if (typeof value === "string") {
				try {
					return JSON.parse(value);
				} catch {
					return [];
				}
			}
			return Array.isArray(value) ? value : [];
		case "short text":
		case "long text":
		case "single select":
		case "radio":
			return value;
		case "file":
			if (!value) {
				return [];
			}
			return JSON.parse(value).map((file) => ({
				name: file.name,
				type: file.type,
				lastModified: file.lastModified,
				size: file.size,
				webkitRelativePath: file.webkitRelativePath,
			}));
		case "checkbox":
			return Boolean(value);
		default:
			return value;
	}
}

export function convertTypeToString(value, type) {
	if (!field_types.includes(type)) {
		throw new Error(`Unsupported type: ${type}`);
	}

	switch (type) {
		case "number":
			return value.toString();
		case "date":
			return value;
		case "multi select":
			return Array.isArray(value) ? JSON.stringify(value) : "[]";
		case "short text":
		case "long text":
		case "single select":
		case "radio":
			return value ? value.toString() : "";
		case "checkbox":
			return value ? "true" : "false";
		case "file":
			return Array.isArray(value)
				? JSON.stringify(
						value.map((file) => ({
							name: file.name,
							type: file.type,
							lastModified: file.lastModified,
							size: file.size,
							webkitRelativePath: file.webkitRelativePath,
						})),
					)
				: "[]";
		default:
			throw new Error(`Unsupported type: ${type}`);
	}
}
