import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Modal
} from 'reactstrap';
import Loading from '../../../components/Loading';
import airtable from '../../../airtables';
import { DAY_LIMITATIONS_MAP, getDayRestrictionConfig } from '../../../utils/constants';
import { isValidDateOnPicker } from '../../../utils/time';
import { DateTimePicker, LocalizationProvider, CalendarIcon } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { IconButton } from '@mui/material';
import SessionValidationChecker from '../../../components/SessionValidationChecker';
import { useSelector } from 'react-redux';

const SessionReject = ({ providerSession, session, sessionId, onToggle, onPost }) => {
  const { userInfo } = useSelector(state => state.appInfo);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [startDate, setStartDate] = useState();
  const [alterDate, setAlterDate] = useState();
  const [preferredTimeValid, setPreferredTimeValid] = useState([]);
  const [alternativeTimeValid, setAlternativeTimeValid] = useState([]);
  const [commentValid, setCommentValid] = useState();
  const [dayRestriction, setDayRestriction] = useState(0);
  const [dayLimitations, setDayLimitations] = useState([]);
  const [openPreferTime, setOpenPreferTime] = useState(false);
  const [openAlterTime, setOpenAlterTime] = useState(false);
  const [availableDates, setAvailableDates] = useState(undefined);
  const [provider, setProvider] = useState(null);
  const [isStartTimeValid, setIsStartTimeValid] = useState("");
  const [isAlterTimeValid, setIsAlterTimeValid] = useState("");
  const [pSession, setPSession] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    const sess = await airtable.sessions.select(sessionId);
    const providerInfo = await airtable.providers.select(sess["Provider"][0]);
    if (!providerSession) {
      const ps = await airtable.providerSessions.select(sess["Session Title"][0]);
      setPSession(ps);
    }
    setProvider(providerInfo);
    if (providerInfo["Day Limitations"]) {
      const dl = providerInfo["Day Limitations"].map(l => DAY_LIMITATIONS_MAP[l]);
      setDayLimitations(dl)
    } else {
      setDayLimitations([]);
    }

    let supply = null;
    if (providerSession?.Supplies && providerSession?.Supplies.length) {
      supply = await airtable.supplies.select(providerSession?.["Supplies"][0]);
    }
    const dayRestrictionConfig = getDayRestrictionConfig(supply)
    setDayRestriction(dayRestrictionConfig || 14);

    if (session["Valid OAO"] === "Valid" && session["Only Available On"]) {
      const ds = session["Only Available On"].split(',').map(d => {
        const date = new Date(d);
        return new Date(Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          12, 0, 0
        ));
      });
      const now = new Date();
      const minDate = new Date(now);
      minDate.setDate(minDate.getDate() + (dayRestrictionConfig || 14)); // Default to 14 if not specified
      const aDates = ds.filter(d => d >= minDate).sort((a, b) => a - b);
      setAvailableDates(aDates);
    }

    setLoading(false);
  }

  const canSave = () => {
    if (loading) return false;
    if (isStartTimeValid !== "valid") return false;
    if (isAlterTimeValid !== "valid") return false;
    return true;
  }

  const onSubmit = async () => {
    if (submitting) return;
    if (commentValid === 'invalid') return;
    if (!startDate) return;
    if (!alterDate) return;

    if (preferredTimeValid.indexOf('invalid') >= 0) {
      alert("The Preferred Date & Time is invalid.");
      return;
    }

    setSubmitting(true);

    const recentSessionInfo = await airtable.sessions.select(sessionId);
    const schedulingAttempts = (recentSessionInfo["Scheduling Attempts"] || 0) + 1

    airtable.sessions.update(
      sessionId,
      {
        "Teacher Booking Response": `${comment}`,
        "Session Start Date/Time": `${startDate.toString()}`,
        "Alternative Date/Time": `${alterDate.toString()}`,
        "Status": "Pending Provider Response",
        "Notify Teacher of Provider Response": false,
        "Scheduling Attempts": schedulingAttempts
      }
    ).then(async (res) => {
      await fetch(`https://hooks.zapier.com/hooks/catch/89715/37q7as9?sessionID=${sessionId}&action=reject`, {
        method: "GET",
        mode: "no-cors",
      });
      onToggle();
      if (onPost) onPost();
    }).catch(error => {
      alert(error.toString());
    });
  }

  const pSess = providerSession || pSession;

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={onToggle}
      backdrop={false}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Propose Alternates (Neither time is available)</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            if (submitting) return;
            onToggle()
          }}
        ><span aria-hidden={true}>×</span></button>
      </div>
      <div className="modal-body">
        <label>Please provide details on future availability/ideal booking times for the provider:</label>
        <Form>
          <FormGroup>
            <Input
              type="textarea"
              style={{ minHeight: 100 }}
              onChange={(e) => {
                setComment(e.target.value)
                setCommentValid(!e.target.value ? 'invalid' : 'valid')
              }}
              invalid={commentValid === 'invalid'}
              disabled={submitting}
            />
          </FormGroup>
          <label>
            Can you propose two ideal dates and times for the provider to review?
          </label>
          <FormGroup>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                open={openPreferTime}
                label={t("sessionrequest.prefer-time")}
                shouldDisableDate={(currentDate) => !isValidDateOnPicker(
                  dayLimitations,
                  currentDate,
                  dayRestriction,
                  userInfo,
                  pSess?.["Availability End Date"],
                  availableDates
                )}
                minutesStep={5}
                ampm
                value={startDate ? dayjs(startDate) : null}
                onChange={(e) => {
                  if (typeof e !== 'string') {
                    const valids = [];
                    const selectedDate = e.toDate();
                    const selectedHour = selectedDate.getHours();
                    if (selectedDate < new Date()) valids.push("past-time")
                    if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                    setPreferredTimeValid(valids)
                    setStartDate(selectedDate);
                  }
                }}
                onClose={() => setOpenPreferTime(false)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: preferredTimeValid.indexOf('invalid') >= 0,
                    disabled: submitting,
                    className: "cn-datetime-picker",
                    InputProps: {
                      endAdornment: (
                        <Fragment>
                          {startDate && (
                            <span
                              className={classNames({
                                "end-adornment": true,
                                "end-adornment-normal": session["Length (Minutes)"] < 90,
                                "end-adornment-bold": session["Length (Minutes)"] >= 90
                              })}
                            >
                              until {dayjs(startDate).add(session["Length (Minutes)"], 'minute').format('h:mm A')}
                            </span>
                          )}
                          <IconButton onClick={() => setOpenPreferTime(!openPreferTime)}>
                            <CalendarIcon />
                          </IconButton>
                        </Fragment>
                      )
                    }
                  },
                }}
              />
            </LocalizationProvider>
            {!!session && !!provider && !!userInfo && !!startDate && !!pSess && (
              <SessionValidationChecker
                time={startDate}
                session={pSess}
                provider={provider}
                teachers={userInfo}
                bookedSessionId={session.id}
                changeValidation={v => setIsStartTimeValid(v)}
                sessionLength={pSess?.["Length (Minutes)"]}
              />
            )}
          </FormGroup>
          <FormGroup>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                open={openAlterTime}
                label={t("sessionrequest.alternative-time")}
                shouldDisableDate={(currentDate) => !isValidDateOnPicker(
                  dayLimitations,
                  currentDate,
                  dayRestriction,
                  userInfo,
                  pSess?.["Availability End Date"],
                  availableDates
                )}
                minutesStep={5}
                ampm
                value={alterDate ? dayjs(alterDate) : null}
                onChange={(e) => {
                  if (typeof e !== 'string') {
                    const valids = [];
                    const selectedDate = e.toDate();
                    const selectedHour = selectedDate.getHours();
                    if (selectedDate < new Date()) valids.push("past-time")
                    if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
                    setAlternativeTimeValid(valids);
                    setAlterDate(selectedDate);
                  }
                }}
                onClose={() => setOpenAlterTime(false)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: alternativeTimeValid.indexOf('invalid') >= 0,
                    disabled: submitting,
                    className: "cn-datetime-picker",
                    InputProps: {
                      endAdornment: (
                        <Fragment>
                          {alterDate && (
                            <span
                              className={classNames({
                                "end-adornment": true,
                                "end-adornment-normal": session["Length (Minutes)"] < 90,
                                "end-adornment-bold": session["Length (Minutes)"] >= 90
                              })}
                            >
                              until {dayjs(alterDate).add(session["Length (Minutes)"], 'minute').format('h:mm A')}
                            </span>
                          )}
                          <IconButton onClick={() => setOpenAlterTime(!openAlterTime)}>
                            <CalendarIcon />
                          </IconButton>
                        </Fragment>
                      )
                    }
                  }
                }}
              />
            </LocalizationProvider>
            {!!session && !!provider && !!userInfo && !!alterDate && !!pSess && (
              <SessionValidationChecker
                time={alterDate}
                session={pSess}
                provider={provider}
                teachers={userInfo}
                bookedSessionId={session.id}
                changeValidation={v => setIsAlterTimeValid(v)}
                sessionLength={pSess?.["Length (Minutes)"]}
              />
            )}
          </FormGroup>
        </Form>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        >Close</Button>
        <Button
          color="success"
          type="button"
          onClick={() => onSubmit()}
          disabled={submitting || !canSave()}
        >{submitting && (<Loading size={14} />)}Submit Response</Button>
      </div>
    </Modal>
  )
}

export default SessionReject;
