import React, { Fragment, useEffect, useState } from "react";
import LoadingBar from '../../components/LoadingBar'
import { getLabelGroupByDay, getLabelGroupByMonth } from "../../utils/time";
import { useSelector } from "react-redux";
import airtable from '../../airtables';
import { Button } from "reactstrap";
import SessionItem from '../../components/SessionItem';
import { isTodayORTomorrowSession } from '../../utils/time';
import VimeoPlayer from '../modals/VimeoPlayer';
import { getProviderSessionIDFromSession } from '../../utils/session';
import SendToTeacher from "../modals/SendToTeacher";
import AddTeacherToEventModal from "../modals/AddTeacherToEvent";
import AdditionalQuestionModal from "../modals/AdditionalQForEvent";

/**
 * 
 * @param {boolean} loading 
 * @param {array<*>} sessions 
 * @param {Function} updateProcessing 
 * @param {Function} updateSessions
 * @param {string} groupBy - Should be either 'month' or 'day'.
 * @param {Function} refresh
 * @returns 
 * 
 */
const SessionList = ({
  loading,
  sessions,
  updateProcessing,
  updateSessions,
  groupBy = "month",
  askable = false,
  refresh,
  additional,
  pdweek = false
}) => {
  const { userInfo, appLocale, userType } = useSelector(state => state.appInfo);
  const [sessionMap, setSessionMap] = useState({});
  const [keys, setKeys] = useState([]);
  const [videoSession, setVideoSession] = useState();
  const [showVideo, setShowVideo] = useState(false);

  const [showSendMsgModal, setShowSendMsgModal] = useState(false);
  const [activeSendMsgEvent, setActiveSendMsgEvent] = useState(null);

  const [showAddTeachersModal, setShowAddTeachersModal] = useState(false);
  const [activeAddTeachersEvent, setActiveAddTeachersEvent] = useState(null);

  const [additionalQModal, setAdditionalQModal] = useState(false);
  const [activeAQEvent, setActiveAQEvent] = useState(null);

  useEffect(() => {
    if (!sessions || sessions.length === 0) {
      setSessionMap({});
      setKeys([]);
    } else {
      const groupByCalc = groupBy === 'month' ? getLabelGroupByMonth : getLabelGroupByDay;

      const sMap = sessions.reduce((result, sess, id) => {
        const heading = groupByCalc(sess["Session Start Date/Time"], appLocale);
        if (heading) {
          if (result[heading]) {
            result[heading].push({ session: sess, no: id });
          } else {
            result[heading] = [{ session: sess, no: id }];
          }
        }
        return result;
      }, {});

      setSessionMap(sMap);
      setKeys(Object.keys(sMap).sort((a, b) => {
        const timeA = new Date(a), timeB = new Date(b);
        return timeA.getTime() - timeB.getTime()
      }));
    }
  }, [sessions, appLocale, groupBy])

  const cancelRegistration = async (session, no) => {
    if (window.confirm("Are you sure you wish to cancel your registration for this event?")) {
      updateProcessing(true);

      let sessionSchoolIds = session["School(s)"];
      const teacherIds = session["Teacher"].filter(tn => tn !== userInfo.id);
      if (userInfo["School Name"] && userInfo["School Name"].length) {
        const sName = userInfo["School Name Text"][0];
        if (session["School Name Text"] && session["School Name Text"].indexOf(sName) >= 0) {
          const teachers = await airtable.teachers.listByIds(teacherIds);
          const shouldRemoveSchool = teachers.reduce((result, teacher) => {
            if (!result) return false;
            if (!teacher["School Name Text"] || teacher["School Name Text"].length === 0) return result;
            if (teacher["School Name Text"].indexOf(sName) < 0) return result;
            return false;
          }, true);

          if (shouldRemoveSchool) {
            sessionSchoolIds = session["School(s)"].filter(sId => sId !== userInfo["School Name"][0]);
          }
        }
      }

      const newSession = await airtable.sessions.update(session.id, {
        Teacher: teacherIds,
        "School(s)": sessionSchoolIds
      });
      await airtable.events.deleteEvent(userInfo["Email"], session.Session);
      let nSessions = [...sessions]; nSessions[no] = newSession;
      updateSessions(nSessions);
      updateProcessing(false);
    }
  }

  const cancelRegistrationPending = async (session, no) => {
    if (window.confirm("Are you sure you wish to cancel your registration for this event?")) {
      updateProcessing(true);
      await airtable.events.deleteEvent(userInfo["Email"], session.Session);
      const newSession = await airtable.sessions.select(session.id);
      let nSessions = [...sessions]; nSessions[no] = newSession;
      updateSessions(nSessions);
      updateProcessing(false);
    }
  }

  const requestRegisterSeries = async (session) => {
    updateProcessing(true);
    const seriesSessions = await airtable.sessions.getSeriesSessions(session["Series"]);
    await airtable.events.createMultiple(seriesSessions.map(sess => ({
      Name: userInfo["Teacher Name"],
      "E-mail Address": userInfo["Email"],
      School: [userInfo["School Name"][0]],
      Session: [sess.id]
    })))
    refresh();
  }

  const requestRegister = async (session, no) => {
    updateProcessing(true);
    await airtable.events.create({
      Name: userInfo["Teacher Name"],
      "E-mail Address": userInfo["Email"],
      School: [userInfo["School Name"][0]],
      Session: [session.id]
    })
    const newSession = await airtable.sessions.select(session.id);
    let nSessions = [...sessions]; nSessions[no] = newSession;
    updateSessions(nSessions);
    updateProcessing(false);
  }

  const registerWithAdditionalQuestions = async (students, grades) => {
    await airtable.events.create({
      "Name": userInfo["Teacher Name"],
      "E-mail Address": userInfo["Email"],
      "School": [userInfo["School Name"][0]],
      "Session": [activeAQEvent.id],
      "How many students will attend?": students,
      "Grade(s) Attending": grades
    })
    const newSession = await airtable.sessions.select(activeAQEvent.id);

    const no = sessions.findIndex(session => session.id === activeAQEvent.id);
    let nSessions = [...sessions]; nSessions[no] = newSession;
    updateSessions(nSessions);
  }

  const canJoin = (session) => {
    if (userType === 'Teacher') {
      return session.Teacher && session.Teacher.indexOf(userInfo.id) >= 0 && isTodayORTomorrowSession(session["Session Start Date/Time"]) && session["WebEx/Zoom Link"];
    }

    return true;
  }

  const renderRegistrationStatus = (session, i) => {
    if (userType !== 'Teacher') return null;

    if (session.Teacher && session.Teacher.indexOf(userInfo.id) >= 0) {
      return (
        <>
          <Button className='btn-session' color="link" disabled={true} size="sm">Registered</Button>
          <Button
            className='btn-session'
            color="danger"
            size="sm"
            onClick={(e) => cancelRegistration(session, i)}
          >Cancel Registration</Button>
        </>
      )
    }

    if (session["Premium Registration Closed"]) {
      return (
        <>
          <Button className='btn-session' color="link" disabled={true} size="sm">Registration Closed</Button>
        </>
      )
    }

    if (Array.isArray(session["Premium Registrations"]) && session["Premium Registrations"].indexOf(userInfo.Email) >= 0) {
      if (typeof session["Custom Session Title"] === 'string' && session["Custom Session Title"].includes("PD Week")) {
        return (
          <>
            <Button className='btn-session' color="link" disabled={true} size="sm">Registered</Button>
            {isTodayORTomorrowSession(session["Session Start Date/Time"]) && session["WebEx/Zoom Link"] ? (
              <Button
                className='btn-session'
                color="success"
                size="sm"
                onClick={(e) => window.open(session["WebEx/Zoom Link"], "_blank")}
              >Join</Button>
            ) : (
              <Button
                className='btn-session'
                color="danger"
                size="sm"
                onClick={(e) => cancelRegistrationPending(session, i)}
              >Cancel Registration</Button>
            )}
          </>
        )
      } else {
        return (
          <>
            <Button className='btn-session' color="link" disabled={true} size="sm">Registration Pending</Button>
            <Button
              className='btn-session'
              color="danger"
              size="sm"
              onClick={(e) => cancelRegistrationPending(session, i)}
            >Cancel Registration</Button>
          </>
        )
      }
    }

    if (session["Capacity (Premium Sessions)"] && (session["# Schools"] >= session["Capacity (Premium Sessions)"])) {
      return (
        <Button
          className='btn-session'
          color="warning"
          size="sm"
          onClick={(e) => requestRegister(session, i)}
        >Session Full - Join Waitlist</Button>
      )
    }

    if (session["Series"] && String(session["Session Title"]) === 'rectuVp6Nto2d2RWK') {
      return (
        <Fragment>
          <Button
            className='btn-session'
            color="success"
            size="sm"
            onClick={(e) => requestRegisterSeries(session, i)}
          >Register for Series</Button>
        </Fragment>
      )
    } else if (session["Series"]) {
      return (
        <Fragment>
          <Button
            className='btn-session'
            color="success"
            size="sm"
            onClick={(e) => requestRegisterSeries(session, i)}
          >Register for Series</Button>
          <Button
            className='btn-session'
            color="success"
            size="sm"
            onClick={(e) => requestRegister(session, i)}
          >Register for Single Session</Button>
        </Fragment>
      )
    }

    return (
      <Button
        className='btn-session'
        color="success"
        size="sm"
        onClick={(e) => {
          if (additional) {
            setAdditionalQModal(true);
            setActiveAQEvent(session);
          } else {
            requestRegister(session, i)
          }
        }}
      >Register</Button>
    );
  }

  if (!loading && (!sessions || sessions.length === 0)) {
    return (<div className="general-container events-page">
      <span>There are currently no Events or Event Registrations to display in this view.</span>
    </div>)
  }

  return (
    <Fragment>
      {loading ? <LoadingBar /> : null}
      <div className="general-container events-page">
        {
          loading ? null : (
            keys.map((k, i) => (
              <div key={i}>
                <h4 className='monthly-group'><b>{k}</b></h4>
                <div>{
                  sessionMap[k].map((sessInfo, j) => (
                    <div key={j}>
                      <SessionItem
                        session={sessInfo.session}
                        viewType="events"
                        pdweek={pdweek}
                      >
                        <div className="buttons">
                          {renderRegistrationStatus(sessInfo.session, sessInfo.no)}

                          {
                            sessInfo.session["Flyer"] ? (
                              <Button
                                className='btn-session'
                                color="primary"
                                size="sm"
                                onClick={(e) => window.open(sessInfo.session["Flyer"][0].url, "_blank")}
                              >View Flyer</Button>
                            ) : null
                          }
                          {
                            (String(sessInfo.session["Session Title"]) === 'recGdS3kxweS8E5s7' || String(sessInfo.session["Session Title"]) === 'recGY7aPO5N9YdJ0d') && (userType === "Team" || (sessInfo.session.Teacher && sessInfo.session.Teacher.indexOf(userInfo.id) >= 0)) ? (
                              <Button
                                className='btn-session'
                                color="primary"
                                size="sm"
                                onClick={(e) => window.location.href = '/cn/session/' + sessInfo.session["Session Title"]}
                              >View Video(s)</Button>
                            ) : null
                          }
                          {
                            String(sessInfo.session["Session Title"]) === 'reckUt9B0PdPaPwsm' && (userType === "Team" || (sessInfo.session.Teacher && sessInfo.session.Teacher.indexOf(userInfo.id) >= 0)) ? (
                              <Button
                                className='btn-session'
                                color="primary"
                                size="sm"
                                onClick={(e) => window.location.href = '/cn/session/' + sessInfo.session["Session Title"]}
                              >Slides & Recordings</Button>
                            ) : null
                          }
                          {
                            userType === "Team" || canJoin(sessInfo.session) ? (
                              <Button
                                className='btn-session'
                                color="success"
                                size="sm"
                                onClick={(e) => window.open(sessInfo.session["WebEx/Zoom Link"], "_blank")}
                              >Join</Button>
                            ) : null
                          }

                          {!!askable && sessInfo.session["Status"] === 'QuestionsOn' && (userType === 'Teacher' || userType === 'Team') && sessInfo.session["Status"] !== 'Completed' && (
                            <Button
                              className='btn-session'
                              size="sm"
                              onClick={(e) => window.open(`/cn/ask-a-question/${sessInfo.session.id}`, "_blank")}
                            >Ask a question</Button>
                          )}

                          {(userType === 'Team' && sessInfo.session["# of Questions"] > 0) && (
                            <Button
                              className='btn-session'
                              size="sm"
                              onClick={(e) => window.open(`/cn/ask-a-question/${sessInfo.session.id}`, "_blank")}
                            >View questions</Button>
                          )}
                          {
                            sessInfo.session["Recording Embed Link"] ? (
                              <Button
                                className='btn-session'
                                color="default"
                                size="sm"
                                onClick={(e) => {
                                  setVideoSession(sessInfo.session)
                                  setShowVideo(true);
                                }}
                              >View Recording</Button>
                            ) : null
                          }
                          {
                            sessInfo.session["Handouts"] ? (
                              <Button
                                className='btn-session'
                                color="info"
                                size="sm"
                                onClickCapture={() => window.open(`/cn/session/${getProviderSessionIDFromSession(sessInfo.session)}`)}
                              >View Handouts</Button>
                            ) : null
                          }

                          {(
                            (userType === "Team" && userInfo["Status"] !== 'Session Host') ||
                            (userType === 'Teacher' && (userInfo["In-school Coordinator"] || userInfo["Cluster Leadership"]))) && (
                              <Button
                                className='btn-session'
                                size="sm"
                                onClick={(e) => {
                                  setActiveSendMsgEvent(sessInfo.session);
                                  setShowSendMsgModal(true);
                                }}
                              >Send{" "}<i className="fa fa-envelope" /></Button>
                            )}

                          {userType === 'Team' && (
                            <Button
                              className='btn-session'
                              size="sm"
                              onClick={(e) => {
                                setShowAddTeachersModal(true);
                                setActiveAddTeachersEvent(sessInfo.session);
                              }}
                            >Add Teacher(s){" "}</Button>
                          )}
                        </div>
                      </SessionItem>
                    </div>
                  ))
                }</div>
              </div>
            ))
          )
        }

        {
          (videoSession && showVideo) && (
            <VimeoPlayer
              title={videoSession['Session Title Text']}
              onToggle={() => setShowVideo(!showVideo)}
              vimeo={videoSession["Recording Embed Link"]}
              onPlay={async () => {
                const providerSessionId = getProviderSessionIDFromSession(videoSession);
                if (!userType === "Teacher") return;
                if (userInfo["Recordings Watched"] && userInfo["Recordings Watched"].indexOf(providerSessionId) >= 0) return;

                let recordings = [];
                if (!userInfo["Recordings Watched"]) recordings = [providerSessionId]
                else recordings = [...userInfo["Recordings Watched"], providerSessionId];

                await airtable.teachers.update(userInfo.id, { "Recordings Watched": recordings });
              }}
            />
          )
        }

        {showSendMsgModal && !!activeSendMsgEvent && (
          <SendToTeacher
            data={activeSendMsgEvent}
            onToggle={() => setShowSendMsgModal(!showSendMsgModal)}
            type="Event"
          />
        )}

        {showAddTeachersModal && !!activeAddTeachersEvent && (
          <AddTeacherToEventModal
            session={activeAddTeachersEvent}
            onToggle={() => {
              setShowAddTeachersModal(false);
              setActiveAddTeachersEvent(null);
            }}
          />
        )}

        {additionalQModal && !!activeAQEvent && (
          <AdditionalQuestionModal
            event={activeAQEvent}
            onToggle={() => {
              setAdditionalQModal(false);
              setActiveAQEvent(null);
            }}
            onFinish={registerWithAdditionalQuestions}
          />
        )}
      </div>
    </Fragment>
  )
}

export default SessionList;
