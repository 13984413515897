import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import logoImg from '../../assets/img/logo1.png';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAppUserInfo, setAppUserType } from '../../redux/actions';
import { setSession as saveSession } from "../../utils/session";
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from '../../config';
import './styles.scss';
import { analytics } from '../../firebase';
import airtable from '../../airtables';

const rates = [1, 2, 3, 4, 5];

const Screen = ({ match: { params: { providerId, sessionId, initialRating = 0 } } }) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [isOwner, setIsOwner] = useState(true);
    const [isRated, setIsRated] = useState(false);
    const [rating, setRating] = useState(parseInt(initialRating));
    const [ratingFinished, setRatingFinished] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [saving, setSaving] = useState(false);
    const [session, setSession] = useState();
    const [height, setHeight] = useState(0);
    const [presenter, setPresenter] = useState(null);
    const [provider, setProvider] = useState(null);
    const [error, setError] = useState("");
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.document.title = "Rate - Connected North"
        setTimeout(resizeListener);

        initialize();

        window.addEventListener('resize', resizeListener);
        return () => {
            window.removeEventListener("resize", resizeListener);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const resizeListener = () => {
        setHeight(window.innerHeight);
    }

    const initialize = async () => {
        const params = new URLSearchParams(location.search);
        let p;
        if (params.has("presenter")) {
            const presenterId = params.get("presenter");
            if (presenterId) {
                p = await airtable.presenters.select(presenterId)
                if (p) setPresenter(p);
            }
        }

        if (!p) {
            const pro = await airtable.providers.select(providerId);
            if (pro) setProvider(pro);
        }

        const sess = await airtable.sessions.select(sessionId);
        setSession(sess);

        if (!sess) {
            setError(t("no-such-session"));
            setLoading(false);
        } else if (!sess || sess["Provider"].indexOf(providerId) < 0) {
            setIsOwner(false);
            setLoading(false);
        } else if (sess["Provider Rating"]) {
            setRating(sess["Provider Rating"]);
            setFeedback(sess["Provider Feedback"]);

            // setIsRated(true);
            setLoading(false);
        } else {
            await airtable.sessions.update(sessionId, {
                "Provider Rating": rating,
                "Provider Feedback": ""
            });
            setLoading(false);
            setIsOwner(true);
            setIsRated(false);
        }
    }

    const logSignin = async (user, userType) => {
        await Promise.all([
            setUserId(analytics, user),
            setUserProperties(analytics, 'user_type', userType),
            logEvent(analytics, 'login', {
                email: user,
                userType
            })
        ]);
    }

    const onRate = async () => {
        if (rating === 0) {
            toast.warning(t("rate-session-desc"));
            return;
        }

        setSaving(true)

        var params = {
            "Provider Rating": rating,
            "Provider Feedback": feedback
        }

        logEvent(analytics, 'rate_session', {
            userType: presenter ? "Presenter" : "Provider",
            session: sessionId
        })

        airtable.sessions.update(sessionId, params).then(async () => {
            if (presenter) {
                dispatch(setAppUserInfo(presenter));
                dispatch(setAppUserType("Presenter"));
                await logSignin(presenter['Email'], 'Presenter');
                await saveSession(CONNECTEDNORTH_SIGNINFO, presenter);
                await saveSession(CONNECTEDNORTH_SIGNTYPE, "Presenter");
            } else if (provider) {
                dispatch(setAppUserInfo(provider));
                dispatch(setAppUserType("Provider"));
                await logSignin(provider['Email'], 'Provider');
                await saveSession(CONNECTEDNORTH_SIGNINFO, provider);
                await saveSession(CONNECTEDNORTH_SIGNTYPE, "Provider");
            }

            setSaving(false);
            setRatingFinished(true);
            // setIsRated(true);
            toast.success(t("session-rate-successful-desc"));
        }).catch(error => {
            setSaving(false);
            toast.error(error.toString())
        })
    }

    const renderContent = () => {
        if (loading) return (
            <div className="rate-content">
                <Loading size={24} />
            </div>
        )

        if (error) {
            return (
                <div className="rate-content">
                    <span className="rate-title" style={{ color: 'red' }}>{error}</span>
                </div>
            )
        }

        if (!isOwner) {
            return (
                <div className="rate-content">
                    <span className="rate-title" style={{ color: 'red' }}>{t("not-own-session")}</span>
                </div>
            )
        }

        if (ratingFinished) {
            return (
                <>
                    <div className="rate-content">
                        <span className="rate-title" style={{ display: 'block', fontSize: '18px', marginBottom: '20px' }}>{i18n.t("how-was-your-session", { providerName: session['School Name Text'] })}</span>
                        <div style={{ height: 10 }} />
                        <span style={{ textTransform: 'initial', fontSize: '15px', marginBottom: '20px' }}>{t("rate-session-success")}</span>
                    </div>
                    <div className="rate-action" style={{ justifyContent: 'center' }}>
                        <Button
                            color="success"
                            type="button"
                            onClick={() => window.location.href = "/cn/upcoming"}
                        >{t("sign_in")}</Button>
                    </div>
                </>
            )
        }

        if (!isRated) {
            return (
                <>
                    <div className="rate-content">
                        <span className="rate-title"style={{ display: 'block', fontSize: '18px', marginBottom: '10px' }}>{i18n.t("how-was-your-session", { providerName: session['School Name Text'] })}</span>
                        <div style={{ height: 10 }} />
                        <span style={{ textTransform: 'initial', fontSize: '15px', marginBottom: '10px' }}>{t("share-rating-desc-prorate")}</span>
                        <div className="rating">
                            {
                                rates.map((item, index) => (
                                    <div style={{ padding: '0 5px', flex: 1 }} key={index}>
                                        <div
                                            onClick={() => setRating(item)}
                                            style={{
                                                height: 32,
                                                background: (rating === item) ? "#FF5D5D" : "#EAEAEB",
                                                borderRadius: 10,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                color: (rating === item) ? "white" : "#1A1824"
                                            }}
                                        >
                                            <span>{item}</span>&nbsp;<i className="fa fa-star" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="rating-feedback">
                            <Input
                                rows="3"
                                type="textarea"
                                className="form-style"
                                placeholder={t("share-feedback-placeholder-prorate")}
                                defaultValue={feedback}
                                onChange={e => setFeedback(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="rate-action">
                        <Button
                            className="ml-auto"
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => window.location.href = "/cn/rate"}
                        >{t("cancel")}</Button>
                        <Button
                            color="success"
                            type="button"
                            onClick={() => onRate()}
                            disabled={saving}
                        >{saving ? (<Loading size={14} />) : (null)}{t("submit-rating")}</Button>
                    </div>
                </>
            )
        }
    }

    return (
        <main>
            <section className="section section-shaped section-lg" style={{ height: height, background: 'rgb(85, 197, 242, 0.7)', padding: 0 }}>
                <div className="shape shape-style-1 bg-gradient-default">
                    <span /><span /><span /><span /><span /><span /><span /><span />
                </div>
                <Container className="lg-8">
                    <Row className="justify-content-center align-items-center" style={{ height: '100%', overflowY: 'scroll' }} id="rating-main-container">
                        <Col lg="8">
                            <div className="top-gap" />
                            <Card className="bg-secondary shadow border-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="rate-logo">
                                        <img src={logoImg} style={{ padding: '0 20px', maxWidth: 300, marginBottom: 10 }} alt="" />
                                        <span>{t("tranforming-lives-through-technology")}</span>
                                    </div>
                                    {renderContent()}
                                </CardBody>
                            </Card>
                            <div className="bottom-gap" />
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    )
}

export default Screen;
