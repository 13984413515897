import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import airtable from "../../../airtables";
import supabase from "../../../database";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { createFormResponse } from "../../PrincipalReporting/mutations/create-form-response";
import { createInitialValues } from "../../PrincipalReporting/mutations/create-initial-values";
import { getAllFieldsFromLayout } from "../../PrincipalReporting/util/get-all-fields-from-layout";
import { fetchFormFields } from "../../PrincipalReporting/queries/fetch-form-fields";
import { FORM_RESPONSE_VALUES_FIELD_IDS } from "../constants/form-response-values-field-ids";
import { transformUseAllFormResponseValues } from "../utils/transformUseFormResponseValues";

export const useSchools = () => {
	const { userType, userInfo } = useSelector((state) => state.appInfo);
	const [schools, setSchools] = useState([]);
	const [loading, setLoading] = useState(true);
	const [school, setSchool] = useState(null);
	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		initialize();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (schools && schools.length) {
			const savedSchoolId = sessionStorage.getItem("principalSelectedSchool");
			if (savedSchoolId) {
				const savedSchool = schools.find((s) => s.id === savedSchoolId);
				if (savedSchool) {
					setSchool({ ...savedSchool });
					return;
				}
			}
			setSchool({ ...schools[0] });
		}
	}, [schools]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (school?.id) {
			sessionStorage.setItem("principalSelectedSchool", school.id);
		}
	}, [school]);

	const handleClickReport = (reportId) => {
		history.push(`principal-reporting/${reportId}`);
	};

	const handleCreateReport = async (schoolId, activeForm) => {
		try {
			const previousResponse =
				await supabase.formResponses.selectLatest(schoolId);

			const response = await createFormResponse(activeForm.id, userInfo.id, [
				schoolId,
			]);

			const previousValues = previousResponse
				? await supabase.formResponseValues.listByResponse(previousResponse?.id)
				: [];

			const pages = await fetchFormFields(activeForm.template_id);
			const fields = getAllFieldsFromLayout({ pages: pages });

			await createInitialValues(response.id, fields, previousValues);

			handleClickReport(response.id);
		} catch (error) {
			console.error("Error creating report:", error);
		}
	};

	const initialize = async () => {
		let sIds = null;
		if (userType === "Team") {
			if (!userInfo.Administrator) {
				sIds = userInfo["Schools Managed"];
			}
		} else if (userType === "Teacher") {
			if (userInfo["Cluster Leadership"]) {
				const cluster = await airtable.clusters.select(
					userInfo["Cluster Leadership"][0],
				);
				sIds = cluster["Schools"];
			} else {
				sIds = userInfo["School Name"];
			}

			if (!sIds || !sIds.length) {
				setSchools([]);
				setLoading(false);
				return;
			}
		}
		const activeForms = await supabase.forms.listActiveAndInactive();

		airtable.schools.getAllSchools(sIds).then(async (ss) => {
			const schoolsWithResponses = await Promise.all(
				ss.map(async (school) => {
					const reports = userType === "Team" ? [] : await Promise.all(
						activeForms.map(async (form) => {
							const formResponses =
								await supabase.formResponses.listBySchoolAndForm(
									school.id,
									form.id,
								);

							if (formResponses.length === 0) {
								return {
									status: "not-started",
									month: form.name,
									form_id: form.id,
									report_label: "Create Report",
									onClick: () => handleCreateReport(school.id, form),
									year: form.year,
									monthValue: form.month, // Add month and year for sorting
								};
							} else {
								const processedResponses = await Promise.all(
									formResponses.map(async (response) => {
										const fieldIds = Object.values(
											FORM_RESPONSE_VALUES_FIELD_IDS,
										);
										const fieldKeys = Object.keys(
											FORM_RESPONSE_VALUES_FIELD_IDS,
										);
										const fieldsData =
											await supabase.formResponseValues.listByResponseAndFields(
												response.id,
												fieldIds,
											);

										const fieldsDataSort = fieldIds.map((fieldId, idx) => {
											const valuesPerField = fieldsData.filter(
												(field) => field.field_id === fieldId,
											);
											return {
												[fieldKeys[idx]]: valuesPerField,
											};
										});

										const transformResponses = {};
										fieldsDataSort.forEach((item) => {
											const [key, values] = Object.entries(item)[0];
											transformResponses[key] = values;
										});

										const transformedData = transformUseAllFormResponseValues(
											transformResponses,
											false,
										);
										return {
											...response,
											month: form.name,
											status: response.status,
											submitted_at: formatDate(response.submitted_at),
											last_saved_at: formatDate(response.last_saved_at),
											created_at: formatDate(response.created_at),
											user_name:
												(await airtable.teachers.select(response.user_id))?.[
													"Teacher Name"
												] || null,
											report_label: "Enter Report",
											onClick: () => handleClickReport(response.id),
											year: form.year,
											monthValue: form.month, // Add month and year for sorting
											fields: transformedData, // Add the queried fields data
										};
									}),
								);
								return processedResponses;
							}
						}),
					);

					const processedReports = reports.flat().sort((a, b) => {
						if (b.year === a.year) {
							return b.monthValue - a.monthValue;
						}
						return b.year - a.year;
					});
					return {
						...school,
						reports: processedReports,
					};
				}),
			);
			setSchools(schoolsWithResponses);
			setLoading(false);
		});
	};

	const formatDate = (timestamp) => {
		const date = new Date(timestamp);
		return date.toLocaleDateString("en-CA");
	};
	const getTitle = () => {
		if (loading) return t("principal.heading");
		if (schools && schools.length > 1) return t("principal.heading-multi");
		return t("principal.heading");
	};

	const addNewTeacher = async (name, email) => {
		let t = await airtable.teachers.selectTeacherByEmail(email);

		if (t) {
			throw Error(`A teacher with ${email} already registered.`);
		}

		const cluster = await airtable.clusters.selectBySchool(school.School);
		const defaultCredits =
			cluster && cluster["Default New Teacher Credits"]
				? cluster["Default New Teacher Credits"]
				: 15;

		const res = await airtable.teachers.create({
			"Teacher Name": name,
			Email: email,
			"School Name": [school.id],
			"Platform Logins": 0,
			Status: "Active",
			"Created by": "Principal through Platform",
			"E-mail Reminders": ["24 hours before"],
		});

		await airtable.credit.create({
			Teacher: [res.id],
			Credits: defaultCredits,
			Action: "Credits Issued by Program",
			"Display Note":
				"Introductory Credits - Teacher joined the Connected North Platform",
		});
		return res;
	};

	return {
		loading,
		school,
		setSchool,
		schools,
		getTitle,
		addNewTeacher,
		refresh: initialize,
	};
};
