// Utility function to process data
export const stripEmptyData = (rawLabels, rawData, rawSecondaryData, rawTertiaryData, stripEmpty) => {
  const labels = [];
  const data = [];
  const secondaryData = [];
  const tertiaryData = [];

  if (!rawLabels || !rawData) {
    return { labels, data, secondaryData, tertiaryData };
  }
	if(rawLabels && rawData?.length + rawSecondaryData?.length + rawTertiaryData?.length === 0) {
		return {labels: rawLabels, data: new Array(rawLabels.length).fill(0)}
	}

  rawLabels.forEach((label, index) => {
    const primaryValue = rawData[index] || 0;
    const secondaryValue = rawSecondaryData?.[index] || 0;
    const tertiaryValue = rawTertiaryData?.[index] || 0;

    if (
      !stripEmpty ||
      primaryValue !== 0 ||
      secondaryValue !== 0 ||
      tertiaryValue !== 0
    ) {
      labels.push(label);
      data.push(primaryValue);
      secondaryData.push(secondaryValue);
      tertiaryData.push(tertiaryValue);
    }
  });

  return { labels, data, secondaryData, tertiaryData };
};
