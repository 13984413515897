import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import './styles.scss';
import { CircularProgress, Step, StepLabel, Stepper } from '@mui/material';
import StepSelectTeachers from './StepSelectTeachers';
import StepFillInformation from './StepFillInformation';
import airtable from '../../../airtables';
import { useDispatch, useSelector } from 'react-redux';
import { setTotalTeams } from '../../../redux/actions';
import { distinctArray } from '../../../utils/array';
import { toast } from "react-toastify";
import { getDayRestrictionConfig } from '../../../utils/constants';

const BookSessionModal = ({
  providerSessionId,
  onToggle,
  prefill = null
}) => {
  const {
    userInfo,
    totalTeams: {
      loaded: teamListLoaded,
      teams
    }
  } = useSelector(state => state.appInfo)

  const [loading, setLoading] = useState(true);
  const [providerSession, setProviderSession] = useState(null);
  const [provider, setProvider] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [presenters, setPresenters] = useState([]);
  const [saving, setSaving] = useState(false);
  const [supply, setSupply] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initialize = async () => {
    setLoading(true);
    if (!teamListLoaded) {
      airtable.teams.getAllTeams().then(ts => {
        dispatch(setTotalTeams({
          loaded: true,
          teams: ts
        }))
      });
    }

    const ps = await airtable.providerSessions.select(providerSessionId);
    setProviderSession(ps);

    const p = await airtable.providers.select(ps["Provider"][0]);
    setProvider(p);

    if (ps.Supplies && ps.Supplies.length) {
      const sp = await airtable.supplies.select(ps["Supplies"][0]);
      setSupply(sp);
    } else {
      setSupply(null);
    }

    const pss = await airtable.presenters.presentersForProvider(p.id, p["Name"]);
    setPresenters(pss);
    setLoading(false);
  }

  const getTypeOfSession = () => {
    if (providerSession["Indigenous?"]) return "Indigenous Role Model/Content Provider";
    if (providerSession["Primary Subject Text"][0] === "Professional Development") return "Teacher PD"
    return "Virtual Experts & Field Trips";
  }

  const bookSession = async (infos) => {
    setSaving(true);

    const sIds = selectedTeachers.reduce((result, teacher) => {
      return [...result, teacher["School Name"][0]]
    }, []);
    const schoolIds = distinctArray(sIds);

    let params = {
      "Session Start Date/Time": infos.sessionDate,
      'Status': infos.status,
      'Teacher': selectedTeachers.map(t => t.id),
      'Session Title': [providerSession.id],
      'Subject/Curriculum': infos.subjects,
      'Well-being Link': infos.wbLinks || [],
      'Provider': [providerSession["Provider"][0]],
      'School Lead': [userInfo.id],
      'Length (Minutes)': infos.length,
      'Grade(s)': infos.grades,
      'Students': infos.studentNum,
      'Notes': infos.notes,
      'Session Host Notes': infos.hostNotes,
      'School(s)': schoolIds,
      'Type of Session': getTypeOfSession()
    }

    if (providerSession && providerSession["Tied Session"]) {
      params['Session Title'] = params['Session Title'] ? 
        [...params['Session Title'], ...providerSession["Tied Session"]] : 
        providerSession["Tied Session"];
      
      params['Provider'] = params['Provider'] ? 
        [...params['Provider'], ...providerSession["Tied Session Provider"]] : 
        providerSession["Tied Session Provider"];
    }

    if (infos.status === 'Requested' && infos.alterSessionDate) {
      params["Alternative Date/Time"] = [infos.alterSessionDate];
    }

    if (infos.presenter) params["Presenters"] = [infos.presenter]
    if (infos.suppliesShipment) params["Supplies Shipment Not Required"] = infos.suppliesShipment;
    if (infos.cilcReqId) params["CILC Request ID"] = infos.cilcReqId;
    if (infos.host) params["Session Host(s)"] = [infos.host];
    await airtable.sessions.create(params);
    setSaving(false);
    toast.success("Your Session was successfully booked!");
    onToggle();
  }

  return (
    <Modal
      className="modal-dialog-centered modal-book-session"
      isOpen={true}
      toggle={() => onToggle()}
      size='lg'
      backdrop={saving ? 'static' : undefined}
    >
      <div className="modal-body">
        {loading ? (
          <div className="loadingContainer">
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            <Stepper activeStep={activeStep}>
              <Step key="teachers">
                <StepLabel>Select Teacher(s)</StepLabel>
              </Step>
              <Step key="fill">
                <StepLabel>Complete Details</StepLabel>
              </Step>
            </Stepper>

            <StepSelectTeachers
              hidden={activeStep === 1}
              prefill={prefill}
              session={providerSession}
              next={(teachers) => {
                setSelectedTeachers(teachers)
                setActiveStep(1);
              }}
            />
            {activeStep === 1 && (
              <StepFillInformation
                previous={() => setActiveStep(0)}
                next={(infos) => bookSession(infos)}
                teachers={selectedTeachers}
                session={providerSession}
                hosts={teams}
                dayRestrictionConfig={getDayRestrictionConfig(supply)}
                presenters={presenters}
                provider={provider}
                loading={saving}
                prefill={prefill}
              />
            )}
          </Fragment>
        )}
      </div>
    </Modal >
  )
}

export default BookSessionModal
