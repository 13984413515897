import supabase from "../../../database";
import { consolidateFormLayout } from "../util/consolidate-form-layout";
import { transformGroupPage } from "../util/transform-group-page";
import { transformField } from "../util/transform-field";
import { transformFieldGroups } from "../util/transform-field-group";
import {
	disabled_fields,
	disabled_groups,
	disabled_pages,
} from "../constants/form-disables";

const attendancePageId = "29a541f3-6bb4-4382-a10e-0c49e9b63e1a";

export const fetchFormFields = async (formId, withAttendancePage = false) => {
	try {
		const pagesResponse = await supabase.groupPages.listByForm(formId);
		const disabledPages = withAttendancePage ? disabled_pages.filter(page => page !== attendancePageId) : disabled_pages;

		const transformedPages = pagesResponse
			.filter((page) => !disabledPages.includes(page.id))
			.map(transformGroupPage);
		const pageIds = transformedPages.map((page) => page.id);

		const groupsResponse = await supabase.fieldGroups.listByPages(pageIds);
		const transformedGroups = groupsResponse
			.filter((group) => !disabled_groups.includes(group.id))
			.map(transformFieldGroups);
		const groupIds = transformedGroups.map((group) => group.id);

		const fieldsResponse = await supabase.fields.listByGroups(groupIds);
		const transformedFields = fieldsResponse
			.filter((field) => !disabled_fields.includes(field.id))
			.map(transformField);

		const pages = consolidateFormLayout(
			transformedPages,
			transformedGroups,
			transformedFields,
		);

		return pages || [];
	} catch (e) {
		console.log("Error:", e);
		return [];
	}
};
