import { useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import airtable from "../../../airtables";
import { useTranslation } from "react-i18next";

export const useSchoolTeacherData = () => {
	const { userInfo } = useSelector((state) => state.appInfo);
	const { t } = useTranslation();

	const gradeOrder = useMemo(
		() => [
			"JK",
			"K",
			"1",
			"2",
			"3",
			"4",
			"5",
			"6",
			"7",
			"8",
			"9",
			"10",
			"11",
			"12",
			"Administrator",
			"SSA",
			"SST",
			"Learning Coach",
		],
		[],
	);
	const gradeMap = useMemo(
		() => ({
			JK: "",
			K: "a",
			1: "b",
			2: "c",
			3: "d",
			4: "e",
			5: "f",
			6: "g",
			7: "h",
			8: "i",
			9: "j",
			10: "k",
			11: "l",
			12: "m",
			Administrator: "",
			SSA: "",
			SST: "",
			"Learning Coach": "",
		}),
		[],
	);

	const fetchTeachersBySchool = useCallback(async () => {
		const schoolName = userInfo["School Name Text"][0];
		return await airtable.teachers.getSchoolTeachers(schoolName);
	}, [userInfo]);

	const getGradeRangeByUser = useCallback(async () => {
		const lowestGrade = String(userInfo["Lowest Grade"][0]);
		const highestGrade = String(userInfo["Highest Grade"][0]);

		let withinRange = false;
		const gradeRange = gradeOrder.reduce((range, grade) => {
			if (grade === lowestGrade) {
				withinRange = true;
			}

			if (withinRange) {
				range.push(grade);
			}

			if (grade === highestGrade) {
				withinRange = false;
			}

			return range;
		}, []);

		return gradeRange.map((grade) => ({
			label: t(`grade-list.${grade}`),
			value: gradeMap[grade],
		})); // eslint-disable-next-line
	}, [userInfo, gradeOrder, t]);

	const formatTeachersAsOptions = useCallback((teachers) => {
		return teachers.map((teacher) => ({
			label: teacher["Teacher Name"],
			value: teacher["Teacher Name"],
		}));
	}, []);

	const teachersBySchoolId = useCallback(async () => {
		const listOfTeachers = await fetchTeachersBySchool();
		return formatTeachersAsOptions(listOfTeachers);
	}, [fetchTeachersBySchool, formatTeachersAsOptions]);

	const inuktutTeachersBySchoolId = useCallback(async () => {
		const listOfTeachers = await fetchTeachersBySchool();
		return formatTeachersAsOptions(listOfTeachers);
	}, [fetchTeachersBySchool, formatTeachersAsOptions]);

	return {
		inuktutTeachersBySchoolId,
		teachersBySchoolId,
		getGradeRangeByUser,
	};
};
