// Helper function to check if a date is a Canadian statutory holiday
export const isCanadianHoliday = (date, userInfo) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  // Check Nunavut February Break dates
  if (userInfo?.["School P/T Text"] === 'NU' && month === 1) { // February is month 1
    // 2025 PD Week
    if (year === 2025 && day >= 10 && day <= 14) {
      return true;
    }
    // 2026 PD Week
    if (year === 2026 && day >= 16 && day <= 20) {
      return true;
    }
    // 2027 PD Week
    if (year === 2027 && day >= 15 && day <= 19) {
      return true;
    }
  }

  // Check Ontario March Break dates
  if (userInfo?.["School P/T Text"] === 'ON' && month === 2) { // March is month 2
    // 2025 March Break
    if (year === 2025 && day >= 10 && day <= 14) {
      return true;
    }
    // 2026 March Break
    if (year === 2026 && day >= 16 && day <= 20) {
      return true;
    }
    // 2027 March Break
    if (year === 2027 && day >= 16 && day <= 19) {
      return true;
    }
  }

  // Create a map of static holidays - using 0-based months
  const staticHolidays = {
    "0-1": "New Year's Day", // Jan 1
    "6-1": "Canada Day", // July 1
    "7-1": "Civic Holiday", // First Monday in August
  };

  // Check National Indigenous Peoples Day for YT and NT
  if (month === 5 && day === 21) { // June 21
    const validRegions = ['YT', 'NT'];
    const userRegion = userInfo?.["School P/T Text"];
    if (userRegion && validRegions.includes(userRegion)) {
      return true;
    }
    return false;
  }

  // Check Saint-Jean-Baptiste Day for Quebec schools
  if (month === 5 && day === 24) { // June 24
    const userRegion = userInfo?.["School P/T Text"];
    if (userRegion === 'QC') {
      return true;
    }
    return false;
  }

  // Check Remembrance Day for applicable provinces/territories
  if (month === 10 && day === 11) { // November 11
    const excludedRegions = ['MB', 'ON', 'QC', 'NS'];
    const userRegion = userInfo?.["School P/T Text"];

    // Only block the date if user's region is NOT in the excluded regions
    if (userRegion && !excludedRegions.includes(userRegion)) {
      return true;
    }
    return false;
  }

  // Check if date falls in Christmas week
  if (month === 11) { // December
    const christmas = new Date(year, 11, 25);
    const christmasDay = christmas.getDay();

    // If Christmas is on weekend, block the following week
    if (christmasDay === 0 || christmasDay === 6) {
      const blockStartDate = new Date(year, 11, 27); // Monday after Christmas
      const blockEndDate = new Date(year, 11, 31); // End of following week

      if (date >= blockStartDate && date <= blockEndDate) {
        return true;
      }
    } else {
      // For weekday Christmas, block the week containing Christmas
      const mondayOfWeek = new Date(christmas);
      mondayOfWeek.setDate(christmas.getDate() - christmasDay + 1); // Get Monday of Christmas week

      const fridayOfWeek = new Date(mondayOfWeek);
      fridayOfWeek.setDate(mondayOfWeek.getDate() + 4); // Get Friday of Christmas week

      if (date >= mondayOfWeek && date <= fridayOfWeek) {
        return true;
      }
    }
  }

  // Check if Sept 30 should be blocked based on province/territory
  if (month === 8 && day === 30) { // September 30th
    const validRegions = ['BC', 'PE', 'MB', 'NT', 'NU', 'YT'];
    const userRegion = userInfo?.["School P/T Text"];

    // Only block the date if user's region is in the valid regions list
    if (!userRegion || !validRegions.includes(userRegion)) {
      // Return false to allow booking if not in specified regions
      return false;
    }
  }

  // Check static holidays
  const dateKey = `${month}-${day}`;
  if (staticHolidays[dateKey]) return true;

  // Calculate Good Friday (2 days before Easter Sunday)
  const easter = getEasterDate(year);
  const goodFriday = new Date(easter);
  goodFriday.setDate(easter.getDate() - 2);
  if (isSameDay(date, goodFriday)) return true;

  // Calculate Easter Monday
  const easterMonday = new Date(easter);
  easterMonday.setDate(easter.getDate() + 1);
  if (isSameDay(date, easterMonday)) return true;

  // Calculate Victoria Day (Monday preceding May 25)
  const victoria = new Date(year, 4, 25); // May 25
  victoria.setDate(victoria.getDate() - ((victoria.getDay() + 6) % 7));
  if (isSameDay(date, victoria)) return true;

  // Calculate Labour Day (First Monday in September)
  const labour = new Date(year, 8, 1);
  labour.setDate(labour.getDate() + ((8 - labour.getDay()) % 7));
  if (isSameDay(date, labour)) return true;

  // Calculate Thanksgiving (Second Monday in October)
  const thanksgiving = new Date(year, 9, 1);
  thanksgiving.setDate(thanksgiving.getDate() + ((8 - thanksgiving.getDay()) % 7) + 7);
  if (isSameDay(date, thanksgiving)) return true;

  return false;
};

// Helper function to calculate Easter Sunday for a given year
function getEasterDate(year) {
  const f = Math.floor;
  const G = year % 19;
  const C = f(year / 100);
  const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
  const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
  const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
  const L = I - J;
  const month = 3 + f((L + 40) / 44);
  const day = L + 28 - 31 * f(month / 4);

  return new Date(year, month - 1, day);
}

// Helper function to check if two dates are the same day
function isSameDay(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}

/**
 * 
 * @param {Date} targetDate
 */
export const checkChristmasDay = (targetDate) => {
  if (targetDate.getMonth() !== 11) {
    return {
      status: false,
      date: targetDate
    }
  }

  const christmas = new Date(targetDate.getFullYear(), 11, 25);
  const christmasDay = christmas.getDay();

  // If Christmas is on weekend, check if date is in the following week
  if (christmasDay === 0 || christmasDay === 6) {
    const blockStartDate = new Date(targetDate.getFullYear(), 11, 27); // Monday after Christmas
    const blockEndDate = new Date(targetDate.getFullYear(), 11, 31); // End of following week

    if (targetDate >= blockStartDate && targetDate <= blockEndDate) {
      const nDate = new Date(targetDate.getFullYear(), 11, 31); // Skip to end of block
      nDate.setDate(nDate.getDate() + 1); // Move to next day
      return {
        status: true,
        date: nDate
      };
    }
  } else {
    // For weekday Christmas, check if date is in Christmas week
    const mondayOfWeek = new Date(christmas);
    mondayOfWeek.setDate(christmas.getDate() - christmasDay + 1); // Get Monday of Christmas week

    const fridayOfWeek = new Date(mondayOfWeek);
    fridayOfWeek.setDate(mondayOfWeek.getDate() + 4); // Get Friday of Christmas week

    if (targetDate >= mondayOfWeek && targetDate <= fridayOfWeek) {
      const nDate = new Date(fridayOfWeek); // Skip to end of block
      nDate.setDate(nDate.getDate() + 1); // Move to next day
      return {
        status: true,
        date: nDate
      };
    }
  }

  return {
    status: false,
    date: targetDate
  }
}