import AirtableTable from "../AirtableTable";

export class SkillTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Skills");

    this.fields = {
      "Skill": "fldVhLANPTlIlGYdH",
      "Aligned Sessions": "fldJg6EXI0Rastudy",
      "Tagged Sessions": "fldBDXalHt1lHNMXS"
    }
  }

  getAllSkills = () => {
    return this.list({
      sort: [
        { field: 'Skill', direction: 'asc' }
      ]
    });
  }
}
