import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import bgColor from '../constants/dayColor';
import GNLogoImg from '../../../assets/img/GN.jpg';
import { getGrade, minHours, maxDailyMinutes } from "./transformGradeKeys";

// Parse a date string to noon UTC to avoid timezone issues
const parseDateToNoon = (dateStr) => {
	// Split the date into components
	const [year, month, day] = dateStr.split('-').map(Number);
	// Create date using UTC to avoid timezone shifts
	const date = new Date(Date.UTC(year, month - 1, day, 12, 0, 0));
	return date;
};

export const generateCalendarPDF = (school, year, schoolCalendar, groupedDays, monthNames, schoolSchedule, scheduledHours) => {
	const doc = new jsPDF({
		orientation: "portrait",
		unit: 'mm',
		format: 'letter'  // Standard Letter format
	});

	// Title
	doc.setFontSize(24);
	doc.setTextColor(44, 37, 96);
	doc.addImage(GNLogoImg, "jpeg", 10, 10, 20, 24, "");
	doc.text(school?.["School"], 105, 20, { align: 'center' });
	doc.setFontSize(18);
	doc.text(year, 105, 30, { align: 'center' });

	generateCalendarEvent(doc, schoolCalendar, groupedDays, monthNames, school, year);

	// Calendar setup
	let currentPage = 1;
	doc.setPage(currentPage);

	const startDate = parseDateToNoon(schoolCalendar?.["First Day (Principal)"]);
	startDate.setHours(12, 0, 0, 0);
	const monthsPerRow = 4;
	const monthWidth = 50;
	const monthHeight = 70;
	const marginLeft = 9;

	// Generate 12 months starting from the start date
	for (let i = 0; i < 12; i++) {
		const monthDate = new Date(startDate);
		monthDate.setDate(1);
		monthDate.setMonth(startDate.getMonth() + i);
		const monthName = monthNames[monthDate.getMonth()];
		const row = Math.floor(i / monthsPerRow);
		const col = i % monthsPerRow;

		// Calculate position for each month
		const x = marginLeft + (col * monthWidth) + (col * 0.5);
		const y = 50 + (row * monthHeight);

		// Add month name
		doc.setFontSize(15);
		doc.setTextColor(44, 37, 96);
		const monthNameWidth = doc.getStringUnitWidth(monthName) * doc.internal.getFontSize() / doc.internal.scaleFactor;
		doc.text(monthName, x + (monthWidth - monthNameWidth - marginLeft) / 2, y);

		const monthEvents = groupedDays[monthName] || [];
		drawCustomCalendar(doc, x, y + 8, generateMonthCalendarData(monthDate), monthEvents, monthDate.getFullYear(), monthDate.getMonth());
	}

	// Add new page for Calendar Summary
	doc.addPage();
	drawCalendarSummary(doc, schoolCalendar, groupedDays, monthNames, schoolSchedule, scheduledHours, school);

	doc.save(`${school?.["School"]} ${year} calendar.pdf`);
};

const generateMonthCalendarData = (monthDate) => {
	const daysInMonth = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0).getDate();
	const firstDay = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1).getDay();
	const calendar = Array.from({ length: 6 }, () => Array(7).fill('')); // 6 weeks max

	let dayCount = 1;

	// Fill first week
	for (let i = firstDay; i < 7 && dayCount <= daysInMonth; i++) {
		calendar[0][i] = dayCount++;
	}

	// Fill remaining weeks
	for (let weekIndex = 1; dayCount <= daysInMonth; weekIndex++) {
		for (let dayIndex = 0; dayIndex < 7 && dayCount <= daysInMonth; dayIndex++) {
			calendar[weekIndex][dayIndex] = dayCount++;
		}
	}

	return calendar;
};

const processEvent = (doc, event, yPos, xPos) => {
	const [eventType, eventDesc] = event.Type.split(' - ').map(str => str.trim());
	const color = bgColor[eventType] || '#ffffff';

	// Draw event box using xPos
	doc.setFillColor(243, 249, 255);
	doc.setDrawColor(17, 205, 239);
	doc.roundedRect(xPos, yPos - 5, 45, 13, 2, 2, 'FD');

	// Add event text and circle based on xPos
	doc.setFillColor(color);
	doc.setFontSize(8);
	const textWidth = doc.getStringUnitWidth(eventType) * 8 / doc.internal.scaleFactor;
	const circleRadius = Math.max(textWidth / 1.5, 3);
	doc.circle(xPos + circleRadius + 1, yPos - 0.5, circleRadius, 'F');
	doc.text(eventType, xPos + circleRadius + 1, yPos - 0.5, { align: 'center', baseline: 'middle' });

	// Event description and date text
	doc.setFont('helvetica', 'normal');
	doc.text(eventDesc || '', xPos + 7.5, yPos + 0.5);
	doc.setFontSize(7);
	doc.text(event.displayDate, xPos + 2, yPos + 6);
	if (event.Length) {
		doc.text(event.Length, xPos + 35, yPos + 6);
	}

	return yPos + 15;
};

const generateCalendarEvent = (doc, schoolCalendar, groupedDays, monthNames, school, year) => {
	doc.addPage();

	let yPos = 20;
	let columnNo = 0;
	const leftMargin = 10;
	const startingMonth = parseDateToNoon(schoolCalendar?.["First Day (Principal)"]);
	startingMonth.setHours(12, 0, 0, 0);

	for (let i = 0; i < 12; i++) {
		const currentMonthIndex = (startingMonth.getMonth() + i) % 12;
		const monthName = monthNames[currentMonthIndex];
		const events = groupedDays[monthName];

		if (events?.length > 0) {
			// Add month header
			doc.setFontSize(14);
			doc.text(monthName, leftMargin + ( 50 * columnNo ), yPos);
			yPos += 9;

			// Group consecutive events
			const groupedEvents = [];
			let currentGroup = [events[0]];

			for (let j = 1; j < events.length; j++) {
				const currentEvent = events[j];
				const prevEvent = events[j - 1];

				// Check if events should be grouped
				const sameType = currentEvent.Type === prevEvent.Type;
				const sameLength = currentEvent.Length === prevEvent.Length;
				const currentDate = parseDateToNoon(currentEvent.Date);
				const prevDate = parseDateToNoon(prevEvent.Date);
				const dayDiff = Math.floor((currentDate - prevDate) / (1000 * 60 * 60 * 24));

				if (sameType && sameLength && dayDiff <= 7) {
					currentGroup.push(currentEvent);
				} else {
					groupedEvents.push([...currentGroup]);
					currentGroup = [currentEvent];
				}
			}
			groupedEvents.push([...currentGroup]);

			// Process grouped events
			for (const eventGroup of groupedEvents) {
				const firstEvent = eventGroup[0];
				const lastEvent = eventGroup[eventGroup.length - 1];
				const firstDate = parseDateToNoon(firstEvent.Date);
				const lastDate = parseDateToNoon(lastEvent.Date);
				const displayDate = eventGroup.length > 1
					? `${firstDate.toLocaleString('default', { month: 'long', day: 'numeric' })}-${lastDate.getDate()}, ${lastDate.getFullYear()}`
					: firstDate.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' });

				const eventToProcess = {
					...firstEvent,
					displayDate
				};

				yPos = processEvent(doc, eventToProcess, yPos, leftMargin + ( 50 * columnNo ));

				// Check if we need a new page
				if (yPos > 260) {
					yPos = 20;
					if (columnNo < 3) {
						columnNo += 1;
					}
					else {
						doc.addPage();
						columnNo = 0;
					}
				}
			}
		}
	}
};

const drawCustomCalendar = (doc, x, y, calendarData, monthEvents, year, month) => {
	const cellSize = 6.5;
	const cellPadding = 0;

	// Draw headers
	const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
	doc.setFontSize(7);
	doc.setTextColor(150, 150, 150);
	days.forEach((day, i) => {
		doc.text(day, x + (i * (cellSize + cellPadding)) + cellSize / 2, y, { align: 'center' });
	});

	// Draw calendar days
	calendarData.forEach((week, weekIndex) => {
		week.forEach((day, dayIndex) => {
			if (day) {
				const cellX = x + (dayIndex * (cellSize + cellPadding));
				const cellY = y + 4 + (weekIndex * (cellSize + cellPadding + 1.5));

				doc.setTextColor(dayIndex === 0 || dayIndex === 6 ? 150 : 0);

				// Find if there's an event for this day
				const event = monthEvents.find(e => e.Date === `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);

				if (event) {
					// Draw event pill
					const eventType = event.Type.split(" - ")[0];
					const pillWidth = cellSize - 1.5;
					const pillHeight = cellSize + 1;

					doc.setFillColor(...hexToRGB(bgColor[eventType]));
					doc.roundedRect(cellX + 0.725, cellY - 0.25, pillWidth, pillHeight, 2, 2, 'F');
					doc.text(eventType, cellX + cellSize / 2, cellY + cellSize / 2 - 1.5, { align: 'center', baseline: 'middle' });
					doc.text(day.toString(), cellX + cellSize / 2, cellY + cellSize / 2 + 1.5, { align: 'center', baseline: 'middle' });
				} else {
					doc.text(day.toString(), cellX + cellSize / 2, cellY + cellSize / 2, { align: 'center', baseline: 'middle' });
				}
			}
		});
	});
};

// Helper function to convert hex to RGB
const hexToRGB = (hex) => {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	return [r, g, b];
};

const countWorkingDays = (start, end) => {
  const startDate = new Date(start);
  startDate.setHours(12, 0, 0, 0);
  const endDate = new Date(end);
  endDate.setHours(12, 0, 0, 0);
  let workingDays = 0;

  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    if (d.getDay() !== 0 && d.getDay() !== 6) {
      workingDays++;
    }
  }
  return workingDays;
};

const getWorkingDay = (name, days, direction = 'before') => {
	const namedDay = days.find(({ Name }) => Name === name);
	if (!namedDay) return;

	// Find all H days in the same month as the named day
	const holidayEvents = days.filter(({ Type, Date: eventDate }) => {
		const dayType = Type.split(" - ")[0];
		const eventDateObj = parseDateToNoon(eventDate);
		const namedDayObj = parseDateToNoon(namedDay.Date);

		// Only include events in the same month
		return eventDateObj.getMonth() === namedDayObj.getMonth() &&
			   (dayType === "H" || eventDate === namedDay.Date) &&
			   (direction === 'before' ? eventDateObj <= namedDayObj : eventDateObj >= namedDayObj);
	});

	// Sort holidays by date
	holidayEvents.sort((a, b) => {
		const aDate = parseDateToNoon(a.Date);
		const bDate = parseDateToNoon(b.Date);
		return direction === 'before' ? bDate - aDate : aDate - bDate;
	});

	// Start from named day and work in the specified direction
	const namedDayIndex = holidayEvents.findIndex(e => e.Date === namedDay.Date);
	if (namedDayIndex === -1) return;

	// Find the connected holiday
	let targetHoliday = holidayEvents[namedDayIndex];
	for (let i = namedDayIndex; direction === 'before' ? i < holidayEvents.length - 1 : i > 0; i += direction === 'before' ? 1 : -1) {
		const currentDate = parseDateToNoon(holidayEvents[i].Date);
		const nextDate = parseDateToNoon(holidayEvents[direction === 'before' ? i + 1 : i - 1].Date);

		// If there's exactly one day gap, they're connected
		const dayDiff = Math.abs((currentDate - nextDate) / (1000 * 60 * 60 * 24));

		if (dayDiff <= 1) {
			targetHoliday = holidayEvents[direction === 'before' ? i + 1 : i - 1];
		} else {
			break;
		}
	}

	if (!targetHoliday) return;

	let workingDay = parseDateToNoon(targetHoliday.Date);

	while (true) {
		workingDay = new Date(workingDay.getTime() + (direction === 'before' ? -86400000 : 86400000));
		const dayStr = workingDay.toISOString().split('T')[0];

		if (workingDay.getDay() === 0 || workingDay.getDay() === 6) {
			continue;
		}

		// Check if this is not a holiday
		const isHoliday = days.some(({ Type, Date: eventDate }) => {
			const dayType = Type.split(" - ")[0];
			return dayType === "H" && eventDate === dayStr;
		});

		if (isHoliday) {
			continue;
		}

		return dayStr;
	}
};

const getWorkingDayAfter = (name, days) => getWorkingDay(name, days, 'after');
const getWorkingDayBefore = (name, days) => getWorkingDay(name, days, 'before');

// Helper function to get the value based on Length
const getLengthValue = (length, minutes) => {
	if (minutes) {
		return Number((minutes / 360).toFixed(2)); // Round to 2 decimal places
	}
	switch (length) {
		case "Full day": return 1;
		case "Half day": return 0.5;
		case "Quarter day": return 0.25;
		default: return 0;
	}
};

const drawCalendarSummary = (doc, schoolCalendar, groupedDays, monthNames, schoolSchedule, scheduledHours, school) => {
	// Set up styles
	const emptyCell = { content: '', styles: { fillColor: [211, 211, 211] } };

	// Draw title
	doc.setFontSize(16);
	doc.setFont('helvetica', 'bold');
	doc.setTextColor(44, 37, 96);
	doc.text(`${school?.["School"]} Calendar Summary`, 105, 10, { align: 'center' });

	// Parse dates and set to noon to avoid timezone issues
	const startDayT = parseDateToNoon(schoolCalendar?.["First Day (Teachers)"]);
	const endDayT = parseDateToNoon(schoolCalendar?.["Last Day (Teachers)"]);
	const startDayS = parseDateToNoon(schoolCalendar?.["First Day (Students)"]);
	const endDayS = parseDateToNoon(schoolCalendar?.["Last Day (Students)"]);
	const startDayP = parseDateToNoon(schoolCalendar?.["First Day (Principal)"]);
	const endDayP = parseDateToNoon(schoolCalendar?.["Last Day (Principal)"]);

	const startingMonth = parseDateToNoon(schoolCalendar?.["First Day (Principal)"]);

	// Initialize sessional days table
	const sessionalDays = [[
		{ styles: { fillColor: [211, 211, 211], fontStyle: 'bold' }, title: "Month" },
		{ styles: { fillColor: [211, 211, 211], fontStyle: 'bold' }, title: "Staff" },
		{ styles: { fillColor: [211, 211, 211], fontStyle: 'bold' }, title: "Student" },
	]];

	let totalStaffDays = 0, totalStudentDays = 0;

	const calculateWorkingDays = (start, end, offDays, validStart, validEnd) => {
		// Create Date objects using consistent parsing
		const startDate = parseDateToNoon(start);
		const endDate = parseDateToNoon(end);
		const validStartDate = parseDateToNoon(validStart);
		const validEndDate = parseDateToNoon(validEnd);

		// Compare dates directly using timestamps
		const effectiveStart = startDate.getTime() <= validStartDate.getTime() ? validStartDate : startDate;
		const effectiveEnd = endDate.getTime() >= validEndDate.getTime() ? validEndDate : endDate;

		return countWorkingDays(effectiveStart, effectiveEnd) - offDays;
	};

	// Calculate working days for each month
	for (let i = 0; i < 12; i++) {
		const monthDate = new Date(Date.UTC(startingMonth.getUTCFullYear(), startingMonth.getUTCMonth() + i, 1, 12, 0, 0));
		const startDateM = new Date(Date.UTC(monthDate.getUTCFullYear(), monthDate.getUTCMonth(), 1, 12, 0, 0));
		const endDateM = new Date(Date.UTC(monthDate.getUTCFullYear(), monthDate.getUTCMonth() + 1, 0, 12, 0, 0));
		const monthName = monthNames[monthDate.getUTCMonth()];

		// Calculate off days
		let staffOffDays = 0, studentOffDays = 0;
		const events = groupedDays[monthName];

		if (events) {
			events.forEach(({ Type, Length, Minutes, Date: eventDate }) => {
				const dayType = Type.split(" - ")[0];
				const dateObj = parseDateToNoon(eventDate);
				// If the date is within range and the day type IS in the list, count it as an off day
				if (dateObj.getTime() >= new Date(startDayT).getTime() && dateObj.getTime() <= new Date(endDayT).getTime() && ["S", "H"].includes(dayType)) {
					staffOffDays += getLengthValue(Length, Minutes);
				}
				if (dateObj.getTime() >= new Date(startDayS).getTime() && dateObj.getTime() <= new Date(endDayS).getTime() && ["S", "O", "P", "H", "D", "A", "C"].includes(dayType)) {
					studentOffDays += getLengthValue(Length, Minutes);
				}
			});
		}
		const workingDaysT = calculateWorkingDays(
			startDateM.toISOString().split('T')[0],
			endDateM.toISOString().split('T')[0],
			staffOffDays,
			startDayT.toISOString().split('T')[0],
			endDayT.toISOString().split('T')[0]
		);
		const workingDaysS = calculateWorkingDays(
			startDateM.toISOString().split('T')[0],
			endDateM.toISOString().split('T')[0],
			studentOffDays,
			startDayS.toISOString().split('T')[0],
			endDayS.toISOString().split('T')[0]
		);

		sessionalDays.push([monthName, workingDaysT, workingDaysS]);
		totalStaffDays += workingDaysT;
		totalStudentDays += workingDaysS;
	}

	// Get holiday days for working day calculations
	const calendarDays = Object.values(groupedDays).flat();
	const schoolOffDays = calendarDays?.filter(d => ["S", "H"].includes(d.Type.split(" - ")[0]));

	// Prepare datesTable with empty rows
	const datesTable = [
		["Date Principal Reports for Duty:", startDayP.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })],
		["Date Teachers Report for Duty:", startDayT.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })],
		["Date School Opens for Students:", startDayS.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })],
		[emptyCell, emptyCell], // Empty row with gray background
		["Last Day of Classes before Xmas:", getWorkingDayBefore("Christmas Day", schoolOffDays)?.split('-').length === 3 ? parseDateToNoon(getWorkingDayBefore("Christmas Day", schoolOffDays)).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }) : ""],
		["First Day of Classes in January:", getWorkingDayAfter("New Year's Day", schoolOffDays)?.split('-').length === 3 ? parseDateToNoon(getWorkingDayAfter("New Year's Day", schoolOffDays)).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }) : ""],
		["Last Day of Classes before Spring Break:", ""],
		["First Day of Classes after Spring Break:", ""],
		["Last Day of Classes before Easter:", getWorkingDayBefore("Good Friday", schoolOffDays)?.split('-').length === 3 ? parseDateToNoon(getWorkingDayBefore("Good Friday", schoolOffDays)).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }) : "" ],
		["First Day of Classes after Easter:", getWorkingDayAfter("Easter Monday", schoolOffDays)?.split('-').length === 3 ? parseDateToNoon(getWorkingDayAfter("Easter Monday", schoolOffDays)).toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }) : ""],
		[emptyCell, emptyCell], // Empty row with gray background
		["Last Day of School for Students:", endDayS.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })],
		["Last Day of Duty for Teachers:", endDayT.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })],
		["Last Day of Duty for Principal:", endDayP.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })],
	];

	const pageWidth = doc.internal.pageSize.getWidth() - 30;
	const sessionalDaysTableWidth = pageWidth / 3; // 33% of the page width
	const scheduleTableWidth = (pageWidth - sessionalDaysTableWidth) + 5;

	// Draw the datesTable on the right
	autoTable(doc, {
		body: datesTable,
		startY: 15, // Align with the top of the first table
		margin: { left: sessionalDaysTableWidth + 15 }, // Position it to the right of the first table
		theme: "grid",
		columnStyles: {
			0: { cellWidth: scheduleTableWidth * 0.6, fontStyle: 'bold', fillColor: [211, 211, 211]  }, // Adjust width for 2 columns
			1: { cellWidth: scheduleTableWidth * 0.4 },
		},
		styles: { fontSize: 8, cellPadding: 1.75, minCellHeight: 6, valign: "middle" }, // Adjust minimum cell height
	});

	// Draw the sessionalDays table on the left
	autoTable(doc,{
		head: [[{colSpan: 3, title: "Sessional Days"}]],
		headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0], halign: "center"  }, // Light grey background for head
		body: sessionalDays,
		startY: 15,
		theme: "grid",
		margin: { left: 10 },
		columnStyles: {
			0: { cellWidth: sessionalDaysTableWidth * 0.4, fontStyle: 'bold', fillColor: [211, 211, 211] }, // Adjust width for 3 columns
			1: { cellWidth: sessionalDaysTableWidth * 0.3 }, // Column 1 font bold
			2: { cellWidth: sessionalDaysTableWidth * 0.3 },
		},
		styles: { fontSize: 8, cellPadding: 1.75, minCellHeight: 6, valign: "middle" }, // Adjust minimum cell height
		foot: [["Totals", totalStaffDays, totalStudentDays]],
		footStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0] },
	});

	const hoursTableWidth = (doc.internal.pageSize.getWidth() - 30)/3;

	let startX = 10;
	let startY = doc.lastAutoTable.finalY + 5;

	let count = 0;
	// Grade hours section
	Object.keys(minHours).forEach((key, index) => {
		if (!schoolSchedule || !schoolSchedule[key]) return;

		const s = schoolSchedule[key];
		const data = [
			["• A.M. Start:", s["AM Start"]],
			["• Recess Start:", s["Recess 1 Start"]],
			["• Recess Finish:", s["Recess 1 Finish"]],
			["• A.M. Finish:", s["AM Finish"]],
			["• P.M. Start:", s["PM Start"]],
			["• Recess Start:", s["Recess 2 Start"]],
			["• Recess Finish:", s["Recess 2 Finish"]],
			["• P.M. Finish:", s["PM Finish"]],
			...(key === "7To12" ? [["Rotational Minutes:", s["Rotational Minutes"]]] : []),
			["Scheduled Minutes:", s["Minutes of Instruction"]],
			["Max Daily Minutes:", maxDailyMinutes[key]],
			["Scheduled Hours/Year:", scheduledHours[key] || ""]
		];

		autoTable(doc, {
			head: [[{colSpan: 2, title: `Hours: ${getGrade(key)}`}]],
			headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0], halign: "center" },
			body: data,
			startY,
			theme: "grid",
			margin: { left: startX + ((5 + hoursTableWidth) * count)},
			styles: { fontSize: 8, cellPadding: 1.75, minCellHeight: 5, valign: "middle" },
			columnStyles: { 0: { cellWidth: hoursTableWidth * 0.6, fontStyle: 'bold', fillColor: [211, 211, 211] }, 1: { cellWidth: hoursTableWidth * 0.4 } },
		});

		count++;
	})

	let currentY = doc.lastAutoTable.finalY + 5;
	// static hours table
	autoTable(doc, {
		head: [["Hours Required", "Minimum", "Maximum Unsched. Hours", "Minimum after Unsched. Hours", "Maximum"]],
		headStyles: { fillColor: [211, 211, 211], textColor: [0, 0, 0], halign: "center" },
		body: [
			["Kindergarten", "485", "10", "475", "570"],
			["Grade 1-6", "997", "45", "952", emptyCell],
			["Grade 7-12", "1045", "45", "1000", emptyCell],
		],
		startY: currentY,
		theme: "grid",
		margin: { left: startX},
		styles: { fontSize: 8, cellPadding: 1.75, minCellHeight: 5, valign: "middle" },
		columnStyles: {
			0: { fontStyle: 'bold', fillColor: [211, 211, 211], cellWidth: pageWidth * 0.15 },
			1: { cellWidth: pageWidth * 0.1 },
			2: { cellWidth: pageWidth * 0.15 },
			3: { cellWidth: pageWidth * 0.15 },
			4: { cellWidth: pageWidth * 0.1 },
		},
	});

	// comment section
	autoTable(doc, {
		body: [["General Comments", ""]],
		startY: doc.lastAutoTable.finalY + 2,
		theme: "grid",
		margin: { left: startX },
		bodyStyles: { fontSize: 8, cellPadding: 1.75, minCellHeight: 19 },
		columnStyles: { 0: { fontStyle: 'bold', fillColor: [211, 211, 211], cellWidth: pageWidth * 0.10, valign: "middle" }, 1: {cellWidth: pageWidth * 0.55 }},
	})

	// signature section
	const boxDimensions = { width: 55, height: 9 };
	const sectionStart = 10 + pageWidth * 0.65 + 5;
	const boxStart = sectionStart + 15;

	const roles = ["DEA Chairperson", "Principal"];

	roles.forEach((role) => {
		doc.setFontSize(12);
		doc.setFont('helvetica', 'bold');
		doc.text(role, sectionStart, currentY + 3);

		doc.setFontSize(10);
		doc.setFont('helvetica', 'bold');
		doc.text("Signed:", sectionStart, currentY + 10);
		doc.rect(boxStart, currentY + 5, boxDimensions.width, boxDimensions.height);

		doc.text("Date:", sectionStart, currentY + 20);
		doc.rect(boxStart, currentY + 15, boxDimensions.width, boxDimensions.height);

		currentY += 27;
	});
};
