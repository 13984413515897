import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { kitikmeot_options, kivalliq_options, qikiqtani_options } from '../constants/calendar';
import airtable from "../../../airtables/PDAirtable";
import cn_airtable from "../../../airtables";
import { setGrade, getGrade } from "../utils/transformGradeKeys";

const secondsToTime = seconds =>  seconds !== null ? new Date(seconds * 1000).toISOString().substr(11, 5) : "";
const timeToSeconds = time => time ? time.split(":").reduce((h, m) => h * 3600 + m * 60) : null;

const parseDateToNoon = (dateStr) => {
  if (!dateStr) return null;
  const [year, month, day] = dateStr.split('-').map(Number);
  if (!year || !month || !day) return null;
  return new Date(Date.UTC(year, month - 1, day, 12, 0, 0));
};

// Helper function to get region calendar options
const findRegionFromClusterIds = (clusterIds) => {
  const targetIds = ['recyhmVrIkoFf4KDa', 'recXUv6PacZaRhxpK', 'recfvPfMVBiTSPS7G'];
  return clusterIds.split(", ").find(cid => targetIds.includes(cid));
};

// Helper function to select calendar options based on region
const getCalendarOptionsByRegion = (region) => {
  const optionsMap = {
    'recyhmVrIkoFf4KDa': kitikmeot_options,
    'recXUv6PacZaRhxpK': qikiqtani_options,
    'recfvPfMVBiTSPS7G': kivalliq_options,
  };
  return optionsMap[region] || kitikmeot_options;
};

const countWorkingDays = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  let workingDays = 0;

  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    if (d.getDay() !== 0 && d.getDay() !== 6) {
      workingDays++;
    }
  }
  return workingDays;
};

export const useSchoolCalendar = (year = "2024-25") => {
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const [teacher, setTeacher] = useState();
  const [school, setSchool] = useState(null);
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState();
  const [schoolCalendar, setSchoolCalendar] = useState();
  const [calendarOptions, setCalendarOptions] = useState(kitikmeot_options);
  const [allowCustomCalendar, setAllowCustomCalendar] = useState(false);
  const [schoolSchedule, setSchoolSchedule] = useState();
  const [schoolClosedDays, setSchoolClosedDays] = useState();
  const [genericClosedDays, setGenericClosedDays] = useState();
  const [isloading, setIsLoading] = useState(true);
  const [isCalendarloading, setIsCalendarLoading] = useState(true);
  const teacherId = userInfo?.id;
  const [closureDay, setClosureDay] = useState({student: 0, teacher: 0, principal: 0});
  const [schoolSEDays, setSchoolSEDays] = useState([]);
  const [groupedDays, setGroupedDays] = useState([]);
  const [scheduledHours, setScheduledHours] = useState({});
  const [totalScheduledHours, setTotalScheduledHours] = useState({});  // New state for total hours before O days
  const [scheduledOHours, setScheduledOHours] = useState(0);
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  useEffect(() => {
    if (schoolCalendar) {
      setSchoolSEDays([
        { Type: "FP - First Day for Principal", Date: schoolCalendar["First Day (Principal)"] },
        { Type: "FT - First Day for Teachers", Date: schoolCalendar["First Day (Teachers)"] },
        { Type: "FS - First Day for Students", Date: schoolCalendar["First Day (Students)"] },
        { Type: "LP - Last Day for Principal", Date: schoolCalendar["Last Day (Principal)"] },
        { Type: "LT - Last Day for Teachers", Date: schoolCalendar["Last Day (Teachers)"] },
        { Type: "LS - Last Day for Students", Date: schoolCalendar["Last Day (Students)"] },
      ]);
    }
  }, [schoolCalendar]);

  const categorizeEventsByMonth = (events) => {
    const categorizedEvents = monthNames.reduce((acc, month) => {
      acc[month] = [];
      return acc;
    }, {});

    // First, add all existing events
    events.forEach(event => {
      // Split the date string to avoid timezone issues
      const [year, month, day] = event.Date.split('-').map(Number);
      // Create date object at noon local time
      const eventDate = new Date(Date.UTC(year, month - 1, day, 12, 0, 0));
      const monthName = monthNames[eventDate.getMonth()];
      categorizedEvents[monthName]?.push({
        Type: event.Type,
        Date: event.Date,
        Length: event.Length,
        Minutes: event.Minutes,
        Name: event.Name,
      });
    });

    // Add H days for dates before Principal's start date and after Principal's last day
    if (schoolCalendar?.["First Day (Principal)"] && schoolCalendar?.["Last Day (Principal)"]) {
      // Parse dates using the date string directly to avoid timezone issues
      const [startYear, startMonth, startDay] = schoolCalendar["First Day (Principal)"].split('-').map(Number);
      const principalStartDate = new Date(Date.UTC(startYear, startMonth - 1, startDay, 12, 0, 0));
      principalStartDate.setHours(12, 0, 0, 0); // Set to noon to avoid timezone issues

      const [endYear, endMonth, endDay] = schoolCalendar["Last Day (Principal)"].split('-').map(Number);
      const principalEndDate = new Date(Date.UTC(endYear, endMonth - 1, endDay, 12, 0, 0));
      principalEndDate.setHours(12, 0, 0, 0); // Set to noon to avoid timezone issues

      // Calculate the day before start
      const dayBeforeStart = new Date(principalStartDate);
      dayBeforeStart.setDate(principalStartDate.getDate() - 1);
      dayBeforeStart.setHours(12, 0, 0, 0);

      const dayAfterEnd = new Date(principalEndDate);
      dayAfterEnd.setDate(principalEndDate.getDate() + 1);
      dayAfterEnd.setHours(12, 0, 0, 0);

      // If Principal ends in June, set end date to July 31st of the same year
      const schoolYearEnd = new Date(principalEndDate.getFullYear(), 6, 31); // July 31st of end year
      schoolYearEnd.setHours(12, 0, 0, 0);

      // Function to add H days for a date range
      const addHolidaysForRange = (startDate, endDate) => {
        const currentDate = new Date(startDate);
        // Set time to noon to avoid timezone issues
        currentDate.setHours(12, 0, 0, 0);

        // Convert end date to timestamp for comparison, also using noon
        const endDateTime = new Date(endDate);
        endDateTime.setHours(12, 0, 0, 0);
        const endTimestamp = endDateTime.getTime();

        while (currentDate.getTime() <= endTimestamp) {
          const dayOfWeek = currentDate.getDay();
          // Only add H days for Monday (1) through Friday (5)
          if (dayOfWeek >= 1 && dayOfWeek <= 5) {
            // Use ISO string and split to get just the date part
            const dateStr = currentDate.toISOString().split('T')[0];
            const monthName = monthNames[currentDate.getMonth()];
            const existingEvent = categorizedEvents[monthName]?.find(e => e.Date === dateStr);

            if (!existingEvent) {
              categorizedEvents[monthName]?.push({
                Type: "H - Summer holiday",
                Date: dateStr,
                Length: "Full day",
                Name: "Summer Holiday"
              });
            }
          }
          // Move to next day
          currentDate.setDate(currentDate.getDate() + 1);
          // Keep time at noon
          currentDate.setHours(12, 0, 0, 0);
        }
      };

      // Add H days for the period before Principal's start if it's in July or August
      if (principalStartDate.getMonth() === 6 || principalStartDate.getMonth() === 7) { // If Principal starts in July or August
        // Add H days from July 1st or August 1st to the day before start
        const monthStartDate = new Date(principalStartDate.getFullYear(), principalStartDate.getMonth(), 1);
        monthStartDate.setHours(12, 0, 0, 0); // Set to noon
        addHolidaysForRange(monthStartDate, dayBeforeStart);
      }

      // Add H days after Principal's end date if it's in June
      if (principalEndDate.getMonth() === 5) { // If Principal ends in June
        // Add H days from day after end through July
        addHolidaysForRange(dayAfterEnd, schoolYearEnd);
      }
    }

    // Sort events within each month by date
    Object.keys(categorizedEvents).forEach(month => {
      categorizedEvents[month].sort((a, b) => new Date(a.Date) - new Date(b.Date));
    });

    return categorizedEvents;
  };

  useEffect(() => {
    if (schoolSEDays.length > 0) {
      const events = [...(genericClosedDays || []), ...(schoolClosedDays || []), ...schoolSEDays];
      setGroupedDays(categorizeEventsByMonth(events));
    }
  }, [schoolSEDays, genericClosedDays, schoolClosedDays]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch teacher and school data
  const fetchTeacherAndSchoolData = async () => {
    if (!teacherId) return;

    let sIds = null;
    setIsLoading(true);
    try {
      const teacherData = await airtable.nunavutTeachers.getTeacherByRecordId(teacherId);
      setTeacher(teacherData);
      if (userType === "Teacher") {
        if (userInfo["Cluster Leadership"]) {
          const cluster = await cn_airtable.clusters.select(
            userInfo["Cluster Leadership"][0],
          );
          sIds = cluster["Schools"];
        } else {
          sIds = userInfo["School Name"];
        }
        if (!sIds || !sIds.length) {
          setSchools([]);
        }
      }

      const schools = await cn_airtable.schools.getAllSchools(sIds);
      setSchools(schools);
      if (teacherData) {
        const { "Cluster Record IDs": clusterIds, "Allow Custom Calendar": customCalendar } = teacherData;
        setAllowCustomCalendar(customCalendar || false);
        const region = findRegionFromClusterIds(clusterIds);
        setCalendarOptions(getCalendarOptionsByRegion(region));

        // Find user's school in the schools list
        const userSchoolName = Array.isArray(userInfo["School Name Text"])
          ? userInfo["School Name Text"][0]
          : userInfo["School Name Text"];

        const defaultSchool = schools.find(s => s.School === userSchoolName) || schools[0];
        await fetchSchoolData(defaultSchool.School);
        setSelectedSchool(defaultSchool);
      }
    } catch (err) {
      console.error("Error fetching teacher and school data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSchoolData = async (schoolName) => {
    if (school && schoolName === school.School) return;
    const schoolData = await airtable.schools.getSchoolByName(schoolName);
    setSchool(schoolData);
  }

  // Fetch the school calendar based on selected year
  const fetchSchoolCalendar = async () => {
    if (!school) return;
    setIsCalendarLoading(true);
    try {
      const calendarData = await airtable.schoolCalendar.getSchoolCalendarByYear(school["School"], year);
      setSchoolCalendar(calendarData);
      if (calendarData) {
        // Initialize school schedule if we have start/end dates
        if (calendarData["First Day (Principal)"] && calendarData["Last Day (Principal)"]) {
          if (calendarData["School Schedule"]) {
            const schedule = calendarData["School Schedule"];
            const allowedFields = ["AM Start", "AM Finish", "Recess 1 Start", "Recess 1 Finish", "PM Start", "PM Finish", "Recess 2 Start", "Recess 2 Finish", "Minutes of Instruction", "Rotational Minutes"];
            const formattedSchedule = Object.fromEntries(schedule.map(({ "Grade Grouping": grade, ...times }) => [
              setGrade(grade),
              Object.fromEntries(allowedFields.map(k => [k, k === "Minutes of Instruction" || k === "Rotational Minutes" ? ( times[k] ? times[k] : 0 ) : ( times[k] ? secondsToTime(times[k]) : "" )]))
            ]));
            setSchoolSchedule(formattedSchedule);
          }

          // Add N days around Principal's first and last days
          const firstDay = parseDateToNoon(calendarData["First Day (Principal)"]);
          const lastDay = parseDateToNoon(calendarData["Last Day (Principal)"]);

          // Helper to get next weekday
          const getNextWeekday = (date, count) => {
            const result = new Date(date);
            while (count > 0) {
              result.setDate(result.getDate() + 1);
              const dayOfWeek = result.getDay();
              if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                count--;
              }
            }
            return result;
          };

          // Helper to get previous weekday
          const getPrevWeekday = (date, count) => {
            const result = new Date(date);
            while (count > 0) {
              result.setDate(result.getDate() - 1);
              const dayOfWeek = result.getDay();
              if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                count--;
              }
            }
            return result;
          };

          // Create N days for first day and 4 following weekdays
          const firstDayNs = [];
          let currentDay = firstDay;
          let daysAdded = 0;

          // Always add first day if it's a weekday
          const firstDayOfWeek = firstDay.getDay();
          if (firstDayOfWeek !== 0 && firstDayOfWeek !== 6) {
            firstDayNs.push({
              Type: "N - Principal's extra work",
              Date: firstDay.toISOString().split('T')[0],
              Minutes: 360
            });
            daysAdded++;
          }

          // Add remaining weekdays after first day
          while (daysAdded < 5) {
            currentDay = getNextWeekday(currentDay, 1);
            const dayOfWeek = currentDay.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
              firstDayNs.push({
                Type: "N - Principal's extra work",
                Date: currentDay.toISOString().split('T')[0],
                Minutes: 360
              });
              daysAdded++;
            }
          }

          // Create N days for last day and previous weekday
          const lastDayNs = [];
          const lastDayOfWeek = lastDay.getDay();
          if (lastDayOfWeek !== 0 && lastDayOfWeek !== 6) {
            lastDayNs.push({
              Type: "N - Principal's extra work",
              Date: lastDay.toISOString().split('T')[0],
              Minutes: 360
            });
          }

          // Add one more weekday before last day
          const prevDay = getPrevWeekday(lastDay, 1);
          lastDayNs.push({
            Type: "N - Principal's extra work",
            Date: prevDay.toISOString().split('T')[0],
            Minutes: 360
          });

          // Combine existing calendar days with N days
          const existingDays = calendarData["Calendar Days"] || [];
          const allDays = [...existingDays, ...firstDayNs, ...lastDayNs];
          setSchoolClosedDays(allDays);
        }
      }
    } catch (err) {
      console.error("Error fetching school calendar:", err);
    } finally {
      setIsCalendarLoading(false);
    }
  };

  // Fetch generic calendar days
  useEffect(() => {
    const fetchGenericCalendarDays = async () => {
      if (!schoolCalendar?.["First Day (Principal)"] || !schoolCalendar?.["Last Day (Principal)"]) return;

      // Parse dates and set to noon to avoid timezone issues
      const [startYear, startMonth, startDay] = schoolCalendar["First Day (Principal)"].split('-').map(Number);
      const startDateObj = new Date(Date.UTC(startYear, startMonth - 1, startDay, 12, 0, 0));

      const [endYear, endMonth, endDay] = schoolCalendar["Last Day (Principal)"].split('-').map(Number);
      const endDateObj = new Date(Date.UTC(endYear, endMonth - 1, endDay, 12, 0, 0));

      // Format dates in YYYY-MM-DD format
      const startDate = startDateObj.toISOString().split('T')[0];
      const endDate = endDateObj.toISOString().split('T')[0];

      const days = await airtable.calendarDays.getGenericCalendarDays(startDate, endDate);
      if (days && days.length > 0) {
        setGenericClosedDays(days);
      }
    };

    fetchGenericCalendarDays();
  }, [schoolCalendar?.["First Day (Principal)"], schoolCalendar?.["Last Day (Principal)"]]);  // eslint-disable-line react-hooks/exhaustive-deps

  // Get role days based on year and role
  const getRoleDays = useCallback((year, role) => {
    const startDay = schoolCalendar?.[`First Day (${role})`];
    const endDay = schoolCalendar?.[`Last Day (${role})`];
    if (!startDay || !endDay) return 0;

    const holidays = [...(genericClosedDays || []), ...(schoolClosedDays || [])];

    const offDays = holidays.reduce((total, { Type, Length, Date: eventDate }) => {
      const dayType = Type.split(" - ")[0];

      // Skip days that don't affect this role's count
      if ((role === "Principal" && ["O", "P", "A", "C", "D", "N"].includes(dayType)) ||
          (role === 'Teachers' && ["O", "P", "A", "C", "D"].includes(dayType)) ||
          (["A", "C"].includes(dayType))) return total;

      const [dateYear, dateMonth, dateDay] = eventDate.split('-').map(Number);
      const [startYear, startMonth, startDayNum] = startDay.split('-').map(Number);
      const [endYear, endMonth, endDayNum] = endDay.split('-').map(Number);

      const dateTime = new Date(Date.UTC(dateYear, dateMonth - 1, dateDay, 12, 0, 0)).getTime();
      const startTime = new Date(Date.UTC(startYear, startMonth - 1, startDayNum, 12, 0, 0)).getTime();
      const endTime = new Date(Date.UTC(endYear, endMonth - 1, endDayNum, 12, 0, 0)).getTime();

      // Skip if date is outside the role's start/end range
      if (dateTime < startTime || dateTime > endTime) return total;

      return total + (Length === "Full day" ? 1 : Length === "Half day" ? 0.5 : Length === "Quarter day" ? 0.25 : 0);
    }, 0);

    return countWorkingDays(startDay, endDay) - offDays;
  }, [schoolCalendar, genericClosedDays, schoolClosedDays]);

  useEffect(() => {
    if (!schoolSchedule || !schoolCalendar || !genericClosedDays || !schoolClosedDays) {
      setScheduledHours({});
      setScheduledOHours(0);
      setTotalScheduledHours({});  // New state for total hours before O days
      return;
    }

    const schoolActiveDays = getRoleDays(year, "Students");
    const startDay = schoolCalendar[`First Day (Principal)`];
    const endDay = schoolCalendar[`Last Day (Principal)`];
    const schedule = {};
    const totalSchedule = {};  // For calculating total hours before O days

    // Combine school and generic closed days
    const allClosedDays = [...(schoolClosedDays || []), ...(genericClosedDays || [])];

    // Calculate total O minutes scheduled (for tracking usage)
    const oMinutes = allClosedDays.reduce((total, { Type, Date: eventDate, Minutes }) => {
      const dayType = Type.split(" - ")[0];
      if (dayType !== "O") return total;

      const [dateYear, dateMonth, dateDay] = eventDate.split('-').map(Number);
      const [startYear, startMonth, startDayNum] = startDay.split('-').map(Number);
      const [endYear, endMonth, endDayNum] = endDay.split('-').map(Number);

      const dateTime = new Date(Date.UTC(dateYear, dateMonth - 1, dateDay, 12, 0, 0)).getTime();
      const startTime = new Date(Date.UTC(startYear, startMonth - 1, startDayNum, 12, 0, 0)).getTime();
      const endTime = new Date(Date.UTC(endYear, endMonth - 1, endDayNum, 12, 0, 0)).getTime();

      // Only count if the date is within the range and Minutes is not null
      if (dateTime < startTime || dateTime > endTime || Minutes === null) return total;

      return total + (Minutes || 0);
    }, 0);

    setScheduledOHours(oMinutes / 60);

    // For scheduled hours display, include O days in the calculation
    const finalSchoolDays = schoolActiveDays - (oMinutes / 360);
    const totalSchoolDays = schoolActiveDays;  // Total days before O days subtracted

    Object.entries(schoolSchedule).forEach(([key, value]) => {
      const totalHours = value["Minutes of Instruction"]/60;
      const hours = totalHours ? (totalHours * finalSchoolDays) : 0;
      const totalHoursBeforeO = totalHours ? (totalHours * totalSchoolDays) : 0;
      schedule[key] = totalHours ? (Math.round(hours * 10) / 10).toString().replace(/\.0$/, '') : "";
      totalSchedule[key] = totalHours ? (Math.round(totalHoursBeforeO * 10) / 10).toString().replace(/\.0$/, '') : "";
    });

    setScheduledHours(schedule);
    setTotalScheduledHours(totalSchedule);  // New state for total hours before O days
  }, [schoolCalendar, genericClosedDays, schoolClosedDays, schoolSchedule, getRoleDays, year]);

  // Effect to set closure days
  useEffect(() => {
    if (!schoolCalendar) {
      setClosureDay({ student: 0, teacher: 0, principal: 0 });
      return;
    }

    const cDays = {
      student: getRoleDays(year, "Students") - 182,
      teacher: getRoleDays(year, "Teachers") - 195,
      principal: getRoleDays(year, "Principal") - 202
    };
    setClosureDay(cDays);
  }, [schoolCalendar, genericClosedDays, schoolClosedDays, getRoleDays, year]);

  // Save the school calendar based on the selected option
  const saveSchoolCalendar = async (selectedOption) => {

    const calendar = {
      School: [school.id],
      Year: year,
      ...selectedOption,
    };

    try {
      let res;
      if (schoolCalendar) {
        res = await airtable.schoolCalendar.update(schoolCalendar.id, calendar);
      } else {
        calendar["Status"] = "Draft";
        res = await airtable.schoolCalendar.create(calendar);
      }

      await fetchSchoolCalendar();
      return res;
    } catch (err) {
      console.error("Error saving school calendar:", err);
    }
  };

  const saveSchoolSchedule = async (schoolHours) => {
    // If no hours provided, just update state and continue
    if (!schoolHours) {
      setSchoolSchedule({});
      return;
    }

    try {
      const params = Object.entries(schoolHours || {}).map(([key, value]) => ({
        Calendar: [schoolCalendar?.id],
        "Grade Grouping": getGrade(key),
        ...Object.fromEntries(
          Object.entries(value || {})
            .filter(([k]) => k !== "Minutes of Instruction")
            .map(([k, v]) => [k, k === "Rotational Minutes" ? parseInt(v) || 0 : timeToSeconds(v)])
        )
      }));

      let res;
      if (params.length > 0) {
        res = await Promise.all(params.map(async (schedule) => {
          const schedules = schoolCalendar?.["School Schedule"] || [];
          const updateSchedule = schedules.find(sc => sc["Grade Grouping"] === schedule["Grade Grouping"]);
          if (updateSchedule) {
            return airtable.schoolSchedule.update(updateSchedule.id, schedule);
          } else {
            return airtable.schoolSchedule.create(schedule);
          }
        }));
      }

      await fetchSchoolCalendar();
      return res;
    } catch (err) {
      console.error("Error saving school schedule:", err);
      // Don't throw error, just continue
      return null;
    }
  };

  const saveCalendarDays = async (calendarDays) => {
    const newDays = calendarDays.filter(item => !item.id && item.Date);
    const existingDays = calendarDays.filter(item => !!item.id && item.Date);

    const formatDayForSave = (day) => {
      const { Date, Type, Length, Minutes } = day;
      const dayType = Type.split(" - ")[0].trim();
      // For O days, only send Minutes. For other days, only send Length.
      return dayType === "O"
        ? { Date, Type, Minutes }
        : { Date, Type, Length };
    };

    if (newDays.length > 0) {
      const batches = [];
      for (let i = 0; i < newDays.length; i += 10) {
        batches.push(airtable.calendarDays.createMultiple(
          newDays.slice(i, i + 10).map(day => ({
            ...formatDayForSave(day),
            Calendar: [schoolCalendar?.id]
          }))
        ));
      }
      await Promise.all(batches);
    }
    if (existingDays.length > 0) {
      const updatePromises = [];
      for (let i = 0; i < existingDays.length; i += 10) {
        updatePromises.push(airtable.calendarDays.multipleUpdate(
          existingDays.slice(i, i + 10).map(day => ({
            id: day.id,
            param: formatDayForSave(day)
          }))
        ));
      }
      await Promise.all(updatePromises);
    }

    await fetchSchoolCalendar();
    return true;
  };

  // Effect to fetch teacher and school data
  useEffect(() => {
    fetchTeacherAndSchoolData();
  }, [teacherId]);  // eslint-disable-line react-hooks/exhaustive-deps

  // Effect to fetch school calendar after school data is set
  useEffect(() => {
    if (school) fetchSchoolCalendar();
  }, [school]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(selectedSchool) fetchSchoolData(selectedSchool.School);
  }, [selectedSchool]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isloading,
    isCalendarloading,
    calendarOptions,
    allowCustomCalendar,
    schools,
    selectedSchool,
    school,
    schoolCalendar,
    schoolSchedule,
    groupedDays,
    schoolClosedDays,
    genericClosedDays,
    closureDay,
    scheduledHours,
    totalScheduledHours,  // New state for total hours before O days
    scheduledOHours,
    schoolSEDays,
    monthNames,
    teacher,

    setSelectedSchool,
    setGenericClosedDays,
    saveSchoolCalendar,
    saveSchoolSchedule,
    saveCalendarDays,
    fetchSchoolCalendar,
  };
};
