import AirtableBase from "./AirtableBase";
import {
	ActivityTable,
	C2LTable,
	ClusterTable,
	CommunityTable,
	CurriculumLinkTable,
	EventTable,
	FiresideChatTable,
	OtherSurveyTable,
	PaymentTable,
	PresenterTable,
	ProviderSessionTable,
	ProviderTable,
	RegionTable,
	ReviewTable,
	VideoTable,
	SchoolTable,
	SessionTable,
	SubjectTable,
	SupplyTable,
	TagTable,
	TeacherTable,
	TeamTable,
	CreditTable,
	CreditCodesTable,
	QuestionTable,
	ResourceTable,
	FundTable,
	TimezoneTable,
	SkillTable
} from "./tables";

class Airtable extends AirtableBase {
	constructor(apiKey, base) {
		super(apiKey, base);

		this.c2l = new C2LTable(this.instance);
		this.clusters = new ClusterTable(this.instance);
		this.communities = new CommunityTable(this.instance);
		this.curriculumLinks = new CurriculumLinkTable(this.instance);
		this.events = new EventTable(this.instance);
		this.firesideChats = new FiresideChatTable(this.instance);
		this.otherSurveys = new OtherSurveyTable(this.instance);
		this.payments = new PaymentTable(this.instance);
		this.presenters = new PresenterTable(this.instance);
		this.providers = new ProviderTable(this.instance);
		this.providerSessions = new ProviderSessionTable(this.instance);
		this.regions = new RegionTable(this.instance);
		this.reviews = new ReviewTable(this.instance);
		this.videos = new VideoTable(this.instance);
		this.schools = new SchoolTable(this.instance);
		this.sessions = new SessionTable(this.instance);
		this.subjects = new SubjectTable(this.instance);
		this.supplies = new SupplyTable(this.instance);
		this.tags = new TagTable(this.instance);
		this.teachers = new TeacherTable(this.instance);
		this.teams = new TeamTable(this.instance);
		this.credit = new CreditTable(this.instance);
		this.creditCodes = new CreditCodesTable(this.instance);
		this.activities = new ActivityTable(this.instance);
		this.resource = new ResourceTable(this.instance);
		this.questions = new QuestionTable(this.instance);
		this.funds = new FundTable(this.instance);
		this.timezones = new TimezoneTable(this.instance);
		this.skills = new SkillTable(this.instance);
	}
}

const AIRTABLE_APP = "appP1kThwW9zVEpHr";

export const AIRTABLE_KEY =
	"patmUMb77Ctz9Cg87.67d074a8baceb5ee0134afd04ba0d828a37b7914b58f3ba67f88612f50419c56";

const airtableInstance = new Airtable(AIRTABLE_KEY, AIRTABLE_APP);

export default airtableInstance;
