import React, { useEffect, useState } from "react";
import LoadingBar from "../../../components/LoadingBar";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { DAY_LIMITATIONS, SELECTBOX_STYLE } from "../../../utils/constants";
import ProviderCalendars from "../providerCalendars";
import airtable from "../../../airtables";
import { setSession } from "../../../utils/session";
import { CONNECTEDNORTH_SIGNINFO } from "../../../config";
import { toast } from 'react-toastify';
import { setAppUserInfo } from "../../../redux/actions";
import SVGIcon from "../../../components/Icons/SVGIcons";
import DateSelector from "../../modals/DateSelector";
import { getSimpleDateString } from "../../../utils/time";

const SessionAvailabilityTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(state => state.appInfo);
  const [updating, setUpdating] = useState(false);
  const [dayLimitation, setDayLimitation] = useState([]);
  const [earliestStartTime, setEarliestStartTime] = useState("");
  const [recordingAllowed, setRecordingAllowed] = useState("");
  const [receiveRequestNotifications, setReceiveRequestNotifications] = useState(false);
  const [instructions, setInstructions] = useState("");
  const [calendarSelected, setCalendarSelected] = useState("");
  const [nao, setNao] = useState([]);
  const [showDateSelectorModal, setShowDateSelectorModal] = useState(false);

  useEffect(() => {
    setDayLimitation(getSelectRecords(userInfo["Day Limitations"]))
    setEarliestStartTime(userInfo["Earliest Start Time"] || "8:30");
    setRecordingAllowed(userInfo["Recording Allowed"]);
    setReceiveRequestNotifications(userInfo["Autobook"]);
    setInstructions(userInfo["Special Instructions"] ? userInfo["Special Instructions"] : '');
    setCalendarSelected(userInfo['Calendar Selected']);
    if (userInfo["Valid NAO"] === "Valid" && userInfo["Not Available On"]) {
      setNao(userInfo["Not Available On"].split(','));
    }
  }, [userInfo])

  const getSelectRecords = (val) => {
    if (!val) return [];
    return val.map(item => ({
      value: item,
      label: item
    }))
  }

  const getValuesFromSelectRecords = (records) => {
    const vs = records.map(record => record.value)
    if (vs.length === 0) return null;
    return vs;
  }

  const addNao = (date) => {
    const nNao = [...nao];
    nNao.push(getSimpleDateString(date));
    nNao.sort();
    setNao([...nNao]);
    setShowDateSelectorModal(false);
  }

  const isExistNao = (date) => {
    const d = getSimpleDateString(date);
    if (nao.includes(d)) return true;
    return false;
  }

  const updateInfo = async () => {
    setUpdating(true);
    try {
      let params = {
        "Day Limitations": getValuesFromSelectRecords(dayLimitation),
        "Earliest Start Time": earliestStartTime,
        "Recording Allowed": recordingAllowed,
        "Special Instructions": instructions,
        "Autobook": receiveRequestNotifications,
        "Calendar Selected": calendarSelected,
        "Not Available On": nao.join(","),
      }

      const info = await airtable.providers.update(userInfo.id, params);

      await setSession(CONNECTEDNORTH_SIGNINFO, info);
      dispatch(setAppUserInfo(info));
      toast.success("Saved successfully!")
    } catch (error) { }
    setUpdating(false);
  }

  return (
    <div className="tab-container">
      {updating && <LoadingBar />}
      <div className="session-form-section">
        <div className="section-header mt-4">
          <h4>Availability Settings</h4>
          <p className="text-muted">
            If you're only available for sessions on certain days of the week, select those days to limit when requests can be made. If you are available on any day of the week, you do not need to make a selection.
          </p>
        </div>
        <FormGroup row>
          <Label for="dayLimitation" sm={4}>Daily Availability</Label>
          <Col sm={8}>
            <Select
              isMulti
              className="form-style"
              value={dayLimitation}
              onChange={(selectedOptions) => setDayLimitation(selectedOptions)}
              options={DAY_LIMITATIONS.map(limitation => ({
                value: limitation,
                label: limitation
              }))}
              styles={SELECTBOX_STYLE}
            />
          </Col>
        </FormGroup>
        <FormGroup row style={{ marginTop: '1rem' }}>
          <Label for="earliestStartTime" sm={4}>Earliest Start Time</Label>
          <Col sm={8} className="v-center">
            <Input
              type="select"
              name="earliestStartTime"
              id="earliestStartTime"
              className="form-control"
              value={earliestStartTime}
              onChange={e => setEarliestStartTime(e.target.value)}
            >
              <option value="5:00">5:00 AM</option>
              <option value="5:30">5:30 AM</option>
              <option value="6:00">6:00 AM</option>
              <option value="6:30">6:30 AM</option>
              <option value="7:00">7:00 AM</option>
              <option value="7:30">7:30 AM</option>
              <option value="8:00">8:00 AM</option>
              <option value="8:30">8:30 AM</option>
              <option value="9:00">9:00 AM</option>
              <option value="9:30">9:30 AM</option>
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row style={{ marginTop: '1rem' }}>
          <Label for="instructions" sm={4}>{t("availability-booking-instructions")}</Label>
          <Col sm={8} className="v-center">
            <Input
              type="textarea"
              name="instructions"
              id="instructions"
              style={{ height: 150 }}
              value={instructions}
              placeholder="Please provide any general notes on availability, for example, only available Tuesday and Thursday, or special booking instructions, such as a booking web form or process"
              onChange={e => setInstructions(e.target.value)}
            />
          </Col>
        </FormGroup>

        <FormGroup row style={{ marginTop: '1rem' }}>
          <Label for="nao" sm={4}>Not Available On</Label>
          <Col sm={8} className="v-center">
            <div className="nao-container">
              {!!nao && nao.length > 0 && (
                <div className="nao-days">
                  {nao.map((day, index) => (
                    <div className="nao-day" key={index}>
                      <div>{day}</div>
                      <div
                        className="nao-remove-btn"
                        onClick={() => {
                          const newNao = nao.filter(item => item !== day);
                          setNao([...newNao]);
                        }}
                      >
                        <SVGIcon name="close" color="white" />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="nao-actions">
                <div
                  className="nao-add-btn"
                  onClick={() => setShowDateSelectorModal(true)}
                ><i className="fa fa-plus" />&nbsp;Add</div>
              </div>
            </div>
          </Col>
        </FormGroup>

        <div className="section-header mt-4">
          <h4>{t("provider-step-progress.recording")}</h4>
          <p className="text-muted">
            <Trans
              i18nKey="myinfo.recording-policy.message1"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a href="/cn/policies#recording-practices" style={{ textDecoration: 'underline' }} />
              }}
            />&nbsp;
            <Trans
              i18nKey="myinfo.recording-policy.message2"
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a href="mailto:contentproviders@connectednorth.org"></a>
              }}
            />
          </p>
        </div>
        <FormGroup row>
          <Label for="recordingAllowed" sm={4}>{t("recording-allowed")}</Label>
          <Col sm={8} className="v-center">
            <Input
              type="select"
              name="recordingAllowed"
              id="recordingAllowed"
              className="form-control"
              value={recordingAllowed}
              onChange={e => setRecordingAllowed(e.target.value)}
              disabled
            >
              <option></option>
              <option>Yes</option>
              <option>Ask each time</option>
              <option>No</option>
            </Input>
          </Col>
        </FormGroup>

        <div className="section-header mt-4">
          <h4>{t("automatic-request-notifications")}</h4>
          <p className="text-muted">
            {t("myinfo-receive-request-notifications-desc")}
          </p>
        </div>
        <FormGroup row style={{ marginTop: '1rem', marginBottom: 0 }}>
          <Label for="receiveNotifications" data-intercom-target="Request Notifications" sm={4}>{t("receive-request-notifications")}</Label>
          <Col sm={8} className="v-center">
            <input
              id="receiveNotifications"
              type="checkbox"
              checked={receiveRequestNotifications}
              onChange={(e) => setReceiveRequestNotifications(e.target.checked)}
            />
          </Col>
        </FormGroup>
        <div className="section-header mt-4">
          <h4>{t("google-calendar-integration")}</h4>
          <p className="text-muted">
            {t("myinfo-google-calendar-desc")}
          </p>
        </div>
        <FormGroup row>
          <Label for="timezone" data-intercom-target="Google Calendar" sm={4}>{t("google-calendar")}</Label>
          <Col sm={8} className="v-center">
            {
              userInfo['Calendar Token'] ? (
                <span>✓ {t("connected")} (<a href={`https://research.tigweb.org/connectednorth/provider-availability/disconnect.html?provider=${userInfo.id}`}>{t("disconnect")}</a>)</span>
              ) : (
                <a href={`https://research.tigweb.org/connectednorth/provider-availability/calendarauth.html?provider=${userInfo.id}`}><span>{t("connect-google-calendar")}</span></a>
              )
            }
          </Col>
        </FormGroup>
        {
          userInfo['Calendar Token'] ? (
            <>
              <FormGroup row style={{ marginTop: '1rem' }}>
                <Label for="calendarSelected" sm={4}>Use this Calendar:</Label>
                <Col sm={8} className="v-center">
                  <Input
                    type="select"
                    name="calendarSelected"
                    id="calendarSelected"
                    className="form-control"
                    value={calendarSelected}
                    onChange={e => setCalendarSelected(e.target.value)}
                  >
                    <ProviderCalendars provider={userInfo.id} />
                  </Input>
                </Col>
              </FormGroup>
            </>
          ) : null
        }
      </div>
      <div className="ms-action" data-intercom-target="Save">
        <Button
          color="primary"
          size="sm"
          onClick={() => updateInfo()}
        >{t("save-change")}</Button>
      </div>

      {showDateSelectorModal && (
        <DateSelector
          onToggle={() => setShowDateSelectorModal(!showDateSelectorModal)}
          onConfirm={(selectedDate) => addNao(selectedDate)}
          shouldDisable={(date) => isExistNao(date.toDate())}
        />
      )}
    </div>
  )
}

export default SessionAvailabilityTab;