import { useEffect, useRef, useState } from "react";
import airtable from "../../airtables";
import { checkProviderWorkingTime, getEarliestStartTimeFromProviderSession } from "../../pages/modals/Request/utils";
import Axios from 'axios';
import { distinctArray } from "../../utils/array";

const useHook = ({
  time,
  session,
  provider,
  teachers,
  bookedSessionId,
  sessionLength: customSessionLength
}) => {

  const isCalendarToken = provider['Calendar Token'] ? true : false;
  const providerTimezone = useRef(session["Provider Timezone"]?.[0] || "America/Toronto").current;
  const earliestStartTime = useRef(session["Provider Timezone"] ? getEarliestStartTimeFromProviderSession(session) : "09:00").current;
  const [checking, setChecking] = useState(false);
  const sessionLength = customSessionLength || session["Length (Minutes)"] || 60;
  const checkingRef = useRef(null);
  const [validationData, setValidationData] = useState(null);

  useEffect(() => {
    if (!time || !session || !provider || !teachers) return;

    const controller = new AbortController();

    if (checkingRef.current) {
      checkingRef.current.cancel();
    }

    const checkValidationOfSelectedTime = async (signal) => {
      if (session["Provider Timezone"] && session["Provider Timezone"].length > 0) {
        const cResult = checkProviderWorkingTime(time, providerTimezone, earliestStartTime);
        const { isInWorkingTime, providerTime } = cResult;
        if (!isInWorkingTime) {
          return "invalid-working-" + providerTime;
        }
      }

      if (isCalendarToken || provider.id === "recYI3eeWTsAJWqB3") {
        const providerId = provider.id;

        // Convert selectedTime to America/New_York timezone
        const options = {
          timeZone: 'America/New_York',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const parts = formatter.formatToParts(time);
        const formattedDate = `${parts[4].value}-${parts[0].value}-${parts[2].value}T${parts[6].value}:${parts[8].value}:${parts[10].value}`;

        const dateInfo = {
          day: formattedDate.split('T')[0],
          time: formattedDate.split('T')[1]
        };

        try {
          const calendarStatus = await Axios.get(
            `https://research.tigweb.org/connectednorth/provider-availability/events.html?provider=${providerId}&length=${sessionLength}&sessionid=${session.id}&date=${dateInfo.day}&time=${dateInfo.time}`,
            { signal }
          );
          if (calendarStatus.data === "No events found.") {
            return "valid";
          } else if (
            calendarStatus.data.includes("Fatal error") ||
            calendarStatus.data.includes("expired token")
          ) {
            return "not-applicable";
          } else {
            return "invalid";
          }
        } catch (error) {
          if (error.name === 'CanceledError' || error.name === 'AbortError') {
            throw error;
          }
          return "not-applicable";
        }
      }
      return "valid";
    };

    const checkValidationForProvider = async () => {
      const sessions = await airtable.sessions.getSessionsInSpecificPeriod(
        time,
        sessionLength,
        'Provider',
        session["Provider Name Text"]?.[0] || session["Provider Name"][0],
        session["Provider"][0]
      );

      if (!bookedSessionId) {
        if (sessions.length) {
          return "invalid";
        }
      } else {
        if (sessions.length > 1 || (sessions.length === 1 && sessions[0].id !== bookedSessionId)) {
          return "invalid";
        }
      }
      return "valid";
    };

    const checkValidationForTeachers = async () => {
      const checkTeacher = async (teacher) => {
        const sessions = await airtable.sessions.getSessionsInSpecificPeriod(
          time,
          sessionLength,
          'Teacher',
          teacher["Teacher Name"],
          teacher.id
        );

        if (!bookedSessionId) {
          if (sessions.length) {
            return "invalid";
          }
        } else {
          if (sessions.length > 1 || (sessions.length === 1 && sessions[0].id !== bookedSessionId)) {
            return "invalid";
          }
        }
        return "valid";
      };

      const teacherRes = [];
      if (Array.isArray(teachers)) {
        for (let i = 0; i < teachers.length; i++) {
          const res = await checkTeacher(teachers[i]);
          teacherRes.push(res);
        }
      } else {
        const res = await checkTeacher(teachers);
        teacherRes.push(res);
      }
      return teacherRes;
    };

    const checkValidationForSchools = async () => {
      const checkSchool = async (school) => {
        if (!school["Block Simultaneous Bookings"]) return "not-applicable";
        const ss = await airtable.sessions.getSessionsForSchoolInSpecificPeriod(
          time,
          sessionLength,
          school["School"]
        );
        if (ss && ss.length) return "invalid";
        return "valid";
      };

      let schoolIds = [];
      if (Array.isArray(teachers)) {
        const ids = teachers.map(t => t["School Name"][0]);
        schoolIds = distinctArray(ids);
      } else {
        schoolIds = teachers["School Name"];
      }

      const schools = await airtable.schools.listByIds(schoolIds);
      const schoolRes = [];
      for (let i = 0; i < schools.length; i++) {
        const res = await checkSchool(schools[i]);
        schoolRes.push(res);
      }
      return schoolRes;
    };

    const checkValidation = async (signal) => {
      if (time < new Date()) {
        return {
          vTime: "past",
          vProvider: "not-applicable",
          vTeachers: [],
          vSchools: []
        };
      }

      const timeValidation = await checkValidationOfSelectedTime(signal);
      if (timeValidation.startsWith("invalid")) {
        return {
          vTime: timeValidation,
        }
      }

      const providerValidation = await checkValidationForProvider();
      const teacherValidation = await checkValidationForTeachers();
      const schoolValidation = await checkValidationForSchools();

      return {
        vTime: timeValidation,
        vProvider: providerValidation,
        vTeachers: teacherValidation,
        vSchools: schoolValidation
      };
    };

    const checkValidationWithAbort = () => {
      let cancelFunc = () => {
        controller.abort();
      };
      const promise = new Promise(async (resolve, reject) => {
        try {
          const result = await checkValidation(controller.signal);
          if (!controller.signal.aborted) {
            resolve(result);
          }
        } catch (error) {
          if (!controller.signal.aborted) {
            reject(error);
          }
        }
      });
      return { promise, cancel: cancelFunc };
    };

    checkingRef.current = checkValidationWithAbort();
    setChecking(true);
    checkingRef.current.promise
      .then((data) => {
        if (!controller.signal.aborted) {
          setValidationData(data);
          setChecking(false);
        }
      })
      .catch(error => {
        if (!controller.signal.aborted) {
          setChecking(false);
        }
      });

    return () => {
      controller.abort();
      if (checkingRef.current) {
        checkingRef.current.cancel();
      }
    };
  }, [time, sessionLength, session, provider, teachers, bookedSessionId,
    isCalendarToken, providerTimezone, earliestStartTime]);

  return {
    checking,
    validationData
  }
}

export default useHook;