import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import airtable from '../airtables';

const Unsubscribe = (props) => {
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const { match: { params } } = props;

    const tId = params.teacherId;
    airtable.teachers.disableRatingRequest(tId, true).then(() => {
      setIsDone(true);
    })
  }, [props]);

  if (!isDone) return null;

  return (
    <Redirect to="/cn/rate" />
  )
}

export default Unsubscribe;