import React, { useEffect, useLayoutEffect, useState, useId } from "react";
import { Box, CircularProgress, Typography, Menu as MuiMenu, MenuItem } from "@mui/material";
import { Button } from "reactstrap";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import { useFormData } from "./hooks/use-form-data.hook";

import { ProgressProvider } from "./components/ProgressContext";
import { FormLayout } from "./components/FormLayout";
import { useSchoolTeacherData } from "./hooks/use-school-teachers-data.hook";
import { useTranslation } from "react-i18next";
import { dowloadPDFReport } from "./util/pdf";

const Screen = () => {
	const { t } = useTranslation();
	const {
		form,
		reportSchool,
		loading,
		restricted,
		submitted,
		isParamResponse,
	} = useFormData();

	const { teachersBySchoolId, inuktutTeachersBySchoolId, getGradeRangeByUser } =
		useSchoolTeacherData();

	const [options, setOptions] = useState({
		teachersBySchoolId: [],
		inuktutTeachersBySchoolId: [],
		gradesRangeByUser: [],
	});
	const [isDownloading, setIsDownloading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleDownloadPDFClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const id = useId();


	useEffect(() => {
		const fetchOptions = async () => {
			try {
				const teachers = await teachersBySchoolId();
				const inuktutTeachers = await inuktutTeachersBySchoolId();
				const grades = await getGradeRangeByUser();
				setOptions({
					teachersBySchoolId: teachers,
					inuktutTeachersBySchoolId: inuktutTeachers,
					gradesBySchoolId: grades,
				});
			} catch (error) {
				console.error("Error fetching teacher data:", error);
			}
		};
		fetchOptions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const getCurrentMonthYear = () => {
	// 	return new Date().toLocaleDateString("default", {
	// 		month: "long",
	// 		year: "numeric"
	// 	});
	// };

	useLayoutEffect(() => {
		window.document.title = `Monthly School Report - Connected North`;
	});

	const handleDownloadPDF = async (withAttachments = false) => {
		setAnchorEl(null);
		setIsDownloading(true);
		try {
			await dowloadPDFReport(reportSchool, form, t, withAttachments);
		} catch (error) {
			console.error("Error downloading PDF report:", error);
		} finally {
			setIsDownloading(false);
		}
	};

	return (
		<Box sx={{ m: 3, display: "flex", flexDirection: "column", gap: 3 }}>
			<Box sx={{ display: "flex", justifyContent: "space-between" }}>
				<Typography
					variant="h1"
					sx={{
						fontSize: "2.5rem",
						fontFamily: "Effra, sans-serif",
						fontWeight: "400",
					}}
				>
					{"Monthly School Report"}
				</Typography>
				{
					!loading && form?.response_status === "submitted" &&
					<>
						<Button
							color="primary"
							outline
							id={id}
							aria-controls={`basic-menu-${id}`}
							aria-haspopup="true"
							aria-expanded={open}
							onClick={handleDownloadPDFClick}
							disabled={loading || isDownloading}
						>
							<Box component="span" display="flex" gap="0.5rem">
								{isDownloading ? "Downloading..." : "Download as PDF"}
								<KeyboardArrowDownRoundedIcon />
							</Box>
						</Button>
						<MuiMenu
							id={`basic-menu-${id}`}
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							MenuListProps={{
								"aria-labelledby": id,
							}}
						>
							<MenuItem
								component="button"
								onClick={() => handleDownloadPDF(false)}
							>
								Without Attachments
							</MenuItem>
							<MenuItem
								component="button"
								onClick={() => handleDownloadPDF(true)}
							>
								With Attachments
							</MenuItem>
						</MuiMenu>
					</>
				}
			</Box>
			{!loading ? (
				!restricted && form ? (
					<ProgressProvider form={form}>
						<FormLayout
							restricted={restricted}
							submitted={submitted}
							isParamResponse={isParamResponse}
							options={options}
						/>
					</ProgressProvider>
				) : (
					<p>Restricted</p>
				)
			) : (
				<CircularProgress />
			)}
		</Box>
	);
};

export default Screen;
