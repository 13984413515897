import { useState, useEffect } from "react";
import supabase from "../../../database";

export const useAvailableReportDates = (schoolId) => {
	const [dates, setDates] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [newestDate, setNewestDate] = useState(null);

	useEffect(() => {
		const fetchDates = async () => {
			if (!schoolId) {
				setDates([]);
				setLoading(false);
				return;
			}

			try {
				setLoading(true);
				// Query form responses with form details in a single query
				const { data, error } = await supabase.instance
					.from("form_responses")
					.select(
						`
            form_id,
            status,
            forms!inner (
              year,
              month
            )
          `,
					)
					.contains("school_ids", [schoolId])
					.order("created_at", { ascending: false });

				if (error) throw error;

				if (!data || data.length === 0) {
					console.log("No form responses found, using default date");
					const defaultDate = {
						value: "2024-12",
						label: "December 2024",
					};
					setDates([defaultDate]);
					setNewestDate(defaultDate);
					return;
				}

				// Extract unique month/year combinations and sort them
				const uniqueDates = [
					...new Set(
						data
							.filter((response) => {
								console.log("Processing response:", response);
								return response.forms?.year && response.forms?.month;
							})
							.map((response) => {
								const dateStr = `${response.forms.year}-${response.forms.month.toString().padStart(2, "0")}`;
								console.log("Mapped date:", dateStr);
								return dateStr;
							}),
					),
				].sort((a, b) => b.localeCompare(a));

				console.log("Unique dates after Set:", uniqueDates);

				// Convert to format needed for the dropdown
				const formattedDates = uniqueDates.map((dateStr) => {
					const [year, month] = dateStr.split("-");
					const date = new Date(year, parseInt(month) - 1);
					return {
						value: dateStr,
						label: date.toLocaleString("en-US", {
							month: "long",
							year: "numeric",
						}),
					};
				});

				console.log("Formatted dates:", formattedDates);

				if (formattedDates.length > 0) {
					setDates(formattedDates);
					setNewestDate(formattedDates[0]); // First date is the newest since we sorted in descending order
				} else {
					const defaultDate = {
						value: "2024-12",
						label: "December 2024",
					};
					setDates([defaultDate]);
					setNewestDate(defaultDate);
				}
			} catch (err) {
				console.error("Error fetching dates:", err);
				setError(err.message);
				// Set a default date if there's an error
				const defaultDate = {
					value: "2024-12",
					label: "December 2024",
				};
				setDates([defaultDate]);
				setNewestDate(defaultDate);
			} finally {
				setLoading(false);
			}
		};

		fetchDates();
	}, [schoolId]);

	return { dates, loading, error, newestDate };
};
