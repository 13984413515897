import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import SessionInfo from "../../components/SessionInfo";
import { Pagination } from "../../components/Pagination";
import {
	getActionUpcomingMenus,
	getDisplayInfosUpcoming,
} from "../SessionInfoDisplay";
import { hideLoading, showLoading } from "../../utils/loading";
import Axios from 'axios';
import airtable from '../../airtables';
import axios from 'axios';
import CancelSessionModal from '../modals/CancelSession';
import { delayTime } from '../../utils/time';
import RateSessionModal from '../../pages/modals/Rate';
import ReassignLeadModal from '../../pages/modals/ReassignSchoolLead';
import InternalIDModal from '../modals/InternalID';
import AssignPresenter from '../modals/AssignPresenter';
import AddMyClass from '../modals/AddMyClass';
import { toast } from 'react-toastify';
import StudentQRSurveyModal from "../modals/StudentQRSurvey";
import { getProviderSessionIDFromSession } from "../../utils/session";
import BookSessionModal from "../modals/BookSession";
import { confirmAlert } from "react-confirm-alert";
import Loading from "../../components/Loading";
import { setRequestSessionModal } from "../../redux/modalActions";
import DuplicateSessionModal from "../modals/DuplicateSession";
import AssignFundingPoolModal from "../modals/AssignFundPool";
import CancelBRSessionModal from "../modals/CancelBookRequest";

const RenderSchoolSessions = ({
	loading,
	noMessage,
	sessions: sessList,
	type,
	refreshPage
}) => {
	const { userType, userInfo } = useSelector((state) => state.appInfo);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const NUM_SESS_PER_PAGE = 6;

	const pageCount = Math.ceil(sessList.length / NUM_SESS_PER_PAGE);
	const [curPage, setCurPage] = useState(0);
	const [sessionsToShow, setSessionToShow] = useState(sessList.slice(0, NUM_SESS_PER_PAGE));
	const [sessions, setSessions] = useState([])

	const [activeSession, setActiveSession] = useState(null)
	const [showRatingModal, setShowRatingModal] = useState(false)

	const [showInternalIDModal, setShowInternalIDModal] = useState(false);
	const [activeSessionForInternalID, setActiveSessionForInternalID] = useState(null);

	const [showAssignPresenterModal, setAssignPresenterModal] = useState(false);
	const [activeSessionForAssignPresenter, setActiveSessionForAssignPresenter] = useState(null);

	const [activeSessionForAddingClass, setActiveSessionForAddingClass] = useState(null)
	const [showAddMyClassModal, setShowAddMyClassModal] = useState(false);

	const [activeCancelSession, setActiveCancelSession] = useState(null);
	const [showCancelSessionModal, setShowCancelSessionModal] = useState(false);

	// reassign lead
	const [activeReassignSession, setActiveReassignSession] = useState(null);
	const [showReassignLeadModal, setShowReassignLeadModal] = useState(false);

	// student qr survey
	const [activeQRSession, setActiveQRSession] = useState(null);
	const [showStudentQRSurveyModal, setShowStudentQRSurveyModal] = useState(false);

	//rebook session
	const [activeCancelledSession, setActiveCancelledSession] = useState(null);
	const [showRebookModal, setShowRebookModal] = useState(false);

	//duplicate session
	const [activeDuplicateSession, setActiveDuplicateSession] = useState(null);
	const [showDuplicateSessionModal, setShowDuplicateSessionModal] = useState(false);

	// assign to funding pool
	const [activeAssignFundPoolSession, setActiveAssignFundPoolSession] = useState(null);
	const [showAssignFundPoolModal, setShowAssignFundPoolModal] = useState(false);

	// cancel booked or requested session
	const [activeCancelRBSession, setActiveCancelRBSession] = useState(null);
	const [cancelBookedOrRequestedSessionModal, showCancelBookedOrRequestedSessionModal] = useState(false);


	useEffect(() => {
		setSessions(sessList);
	}, [sessList]);

	useEffect(() => {
		setSessionToShow(
			sessions.slice(
				curPage * NUM_SESS_PER_PAGE,
				(curPage + 1) * NUM_SESS_PER_PAGE,
			),
		);
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, [curPage, sessions]);

	const bookOnCILC = async (session) => {
		showLoading("Processing...");
		const res = await Axios.get("https://research.tigweb.org/connectednorth/cilc/?recID=" + session.id);
		const cilcReqId = res.data["CILC Request ID"];

		const sessionIndex = sessions.findIndex(s => s.id === session.id);
		const updatedSessions = [...sessions];
		updatedSessions[sessionIndex] = {
			...session,
			'CILC Request ID': String(cilcReqId),
			'CILC Request URL': `https://www.cilc.org/ContentProvider/ViewAProgramRequest.aspx?Type=S&id=${cilcReqId}`
		};
		setSessions(updatedSessions);
		hideLoading();
	}

	const postRating = () => {
		setActiveSession(null);
		setShowRatingModal(false);
		refreshPage();
	}

	const onRequestSupport = (e) => {
		e.preventDefault();
		window.location.href = "tel:8679920055"
	}

	const reassignSessionLead = (e, session) => {
		e.preventDefault();

		setActiveReassignSession(session);
		setShowReassignLeadModal(true);
	}

	const editSession = (session) => {
		dispatch(setRequestSessionModal({
			visible: true,
			providerSession: session,
			onSubmitted: () => {
				sendNotificationForChange(session.id, 'change', userType);
				refreshPage();
			},
			isEdit: true,
			prefillSession: session
		}))
	}

	const openInternalIDModal = (session) => {
		setActiveSessionForInternalID(session);
		setShowInternalIDModal(true);
	}

	const assignPresenter = (session) => {
		setActiveSessionForAssignPresenter(session);
		setAssignPresenterModal(true)
	}

	const openAddMyClassModal = (session) => {
		setActiveSessionForAddingClass(session);
		setShowAddMyClassModal(true);
	}

	const rateSession = (e, session) => {
		e.preventDefault();
		setActiveSession(session)
		setShowRatingModal(true)
	}

	const cancelSession = (e, session) => {
		e.preventDefault();
		setActiveCancelSession(session)
		setShowCancelSessionModal(true);
	}

	const onCancelSession = async (reason, feedback) => {
		try {
			const cancelledSession = await airtable.sessions.update(activeCancelSession.id, {
				"Cause of Cancellation/Reschedule": reason,
				"Team Feedback": feedback
			});
			await delayTime(4000);
			toast.success("Session Cancellation successful!")
			setActiveCancelSession(null);
			setShowCancelSessionModal(false);

			confirmAlert({
				title: "Connected North",
				message: "Your cancellation has been successfully processed. Do you want to rebook this session for a new date and time?",
				buttons: [
					{
						label: "Rebook",
						onClick: async () => rebookSession(cancelledSession),
					},
					{
						label: "No thanks",
						onClick: () => refreshPage(),
					},
				],
			});
		} catch (error) {
			throw error;
		}
	}

	const onStudentSurvey = (session) => {
		setActiveQRSession(session);
		setShowStudentQRSurveyModal(true)
	}

	const sendNotificationForChange = (sessId, action, userType) => {
		if (userType === 'Team') return;
		axios.get(`https://hooks.zapier.com/hooks/catch/89715/b6i5gt7?RequestID=${sessId}&action=${action}`)
	}

	const rebookSession = (session) => {
		setActiveCancelledSession(session);
		setShowRebookModal(true);
	}

	const duplicateSession = (e, session) => {
		setActiveDuplicateSession(session);
		setShowDuplicateSessionModal(true);
	}

	const assignToFundingPool = (e, session) => {
		setActiveAssignFundPoolSession(session);
		setShowAssignFundPoolModal(true);
	}

	const cancelRequestedOrBookedSession = (session) => {
		showCancelBookedOrRequestedSessionModal(true);
		setActiveCancelRBSession(session);
	}

	const cancelRegistration = (session) => {
		confirmAlert({
			title: "Cancel Registration",
			message: "Are you sure you wish to cancel your registration for this event?",
			buttons: [{
				label: "Confirm",
				onClick: async () => {
					showLoading("Cancelling Registration");
					let sessionSchoolIds = session["School(s)"];
					const teacherIds = session["Teacher"].filter(tn => tn !== userInfo.id);
					if (userInfo["School Name"] && userInfo["School Name"].length) {
						const sName = userInfo["School Name Text"][0];
						if (session["School Name Text"] && session["School Name Text"].indexOf(sName) >= 0) {
							const teachers = await airtable.teachers.listByIds(teacherIds);
							const shouldRemoveSchool = teachers.reduce((result, teacher) => {
								if (!result) return false;
								if (!teacher["School Name Text"] || teacher["School Name Text"].length === 0) return result;
								if (teacher["School Name Text"].indexOf(sName) < 0) return result;
								return false;
							}, true);

							if (shouldRemoveSchool) {
								sessionSchoolIds = session["School(s)"].filter(sId => sId !== userInfo["School Name"][0]);
							}
						}
					}

					await airtable.sessions.update(session.id, {
						Teacher: teacherIds,
						"School(s)": sessionSchoolIds
					});
					await airtable.events.deleteEvent(userInfo["Email"], session.Session);

					const sNo = sessions.findIndex(sess => sess.id === session.id);
					let nSessions = [...sessions]; 
					nSessions.splice(sNo, 1);
					setSessions(nSessions);
					hideLoading();
					toast.success("Your Class has successfully been removed from registration for this Event!")
				}
			},
			{
				label: "Cancel",
			}]
		})
	}

	if (loading) {
		return (
			<div className="upcoming-loader">
				<Loading size={30} />
			</div>
		)
	}

	if (!sessions || sessions.length === 0) {
		if (noMessage) {
			return (
				<div className="nolist-placeholder" align="center">
					<span>{noMessage}</span>
				</div>
			);
		} else {
			return (
				<div className="nolist-placeholder" align="center">
					<span>{t("no-upcoming-sessions-at-school")}</span>
				</div>
			)
		}
	}

	return (
		<>
			<div className="session-grid">
				{sessionsToShow.map((session, i) => (
					<SessionInfo
						key={i}
						displayInfos={getDisplayInfosUpcoming(
							session,
							userType,
							type,
						)}
						actionMenu={getActionUpcomingMenus(
							session,
							userType,
							type,
						)}
						session={session}
						rateSession={rateSession}
						sessionDetailLink={getProviderSessionIDFromSession(session)}
						openInternalIDModal={openInternalIDModal}
						assignPresenter={assignPresenter}
						requestSupport={onRequestSupport}
						openAddMyClassModal={openAddMyClassModal}
						cancelRequestedOrBookedSession={cancelRequestedOrBookedSession}
						cancelSession={cancelSession}
						dateType={type !== "Past" ? "normal" : "full"}
						reassignSessionLead={reassignSessionLead}
						editRequest={editSession}
						bookOnCILC={bookOnCILC}
						cancelRegistration={cancelRegistration}
						studentSurvey={onStudentSurvey}
						duplicateSession={duplicateSession}
						assignToFundingPool={assignToFundingPool}
					/>
				))}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				className="mb-5"
			>
				{sessions.length > NUM_SESS_PER_PAGE && (
					<Pagination
						setPage={setCurPage}
						pageCount={pageCount}
						page={curPage}
					/>
				)}
			</div>

			{showRatingModal && activeSession ? (
				<RateSessionModal
					session={activeSession}
					onDone={() => postRating()}
					onToggle={() => setShowRatingModal(!showRatingModal)}
				/>
			) : null}

			{(activeSessionForInternalID && showInternalIDModal) ? (
				<InternalIDModal
					session={activeSessionForInternalID}
					onToggle={() => setShowInternalIDModal(!showInternalIDModal)}
					onSave={async (sessionId, internalId) => {
						setShowInternalIDModal(false);
						await airtable.sessions.update(sessionId, { "Provider Internal ID": internalId })
						const newSessions = [...sessions]
						for (const session of newSessions) {
							if (session.id === sessionId) {
								session["Provider Internal ID"] = internalId;
							}
						}
						setSessions(newSessions);
					}}
				/>
			) : null}

			{(showAssignPresenterModal && activeSessionForAssignPresenter) ? (
				<AssignPresenter
					session={activeSessionForAssignPresenter}
					onToggle={() => setAssignPresenterModal(!showAssignPresenterModal)}
					onConfirm={async (sessionId, presenters) => {
						setAssignPresenterModal(false);
						const newSession = await airtable.sessions.update(sessionId, { 'Presenters': presenters });

						const sessionIdx = sessions.findIndex(sess => sess.id === sessionId);
						if (sessionIdx >= 0) {
							const newSessions = [...sessions];
							newSessions[sessionIdx] = newSession;
							setSessions(newSessions)
						}
					}}
				/>
			) : null}

			{(activeSessionForAddingClass && showAddMyClassModal) && (
				<AddMyClass
					session={activeSessionForAddingClass}
					onToggle={() => setShowAddMyClassModal(!showAddMyClassModal)}
					onDone={() => {
						setShowAddMyClassModal(false);
						toast.success("You have been successfully added to the session requested, and should receive a calendar invite with connection details within 15 minutes")
						refreshPage();
					}}
					showModal={true}
				/>
			)}

			{(showCancelSessionModal && activeCancelSession) ? (
				<CancelSessionModal
					session={activeCancelSession}
					onToggle={() => setShowCancelSessionModal(!showCancelSessionModal)}
					onDone={(reason, feedback) => onCancelSession(reason, feedback)}
				/>
			) : null}

			{(showReassignLeadModal && activeReassignSession) ? (
				<ReassignLeadModal
					session={activeReassignSession}
					onDone={
						() => {
							setActiveReassignSession(null);
							setShowReassignLeadModal(false);
							refreshPage();
						}
					}
					onToggle={() => setShowReassignLeadModal(!showReassignLeadModal)}
				/>
			) : null}

			{(showStudentQRSurveyModal && activeQRSession) && (
				<StudentQRSurveyModal
					session={activeQRSession}
					onToggle={() => {
						setActiveQRSession(null);
						setShowStudentQRSurveyModal(false);
					}}
				/>
			)}

			{(showRebookModal && activeCancelledSession) && (
				<BookSessionModal
					providerSessionId={activeCancelledSession["Session Title"][0]}
					prefill={activeCancelledSession}
					onToggle={() => {
						setShowRebookModal(false);
						setActiveCancelledSession(null);
						refreshPage();
					}}
				/>
			)}

			{(showDuplicateSessionModal && !!activeDuplicateSession) && (
				<DuplicateSessionModal
					session={activeDuplicateSession}
					onToggle={() => {
						setActiveDuplicateSession(null);
						setShowDuplicateSessionModal(false)
					}}
				/>
			)}

			{(showAssignFundPoolModal && !!activeAssignFundPoolSession) && (
				<AssignFundingPoolModal
					session={activeAssignFundPoolSession}
					onToggle={() => {
						setActiveAssignFundPoolSession(null);
						setShowAssignFundPoolModal(false)
					}}
				/>
			)}

			{(cancelBookedOrRequestedSessionModal && !!activeCancelRBSession) && (
				<CancelBRSessionModal
					session={activeCancelRBSession}
					onToggle={() => {
						showCancelBookedOrRequestedSessionModal(false);
						setActiveCancelRBSession(null);
					}}
					toChangeEdit={(session) => {
						editSession(session);
						showCancelBookedOrRequestedSessionModal(false);
						setActiveCancelRBSession(null);
					}}
					onDone={() => {
						showCancelBookedOrRequestedSessionModal(false);
						setActiveCancelRBSession(null);
						refreshPage();
					}}
				/>
			)}
		</>
	);
};

export default RenderSchoolSessions;
