import React, { Fragment, useState } from "react";
import { Col, Row } from "reactstrap";
import useSchoolOrders from "./hooks/use-school-orders";
import SectionLoader from "../../components/SectionLoader";
import SchoolResourceOrderItem from "./components/SchoolResourceOrderItem";
import { Pagination } from "../../components/Pagination";
import ReviewResourceRequestModal from "../modals/ReviewResourceRequest";

const Screen = () => {
  const [refresh, setRefresh] = useState(0);
  const { loading, orders } = useSchoolOrders(refresh);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeOrder, setActiveOrder] = useState(null);
  const [showModal, setShowModal] = useState(false)
  const pagesize = 10;

  const renderContent = () => {
    if (loading) {
      return <SectionLoader />
    }

    const pageCount = Math.ceil(orders.length / pagesize);
    const ordersToShow = orders.slice(currentPage, (currentPage + 1) * pagesize);
    return (
      <Fragment>
        <div className="school-order-list">
          {ordersToShow.map((item, i) => (
            <SchoolResourceOrderItem
              data={item}
              key={i}
              onView={() => {
                setActiveOrder(item);
                setShowModal(true);
              }}
            />
          ))}
        </div>
        <div className="paginate pb-4">
          {orders.length > pagesize && (
            <Pagination
              page={currentPage}
              setPage={(p) => {
                setCurrentPage(p);
              }}
              pageCount={pageCount}
            />
          )}
        </div>
      </Fragment>
    )
  }

  return (
    <div className="main-container school-resource-orders">
      <Row>
        <Col md="10" lg="8">
          <h1>My School's Orders & Requests</h1>
          <p>Below, you can review learning resources ordered by educators at your school, and approve any pending orders awaiting your approval.</p>
        </Col>
      </Row>

      {renderContent()}

      {showModal && !!activeOrder && (
        <ReviewResourceRequestModal
          resourceOrder={activeOrder}
          toggle={() => {
            setActiveOrder(null);
            setShowModal(false);
          }}
          refresher={() => setRefresh(refresh + 1)}
        />
      )}
    </div>
  )
}

export default Screen;