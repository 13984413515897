import { jsPDF } from "jspdf";
import { PDFDocument, PDFName } from "pdf-lib";
import autoTable from "jspdf-autotable";
import GNlogo from "../../../assets/img/GN-logo.jpg";
import { fetchFormById } from "../queries/fetch-form";
import { fetchFormFields } from "../queries/fetch-form-fields";
import { fetchFormResponseValues } from "../queries/fetch-form-response-values";
import { FORM_RESPONSE_VALUES_FIELD_IDS } from "../../Principal/constants/form-response-values-field-ids";
import { transformUseAllFormResponseValues } from "../../Principal/utils/transformUseFormResponseValues";
import { Chart as ChartJS } from 'chart.js';
import { stripEmptyData } from "../../../utils/reportsBar";
import supabase from "../../../database";

const groupsToHide = [
  "b7f97a08-e891-4af3-b1cb-635ea7275c97", // Profile page General details section
]

export const dowloadPDFReport = async (reportSchool, form, t, withAttachments) => {
  // Initialize PDF document
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "letter", // Standard Letter format
  });

  // Define constants for layout
  const layout = {
    margins: {
      right: 10,
      left: 10,
      bottom: 10,
      group: 15, // Left margin for group content
    },
    spacing: {
      afterTitle: 20,
      afterHeading: 10,
      afterGroup: 5,
    },
    fontSize: {
      title: 28,
      pageHeading: 24,
      groupHeading: 20,
    },
  };

  // Calculate available widths
  const pageWidth = doc.internal.pageSize.getWidth();
  const contentWidth = pageWidth - layout.margins.left - layout.margins.right;
  const groupWidth = pageWidth - layout.margins.group - layout.margins.right;

  // Helper function to check and add new page if needed
  function ensureSpace(requiredSpace) {
    // Check if there's enough space on current page
    if (yPosition + requiredSpace > doc.internal.pageSize.getHeight()) {
      // Add new page
      doc.addPage();
      // Reset yPosition to top of new page (with margin)
      yPosition = 20;
      return yPosition;
    }
    // If there's enough space, return current position
    return yPosition;
  }

  // Add logo
  const imgWidth = 28;
  const imgHeight = 35;
  doc.addImage(GNlogo, "jpg", 10, 10, imgWidth, imgHeight);

  // Add school name
  doc.setFontSize(layout.fontSize.title);
  doc.setFont("helvetica", "bold");
  const title = reportSchool?.School;
  doc.text(title, 10 + imgWidth + 2, 32);
  doc.setFont("helvetica", "normal");

  // Add Report for MONTH YEAR
  const subtitle = "Report for " + form["name"];
  doc.text(subtitle, 10 + imgWidth + 2, 44);

  let yPosition = 65;

  // fetch data with attendance section and metrics data
  const data = await fetchFormById(form.id);
  data.updateWithPages(await fetchFormFields(form.template_id, true));
  const formResponse = await fetchFormResponseValues(form.response_id);
  data.updateWithResponseValues(formResponse);

  const fieldIds = Object.values(FORM_RESPONSE_VALUES_FIELD_IDS);
  const fieldKeys = Object.keys(FORM_RESPONSE_VALUES_FIELD_IDS);

  const fieldsData = formResponse.filter(r => fieldIds.includes(r.field_id));
  const fieldsDataSort = fieldIds.map((fieldId, idx) => {
    const valuesPerField = fieldsData.filter((field) => field.field_id === fieldId);
    return { [fieldKeys[idx]]: valuesPerField };
  });

  const transformResponses = {};
  fieldsDataSort.forEach((item) => {
    const [key, values] = Object.entries(item)[0];
    transformResponses[key] = values;
  });

  const transformedData = transformUseAllFormResponseValues(
    transformResponses,
    false,
  );

  doc.setFontSize(18);
  doc.text("Report Highlights", 10, yPosition);

  yPosition += 10;

  const rectWidth = ( contentWidth - 10 ) / 3;

  doc.setDrawColor(17, 205, 239);

  // First rect
  doc.setFillColor(243, 249, 255);
  doc.roundedRect(10, yPosition, rectWidth, 25, 2, 2, 'FD');
  doc.setFontSize(14);
  doc.text(t("principal.metrics-grid.number-card.school-population"), 10 + rectWidth * 0.5, yPosition + 8, 'center');
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(17, 205, 239);
  doc.text(transformedData?.studentPopulationData?.[0]?.value || 0, 10 + rectWidth * 0.5, yPosition + 20, 'center');
  doc.setTextColor(0, 0, 0);
  doc.setFont('helvetica', 'normal');

  // Second rect
  doc.setFillColor(243, 249, 255);
  doc.roundedRect(15 + rectWidth, yPosition, rectWidth, 25, 2, 2, 'FD');
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(14);
  doc.text(t("principal.metrics-grid.number-card.graduates"), 15 + rectWidth * 1.5, yPosition + 8, 'center');
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(17, 205, 239);
  doc.text(transformedData?.graduatingStudentsData?.[0]?.value || 0, 15 + rectWidth * 1.5, yPosition + 20, 'center');
  doc.setTextColor(0, 0, 0);
  doc.setFont('helvetica', 'normal');

  // Third rect
  doc.setFillColor(243, 249, 255);
  doc.roundedRect(20 + rectWidth * 2, yPosition, rectWidth, 25, 2, 2, 'FD');
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(14);
  doc.text(t("principal.metrics-grid.number-card.community-population"), 20 + rectWidth * 2.5, yPosition + 8, 'center');
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(17, 205, 239);
  doc.text(transformedData?.communityPopulationData?.[0]?.value || 0, 20 + rectWidth * 2.5, yPosition + 20, 'center');
  doc.setTextColor(0, 0, 0);
  doc.setFont('helvetica', 'normal');

  yPosition += 30;

  const sumCount = (data) => data.reduce((acc, curr) => acc + curr, 0);

  doc.setFillColor(243, 249, 255);
  doc.roundedRect(10, yPosition, contentWidth, 75, 2, 2, 'FD');
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(17, 205, 239);
  const totalClosureDays = sumCount(transformedData?.schoolClosureReasonData?.counts) || 0;
  doc.text(totalClosureDays.toString() + " " + t("principal.metrics-grid.closures-card.title"), 15, yPosition + 8, 'left');
  doc.setTextColor(0, 0, 0);
  doc.setFont('helvetica', 'normal');

  const closureLabels = ["a", "b", "c", "d", "e", "f", "g", "h"].map((key) =>
    t(`db_fields.closureReason.options.${key}`),
  );

  const labels = [...closureLabels, 'None']

  const rawData = transformedData?.schoolClosureReasonData?.counts || []

  const data1 = [...rawData, rawData.some(d => d !== 0) ? 0 : 1]
  const dataObject = {
    labels: labels,
    datasets: [
      {
        label: "Dataset 1",
        data: data1,
        backgroundColor: ["#63abfd", "#ffa5cb", "#F765A3", "#A155B9", "#FFA07A", "#FFD700", "#FF6347", "#FF4500", "#e0e0e0"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      emptyDoughnut: {
        color: 'rgba(255, 128, 0, 0.5)',
        width: 2,
        radiusDecrease: 20
      },
      legend: {
        display: true,
        position: "bottom",
        align: "center",
        labels: {
          generateLabels: (chart) => {
            const dataset = chart.data.datasets[0];
            return chart.data.labels.map((label, index) => ({
              text: `${label}: ${dataset.data[index]}`,
              fillStyle: dataset.backgroundColor[index],
              hidden: isNaN(dataset.data[index])
                ? false
                : !chart.getDataVisibility(index),
              lineWidth: dataset.borderWidth,
              index: index,
            }));
          },
          font: {
            weight: "400",
            size: 28,
            family: "Effra, sans-serif",
          },
          color: "#000000",
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 15,
          boxHeight: 15,
        },
      },
      title: { display: false },
    },
    cutout: "55%",
  };

  const canvas = document.createElement('canvas');
  canvas.width = 1500;
  canvas.height = 500;
  const ctx = canvas.getContext('2d');

  // Create the chart on the offscreen canvas
  new ChartJS(ctx, {
    type: 'doughnut',
    data: dataObject,
    options: {
      ...options,
      animation: {
        duration: 0, // Disable animations to force immediate rendering
      },
      responsive: false,
    },
  });

  // Convert the canvas content to an image
  const chartImageUrl = canvas.toDataURL('image/png');

  // Generate the PDF using jsPDF
  doc.addImage(chartImageUrl, 'PNG', 20, yPosition + 15, 165, 55, '', 'FAST');

  yPosition += 80;

  // Suspension Chart Card
  doc.setFillColor(243, 249, 255);
  doc.roundedRect(10, yPosition, rectWidth, 50, 2, 2, 'FD');
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(17, 205, 239);
  const totalSuspensions = sumCount(transformedData?.suspensionGradeData) || 0;
  doc.text(totalSuspensions.toString() + " " + t("principal.metrics-grid.suspensions-card.title"), 15, yPosition + 8, 'left');
  doc.setTextColor(0, 0, 0);
  const suspensionHoursInSchoolData = transformedData?.suspensionHoursInSchoolData;
  const suspensionHoursOutOfSchoolData = transformedData?.suspensionHoursOutOfSchoolData;
  const gradeLabels = ["a",	"b",	"c",	"d",	"e",	"f",	"g",	"h",	"i",	"j",	"k",	"l",	"m"].map((key) => t(`principal.metrics-grid.grade-list.${key}`));
  const { labels: sLabels, data: sData, secondaryData: sSecondaryData } = stripEmptyData(gradeLabels, suspensionHoursInSchoolData, suspensionHoursOutOfSchoolData, null, true);
  const sdatasets = [
    {
      label: "In School Days",
      fill: true,
      backgroundColor: "#63ABFD",
      borderColor: "#63ABFD",
      borderWidth: 2,
      data: sData,
    },
    {
      label: "Out of School Days",
      fill: true,
      backgroundColor: "#E697FF",
      borderColor: "#E697FF",
      borderWidth: 2,
      data: sSecondaryData,
    }
  ];

  const scanvas = document.createElement('canvas');
  scanvas.width = 500;  // Set a proper width for the canvas
  scanvas.height = 300; // Set a proper height for the canvas
  const sctx = scanvas.getContext('2d');

  // Create the chart on the offscreen canvas
  new ChartJS(sctx, {
    type: 'bar',
    data: {
      labels: sLabels,
      datasets: sdatasets,
    },
    options: {
      animation: {
        duration: 0, // Disable animations to force immediate rendering
      },
      responsive: false,
      scales: {
        x: {
          grid: { display: false },
          ticks: {
            font: { weight: "400", size: 24, family: "Effra, sans-serif" },
            color: "#0D1120",
          },
        },
        y: {
          grid: { display: false },
          ticks: {
            font: { weight: "400", size: 24, family: "Effra, sans-serif" },
            color: "#0D1120",
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          align: "start",
          labels: {
            font: { weight: "400", size: 24, family: "Effra, sans-serif" },
            color: "#0D1120",
            usePointStyle: true,
            pointStyle: "circle",
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        title: { display: false },
      },
    },
  });

  // Convert the canvas content to an image
  const schartImageUrl = scanvas.toDataURL('image/png');

  doc.addImage(schartImageUrl, 'PNG', 15, yPosition + 15, 50, 30, '', 'FAST');  // Add image to PDF and scale it to fit in this space



  // Explusion Chart Card
  doc.setFillColor(243, 249, 255);
  doc.roundedRect(15 + rectWidth, yPosition, rectWidth, 50, 2, 2, 'FD');
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(17, 205, 239);
  const totalExpulsions = sumCount(transformedData?.expulsionGradeData) || 0;
  doc.text(totalExpulsions.toString() + " " + t("principal.metrics-grid.expulsions-card.title"), 20 + rectWidth, yPosition + 8, 'left');
  doc.setTextColor(0, 0, 0);
  const expulsionGradeData = transformedData?.expulsionGradeData;

  const { labels: eLabels, data: eData } = stripEmptyData(gradeLabels, expulsionGradeData, null, null, true);
  const edatasets = [
    {
      label: "Expulsions",
      fill: true,
      backgroundColor: "#63ABFD",
      borderColor: "#63ABFD",
      borderWidth: 2,
      data: eData,
    }
  ];

  const ecanvas = document.createElement('canvas');
  ecanvas.width = 500;  // Set a proper width for the canvas
  ecanvas.height = 300; // Set a proper height for the canvas
  const ectx = ecanvas.getContext('2d');

  // Create the chart on the offscreen canvas
  new ChartJS(ectx, {
    type: 'bar',
    data: {
      labels: eLabels,
      datasets: edatasets,
    },
    options: {
      animation: {
        duration: 0, // Disable animations to force immediate rendering
      },
      responsive: false,
      scales: {
        x: {
          grid: { display: false },
          ticks: {
            font: { weight: "400", size: 24, family: "Effra, sans-serif" },
            color: "#0D1120",
          },
        },
        y: {
          grid: { display: false },
          ticks: {
            font: { weight: "400", size: 24, family: "Effra, sans-serif" },
            color: "#0D1120",
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          align: "start",
          labels: {
            font: { weight: "400", size: 24, family: "Effra, sans-serif" },
            color: "#0D1120",
            usePointStyle: true,
            pointStyle: "circle",
            boxWidth: 10,
            boxHeight: 10,
          },
        },
        title: { display: false },
      },
    },
  });

  // Convert the canvas content to an image
  const echartImageUrl = ecanvas.toDataURL('image/png');

  doc.addImage(echartImageUrl, 'PNG', 20 + rectWidth, yPosition + 15, 50, 30, '', 'FAST');  // Add image to PDF and scale it to fit in this space


  // Incidents of Violence Card
  let cardYPosition = yPosition;
  doc.setFillColor(243, 249, 255);
  doc.roundedRect(20 + rectWidth * 2, cardYPosition, rectWidth, 50, 2, 2, 'FD');
  cardYPosition += 8;
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(14);
  let text = t("principal.metrics-grid.stats-card.violence");
  let textLines = doc.splitTextToSize(text, rectWidth - 10);
  textLines.forEach((line) => {
    doc.text(line, 25 + rectWidth * 2, cardYPosition);
    cardYPosition += 5;
  });
  cardYPosition += 5;
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(17, 205, 239);
  doc.text((transformedData?.suspensionViolenceStudentsData || 0).toString(), 25 + rectWidth * 2, cardYPosition, 'left');
  doc.setTextColor(0, 0, 0);
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(14);
  cardYPosition += 8;
  text = t("principal.metrics-grid.stats-card.incidents");
  textLines = doc.splitTextToSize(text, rectWidth - 10);
  textLines.forEach((line) => {
    doc.text(line, 25 + rectWidth * 2, cardYPosition);
    cardYPosition += 5;
  });
  cardYPosition += 5;
  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor(17, 205, 239);
  doc.text((transformedData?.suspensionViolenceStaffData || 0).toString(), 25 + rectWidth * 2, cardYPosition, 'left');
  doc.setTextColor(0, 0, 0);

  doc.addPage();
  yPosition = 20;
  const filewithLinkPositions = [];
  // Process each page
  data.pages.forEach((page) => {

    let pHeadingDisplayed = false;
    // Process groups
    page.groups.forEach((group) => {

      if (groupsToHide.includes(group.id)) return;

      const fields = group.fields.map((field) => {
        let d = { ...field };
        group.instances.forEach((i) => {
          d[`instance${i["instance_number"]}`] = i.fields.find(
            (f) => f.field_id === field.id,
          );
        });
        return d;
      });

      const groupInstances = [];
      let allInstancesEmpty = true;
      group.instances.forEach(instance => {
        const data = fields.map((field) => {
          const fieldHeading = t(
            `db_fields.${field.translation_id || "default"}.heading`,
          );
          let value =
            field[`instance${instance["instance_number"]}`]?.value;

          if (field.type === "file") {

            const instanceId = field[`instance${instance["instance_number"]}`]?.id;
            if (value) {
              const urls = [];
              JSON.parse(value).forEach(f => {
                const filePath = `${instanceId}/${f.name}`;
                const data = supabase.formResponseValues.getFilePublicUrl(filePath);
                urls.push({name: f.name, url: data.publicUrl, type: f.type});
              })
              value = urls;
            }
          } else if (["single select", "radio"].includes(field.type)) {
            value = value
              ? t(
                  `db_fields.${field.translation_id || "default"}.options.${value}`,
                )
              : "";
          } else if (field.type === "multi select") {
            if (value && typeof value === "string") {
              try {
                value = JSON.parse(value);
                value = value.map((v) => {
                  const translation = t(
                    `db_fields.${field.translation_id || "default"}.options.${v}`,
                  );
                  return translation ===
                    `db_fields.${field.translation_id || "default"}.options.${v}`
                    ? v
                    : translation;
                });
              } catch {
                value = [];
              }
            }
            value = Array.isArray(value) ? value.join(", ") : "";
          }

          return [fieldHeading, value || ""];
        });
        const allValuesEmpty = data.every(row => row[1] === "");
        if (!allValuesEmpty) {
          groupInstances.push(data.filter(row => row[1] !== ""));
          allInstancesEmpty = false;
        }
      });

      if (allInstancesEmpty) return; // Skip rendering if all instances are empty

      if (!pHeadingDisplayed) {
        // Add page heading
        doc.setFontSize(layout.fontSize.pageHeading);
        doc.setFont("helvetica", "bold");
        const pageHeading = t(
          `db_group_pages.${page.translation_id || "default"}.heading`,
        );
        const pageLines = doc.splitTextToSize(pageHeading, contentWidth);

        // Check if we need a new page for the section
        yPosition = ensureSpace(
          pageLines.length * layout.spacing.afterHeading + 15,
        );

        // Add page heading
        pageLines.forEach((line) => {
          doc.text(line, layout.margins.left, yPosition);
          yPosition += layout.spacing.afterHeading;
        });
        doc.setFont("helvetica", "normal");

        pHeadingDisplayed = true;
      }

      doc.setFontSize(layout.fontSize.groupHeading);
      const groupHeading = t(
        `db_field_groups.${group.translation_id || "default"}.heading`,
      );
      if (groupHeading.trim().length > 0) {
        const groupLines = doc.splitTextToSize(groupHeading, groupWidth);

        // Check if we need a new page for the group
        yPosition = ensureSpace(
          groupLines.length * layout.spacing.afterHeading + 5,
        );

        // Add group heading
        groupLines.forEach((line) => {
          doc.text(line, layout.margins.left, yPosition);
          yPosition += layout.spacing.afterHeading;
        });
      }

      groupInstances.forEach(instance => {
        autoTable(doc, {
          startY: yPosition,
          head: [["Field", "Details"]],
          body: instance,
          theme: "grid",
          margin: { top: 10, left: 14, right: 14 },
          headStyles: {
            fillColor: [3, 93, 152],
          },
          columnStyles: {
            0: {
              cellWidth: doc.internal.pageSize.getWidth() * 0.3, // 30% of page width
              minCellWidth: doc.internal.pageSize.getWidth() * 0.15, // 15% of page width
            },
            1: { cellWidth: "auto" }, // Will take remaining space
          },
          didDrawPage: (data) => {
            yPosition = data.cursor.y + 10;
          },
          willDrawCell: (data) => {
            if (data.column.index === 1 && Array.isArray(data.cell.raw)) {
              // Format cell content as comma-separated file names
              const fileNames = data.cell.raw.map(file => file.name).join(", ");
              data.cell.text = fileNames;

              // Add clickable links for each file
              data.cell.raw.forEach((file, index) => {
                const text = file.name;
                const textWidth = doc.getTextWidth(text);
                const textHeight = doc.getFontSize();

                let textX, textY;
                if (data.cell.textPos) {
                  textX = data.cell.textPos.x;
                  textY = data.cell.textPos.y;
                } else {
                  const paddingLeft = 4;
                  textX = data.cell.x + paddingLeft;
                  textY = data.cell.y + doc.getFontSize() + 2;
                }

                // Adjust X position for subsequent files based on previous text width
                if (index > 0) {
                  textX += doc.getTextWidth(data.cell.raw.slice(0, index).map(f => f.name + ", ").join(""));
                }

                const linkY = textY - textHeight;
                filewithLinkPositions.push({
                  fileName: file.name,
                  url: file.url,
                  type: file.type,
                  pageNumber: doc.internal.getNumberOfPages(), // jsPDF page number where the text is drawn
                  x: textX, // x coordinate where the file name is drawn
                  y: linkY, // y coordinate (top-left) where the file name is drawn
                  width: textWidth, // width of the text (can be computed using doc.getTextWidth)
                  height: textHeight, // font size or computed height
                });
                // doc.link(textX, linkY, textWidth, textHeight, { url: file.url });
              });
            }
          },
        });
      })

      // Add light blue line after group
      doc.setDrawColor(173, 216, 230); // Light blue color
      doc.setLineWidth(0.5);
      const lineWidth = 100; // Width of the line in mm
      const startX = (pageWidth - lineWidth) / 2; // Center the line
      doc.line(startX, yPosition, startX + lineWidth, yPosition);

      yPosition += 15; // Add some space after the line
    });

  });

  const mergedDoc = await PDFDocument.create();

  // Convert jsPDF document to bytes and load it
  const arrayBuffer = doc.output('arraybuffer');
  const jsPdfDoc = await PDFDocument.load(arrayBuffer);

  // Copy all pages from the main document
  const jsPdfPages = await mergedDoc.copyPages(jsPdfDoc, jsPdfDoc.getPageIndices());
  jsPdfPages.forEach(page => mergedDoc.addPage(page));

  let pageCount = doc.internal.getNumberOfPages(); // mainPageCount from the jsPDF doc
  if(withAttachments) {
    for (const file of filewithLinkPositions) {
      try {
        const response = await fetch(file.url);
        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`);
            return pageCount;
        }

        if (file.type === "application/pdf") {
            const existingPdfBytes = await response.arrayBuffer();
            const existingPdfDoc = await PDFDocument.load(existingPdfBytes);
            const copiedPages = await mergedDoc.copyPages(existingPdfDoc, existingPdfDoc.getPageIndices());
            copiedPages.forEach((page) => mergedDoc.addPage(page));

            addLinkAnnotation(
                mergedDoc.getPage(file.pageNumber - 1),
                getAnnotationConfig(file, pageCount),
                mergedDoc
            );
            pageCount = pageCount + copiedPages.length;
        }

        if (file.type.startsWith("image/")) {
            const imageBlob = await (await fetch(file.url)).blob();
            const uint8Array = new Uint8Array(await resizeImage(imageBlob));
            const embeddedImage = await mergedDoc.embedPng(uint8Array);
            const { width, height } = embeddedImage.scale(1);

            mergedDoc.addPage([width, height]).drawImage(embeddedImage, { x: 0, y: 0, width, height });

            addLinkAnnotation(
                mergedDoc.getPage(file.pageNumber - 1),
                getAnnotationConfig(file, pageCount),
                mergedDoc
            );
            pageCount = pageCount + 1;
        }

      } catch (error) {
        console.error("Error processing attachment:", error);
      }
    }
  }

  const finalPdfBytes = await mergedDoc.save();
  const finalBlob = new Blob([finalPdfBytes], { type: 'application/pdf' });
  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(finalBlob);
  downloadLink.download = title + " " + subtitle;
  downloadLink.click();

}

const getAnnotationConfig = (file, targetPageIndex) => {
  return {
      x: file.x * 2.83465 - 5,
      y: file.y * 2.83465 - 5,
      width: file.width * 2.83465 - 5,
      height: file.height,
      targetPageIndex
  };
}

const addLinkAnnotation = (page, { x, y, width, height, targetPageIndex }, pdfDoc) => {
  const { height: pageHeight } = page.getSize();

  // Convert the y coordinate from a top-left origin (jsPDF) to bottom-left (PDF-lib)
  const lowerLeftY = pageHeight - y - height;

  // Create the rectangle array (PDF expects [llx, lly, urx, ury])
  const rectArray = [x, lowerLeftY, x + width, lowerLeftY + height];
  const rect = pdfDoc.context.obj(rectArray);

  // Get the target page (verify targetPageIndex is correct)
  const targetPage = pdfDoc.getPages()[targetPageIndex];
  if (!targetPage) {
    console.error("Target page not found for index:", targetPageIndex);
    return;
  }
  const targetPageRef = targetPage.ref;

  // Create a GoTo action dictionary
  const actionDict = pdfDoc.context.obj({
    Type: 'Action',
    S: 'GoTo',
    D: [targetPageRef, PDFName.of('Fit')],
  });

  // Create the link annotation dictionary
  const linkAnnotationDict = pdfDoc.context.obj({
    Type: 'Annot',
    Subtype: 'Link',
    Rect: rect,
    Border: [0, 0, 0],
    A: actionDict,
  });

  // Retrieve existing annotations (if any)
  let annots = page.node.get(PDFName.of('Annots'));
  if (!annots) {
    annots = pdfDoc.context.obj([]);
    page.node.set(PDFName.of('Annots'), annots);
  }
  // Push the new annotation into the annotations array.
  annots.push(linkAnnotationDict);
}

// Helper function to resize an image using a canvas and return an ArrayBuffer.
// This function downsizes the image if it exceeds the desired maximum width without compressing it.
async function resizeImage(imageBlob) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(imageBlob);
    img.onload = () => {
      const MAX_WIDTH = 612; // Letter size paper width in pixels
      let width = img.width;
      let height = img.height;
      if (width > MAX_WIDTH) {
        height = Math.floor(height * (MAX_WIDTH / width));
        width = MAX_WIDTH;
      }
      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            const blobSize = blob.size / (1024 * 1024);
            const quality = blobSize > 1 ? 0.5 : 1;
            canvas.toBlob(
              (finalBlob) => {
                if (finalBlob) {
                  finalBlob.arrayBuffer().then(resolve).catch(reject);
                } else {
                  reject(new Error("Canvas resizing failed"));
                }
              },
              'image/png', quality
            );
          } else {
            reject(new Error("Canvas resizing failed"));
          }
        },
        'image/png', 1
      );
    };
    img.onerror = reject;
    img.src = objectUrl;
  });
}
