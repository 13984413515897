import AirtableTable from "../../AirtableTable";

export class SchoolsTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Schools");

    this.fields = {
      "School": "fldv4Ph8Z8H0taJeh",
      "City/Community": "fldjIf3aqZI2Kpnag",
      "P/T": "fld8IfXT5n7Y435Kw",
      "Students": "fldIyY0iFy0G7T4Jt",
      "SG": "fldhpVgSYhlZ4Q7N2",
      "EG": "fldCFkdWdfQY07KQI",
    };
  }

  getSchool(schoolId) {
    return this.select(schoolId);
  }

  getSchoolByName(schoolName) {
    return this.selectOneByCondition({ filterByFormula: `{School} = '${schoolName}'` });
  }
}
