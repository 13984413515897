import React, { useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { stripEmptyData } from "../../utils/reportsBar";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ReportsBarGraph = ({
  label: rawLabel,
  primaryData: rawPrimaryData,
  secondaryData: rawSecondaryData,
  tertiaryData: rawTertiaryData,
  showdata2 = false,
  showdata3 = false,
  primaryDataLabel = "Primary Data",
  secondaryDataLabel = "Secondary Data",
  tertiaryDataLabel = "Tertiary Data",
  title = "",
  displayLegend = true,
  stripEmpty = true,
}) => {

  const { labels, data, secondaryData, tertiaryData } = useMemo(
    () =>
      stripEmptyData(
        rawLabel,
        rawPrimaryData,
        rawSecondaryData,
        rawTertiaryData,
        stripEmpty
      ),
    [rawLabel, rawPrimaryData, rawSecondaryData, rawTertiaryData, stripEmpty]
  );

  const chartData = useMemo(() => {
    const datasets = [
      {
        label: primaryDataLabel,
        fill: true,
        backgroundColor: "#63ABFD",
        borderColor: "#63ABFD",
        borderWidth: 2,
        data,
      },
    ];

    if (showdata2) {
      datasets.push({
        label: secondaryDataLabel,
        fill: true,
        backgroundColor: "#E697FF",
        borderColor: "#E697FF",
        borderWidth: 2,
        data: secondaryData,
      });
    }

    if (showdata3) {
      datasets.push({
        label: tertiaryDataLabel,
        fill: true,
        backgroundColor: "#FFB947",
        borderColor: "#FFB947",
        borderWidth: 2,
        data: tertiaryData,
      });
    }

    return {
      labels,
      datasets,
    };
  }, [labels, data, secondaryData, tertiaryData, showdata2, showdata3, primaryDataLabel, secondaryDataLabel, tertiaryDataLabel]);

  const options = useMemo(() => ({
    responsive: true,
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          font: { weight: "400", size: 14, family: "Effra, sans-serif" },
          color: "#0D1120",
        },
      },
      y: {
        grid: { display: false },
        ticks: {
          font: { weight: "400", size: 14, family: "Effra, sans-serif" },
          color: "#0D1120",
        },
      },
    },
    plugins: {
      legend: {
        display: displayLegend,
        position: "bottom",
        align: "start",
        labels: {
          font: { weight: "400", size: 14, family: "Effra, sans-serif" },
          color: "#0D1120",
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 5,
          boxHeight: 5,
        },
      },
      title: {
        display: Boolean(title),
        text: title,
        position: "left",
        font: { weight: "500", size: 14, family: "Effra, sans-serif" },
        color: "#0D1120",
      },
    },
  }), [displayLegend, title]);

  return <Bar data={chartData} options={options} />;
};

export default ReportsBarGraph;
