import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Button } from 'reactstrap';
import { RepeatableAccordion } from '../components/RepeatableAccordion';
import { PrevNextButton } from '../../PrincipalReporting/components/PrevNextButton';
import { Box, Radio, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const formatDate = date => {
  const d = dayjs(date).tz('America/Toronto');
  return d.format('MMMM D, YYYY');
};

const DateLabel = ({label, value}) => (
  <Box sx={{ mb: 2, '&:last-child': { mb: 0 } }}>
    <Typography component="span" sx={{ fontSize: '1.1rem', fontWeight: 700, color: '#0D1120' }}>{label}:</Typography>
    <Typography component="span" sx={{ ml: 1, fontSize: '1.1rem', color: '#2b4f70' }}>{formatDate(value)}</Typography>
  </Box>
);

const CalendarOption = ({ dates }) => {
  const { start_days, end_days } = dates;
  useEffect(() => {
    document.title = "Start & End Dates - School Calendar - Connected North";
  }, []);

  return (
    <Box sx={{ p: 2, pt: 0 }}>
      {['leader', 'staff', 'student'].map(role => (
        <DateLabel label={`First Day ${role.charAt(0).toUpperCase() + role.slice(1)}`} value={start_days[role]} />
      ))}
      <Box sx={{ borderTop: '1px solid #96ddf8', my: 2 }} />
      {['leader', 'staff', 'student'].map(role => (
        <DateLabel label={`Last Day ${role.charAt(0).toUpperCase() + role.slice(1)}`} value={end_days[role]} />
      ))}
    </Box>
  );
};

const SchoolCalendar = ({year, options, custom, selectedCalendar, setSelectedCalendar, uploadCustomMotion, motionUploaded, setError}) => {
  const [selectedOption, setSelectedOption] = useState('option-1');
  const [expanded, setExpanded] = useState(selectedOption);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef();
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    if(!selectedCalendar) return;

    const isMatchingCalendarDates = (start_days, end_days) => (
      selectedCalendar["First Day (Principal)"] === start_days.leader &&
      selectedCalendar["First Day (Students)"] === start_days.student &&
      selectedCalendar["First Day (Teachers)"] === start_days.staff &&
      selectedCalendar["Last Day (Students)"] === end_days.student &&
      selectedCalendar["Last Day (Teachers)"] === end_days.staff &&
      selectedCalendar["Last Day (Principal)"] === end_days.leader
    );

    const validateDates = () => {
      const principalFirstDay = dayjs(selectedCalendar["First Day (Principal)"]);
      const teacherFirstDay = dayjs(selectedCalendar["First Day (Teachers)"]);
      const studentFirstDay = dayjs(selectedCalendar["First Day (Students)"]);
      const principalLastDay = dayjs(selectedCalendar["Last Day (Principal)"]);
      const teacherLastDay = dayjs(selectedCalendar["Last Day (Teachers)"]);
      const studentLastDay = dayjs(selectedCalendar["Last Day (Students)"]);

      const newErrorMessages = {};

      if (!teacherFirstDay.isAfter(principalFirstDay)) {
        newErrorMessages.teachersFirstDay = "Teacher first day must be AFTER Principal first day.";
      }
      if (!studentFirstDay.isAfter(teacherFirstDay)) {
        newErrorMessages.studentsFirstDay = "Student first day must be AFTER Teacher first day.";
      }
      if (!principalLastDay.isAfter(teacherLastDay)) {
        newErrorMessages.teachersLastDay = "Principal last day must be AFTER Teacher last day.";
      }
      if (!teacherLastDay.isAfter(studentLastDay)) {
        newErrorMessages.studentsLastDay = "Teacher last day must be AFTER Student last day.";
      }

      setErrorMessages(newErrorMessages);
      setError(Object.keys(newErrorMessages).length > 0);
    };

    validateDates();

    for (const option of options) {
      const yearData = option.years[year];
      if (!yearData) continue;

      const { start_days, end_days } = yearData;
      if (isMatchingCalendarDates(start_days, end_days)) {
        setSelectedOption(option.id);
        return;
      }
    }
    setSelectedOption("custom");
  }, [selectedCalendar, motionUploaded, options, year, setError, setSelectedOption]);

  useEffect(() => {
    setExpanded(selectedOption);
  }, [selectedOption]);

  useEffect(() => {
    if(motionUploaded) setIsUploading(false);
  }, [motionUploaded])

  const handleChange = (option) => {
    setSelectedOption(option.id);
    const yearData = option.years[year];
    const { start_days, end_days } = yearData;

    setSelectedCalendar({
      "First Day (Principal)": start_days.leader,
      "First Day (Students)": start_days.student,
      "First Day (Teachers)": start_days.staff,
      "Last Day (Students)": end_days.student,
      "Last Day (Teachers)": end_days.staff,
      "Last Day (Principal)": end_days.leader
    });
  }

  const selectCustomOption = () => setSelectedOption("custom");

  const onDateChange = (key, value) => {
    setSelectedCalendar({
      ...selectedCalendar,
      [key]: value ? value.tz('America/Toronto').format('YYYY-MM-DD') : null
    });
  }

  return (
    <Box sx={{ maxWidth: '800px', m: 'auto', pt: "1rem" }}>
      {options.map((option) => (
        <Box key={option.id} sx={{ mb: 2 }}>
          <RepeatableAccordion
            header={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  checked={selectedOption === option.id}
                  sx={{
                    mr: 2,
                    color: '#1976d2',
                    '&.Mui-checked': {
                      color: '#1976d2',
                    },
                  }}
                />
                {option.header}
              </Box>
            }
            id={option.id}
            expanded={expanded === option.id}
            onChange={() => handleChange(option)}
          >
            <CalendarOption dates={option.years[`${year}`]} />
          </RepeatableAccordion>
        </Box>
      ))}
      {
        (custom || selectedOption === "custom") &&
        <Box key="custom" sx={{ mb: 2 }}>
          <RepeatableAccordion
            header={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  checked={selectedOption === "custom"}
                  sx={{
                    mr: 2,
                    color: '#1976d2',
                    '&.Mui-checked': {
                      color: '#1976d2',
                    },
                  }}
                />
                Option 4: Custom Calendar
              </Box>
            }
            id="custom"
            expanded={expanded === "custom"}
            onChange={selectCustomOption}
          >
            <Box sx={{ p: 2, pt: 0 }}>
              {['Principal', 'Teachers', 'Students'].map(role => (
                <Box key={role} sx={{ mb: 2, '&:last-child': { mb: 0 }, display: "flex", gap: "1rem", alignItems: "center" }}>
                  <Typography component="span" sx={{ fontSize: '1.1rem', fontWeight: 700, color: '#0D1120' }}>
                    First Day {role}:
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={selectedCalendar?.[`First Day (${role})`] ? dayjs(selectedCalendar[`First Day (${role})`]) : null}
                      onChange={(newValue) => onDateChange(`First Day (${role})`, newValue)}
                      slotProps={{
                        textField: {
                          helperText: errorMessages[`${role.toLowerCase()}FirstDay`] || "",
                          error: !!errorMessages[`${role.toLowerCase()}FirstDay`],
                        },
                      }}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </Box>
              ))}
              <Box sx={{ borderTop: '1px solid #96ddf8', my: 2 }} />
              {['Principal', 'Teachers', 'Students'].map(role => (
                <Box key={role} sx={{ mb: 2, '&:last-child': { mb: 0 }, display: "flex", gap: "1rem", alignItems: "center" }}>
                  <Typography component="span" sx={{ fontSize: '1.1rem', fontWeight: 700, color: '#0D1120' }}>
                    Last Day {role}:
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={selectedCalendar?.[`Last Day (${role})`] ? dayjs(selectedCalendar[`Last Day (${role})`]) : null}
                      onChange={(newValue) => onDateChange(`Last Day (${role})`, newValue)}
                      slotProps={{
                        textField: {
                          helperText: errorMessages[`${role.toLowerCase()}LastDay`] || "",
                          error: !!errorMessages[`${role.toLowerCase()}LastDay`],
                        },
                      }}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </Box>
              ))}

              <Box key="Motion" sx={{ mt: 5, mb: 2, '&:last-child': { mb: 0 }, display: "flex", gap: "1rem", flexDirection: "column", alignItems: "start" }}>
                <Typography component="span" sx={{ fontSize: '1.1rem', fontWeight: 700, color: '#0D1120' }}>
                  Please upload a motion from your local DEA approving this custom schedule*:
                </Typography>
                <input
                  type="file"
                  accept="application/pdf"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(e) => {uploadCustomMotion(e); setIsUploading(true);}}
                />
                <div className="image-upload-button">
                  <Button onClick={() => fileInputRef.current.click()} type="button" color="primary" disabled={isUploading}>
                    {isUploading ? "Uploading..." : (motionUploaded ? "Uploaded" : "Upload Motion") }
                  </Button>
                </div>
              </Box>
            </Box>
          </RepeatableAccordion>
        </Box>
      }
    </Box>
  );
};

const StartDates = ({year, options, selectedCalendar, custom, setSelectedCalendar, uploadCustomMotion, motionUploaded, handleNext, setError, disable = false}) => {
  const history = useHistory();
  return (
    <>
      <h2>Select your Start Dates</h2>
      <p className='mt-4'>Below, you'll see the three date options provided as options for your school calendar. Please select which start date option you'll be using for your school year, and click Next to continue onto the hours of instruction for each grade grouping.</p>
      <Form>
        <FormGroup>
          <span className='font-weight-bold mb-2 text-dark'>Duration</span>
          <SchoolCalendar year={year} options={options} custom={custom} selectedCalendar={selectedCalendar} setSelectedCalendar={setSelectedCalendar} uploadCustomMotion={uploadCustomMotion} motionUploaded={motionUploaded} setError={setError} />
        </FormGroup>
        <Box sx={{ marginTop: { xs: "1.25rem", md: "1.875rem" }, cursor: disable ? "progress" : "auto" }}>
          <PrevNextButton
            onNextClick={handleNext}
            onPrevClick={() => history.push('/cn/schoolcalendar')}
          />
        </Box>
      </Form>
    </>
  )
}

export default StartDates
