import React, { useEffect, useState } from "react";
import Airtable from "../../airtables";
import LoadingBar from "../../components/LoadingBar";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { setMyFavorites } from "../../redux/actions";
import CurriculumSuggestion from "../modals/CurriculumSuggestion";
import { SessionSideBar } from "./components/SessionSideBar";
import { SessionBio } from "./components/SessionBio";
import { SessionContent } from "./components/SessionContent";
import BookSessionModal from "../modals/BookSession";
import AssignTagForSession from "../modals/AssignTagForSession";
import SendToTeacher from "../modals/SendToTeacher";
import { useQuery } from "../../useQuery";
import { setRequestSessionModal } from "../../redux/modalActions";
import { getDayRestrictionConfig } from "../../utils/constants";
import { getFullDateString } from "../../utils/time";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import "./styles.scss";

const SessionDetail = (props) => {
  const sessionId = props.match.params.sessionId;
  const {
    userType,
    userInfo,
    regionsMap,
    myFavorites,
    totalRegions
  } = useSelector((state) => state.appInfo);

  const query = useQuery();

  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);
  const [provider, setProvider] = useState(null);
  const [grades, setGrades] = useState([]);
  const [requestModal, showRequestModal] = useState(false);

  const [reviews, setReviews] = useState([]);
  const [videos, setVideos] = useState([]);
  const [supply, setSupply] = useState(null);

  const [curriculumLinks, setCurriculumLinks] = useState([]);
  const [otherSessions, setOtherSessions] = useState([]);
  const [similarSessions, setSimilarSessions] = useState([]);
  const [openSuggestModal, setOpenSuggestModal] = useState(false);
  const [openBookModal, setOpenBookModal] = useState(false);
  const [showAssignTagModal, setShowAssignTagModal] = useState(false);
  const [showSendToTeacherModal, setShowSendToTeacherModal] = useState(false);
  const [favoriteState, setFavoriteState] = useState("loading");

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (requestModal && session) {
      dispatch(setRequestSessionModal({
        visible: true,
        providerSession: session,
        dayRestrictionConfig: getDayRestrictionConfig(supply),
        onSubmitted: () => {
          dispatch(setRequestSessionModal({}));
          showRequestModal(false)
        },
        onClose: () => {
          showRequestModal(false);
        }
      }))
    }
  }, [requestModal, session]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const rm = query.get("requestmodal");
    if (rm === 'true') showRequestModal(true)

    refreshSession();
  }, [sessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (totalRegions.length === 0) return;
    if (!session || !session["Curriculum Links"]) {
      return;
    }

    (async () => {
      try {
        /** @type {string[]} */
        const regions = userInfo["School Province or Territory"];
        /** @type {string[]} */
        const sessionIDs = session["Curriculum Links"];

        const links = await Airtable.curriculumLinks.getLinksByIdsAndRegions(sessionIDs, regions);
        const sessions = await Airtable.providerSessions.getActiveSessionsByIds(links[0]["Sessions"]);

        const filteredSessions = sessions.filter(s => s.id !== session.id);

        setCurriculumLinks(links);
        setSimilarSessions(filteredSessions);
      } catch (e) {
        console.error(e);
      } finally { }
    })();
  }, [session, regionsMap]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userType === "Teacher") {
      setFavoriteState(
        myFavorites.indexOf(sessionId) >= 0 ? "favorite" : "unfavorite"
      );
    }
  }, [myFavorites, sessionId, userType]);

  const refreshSession = async () => {
    const sess = await Airtable.providerSessions.select(sessionId);
    if (sess.Supplies && sess.Supplies.length) {
      const sp = await Airtable.supplies.select(sess["Supplies"][0]);
      setSupply(sp);
    } else {
      setSupply(null);
    }

    document.title = sess
      ? `${sess["Session Title"]} - ${sess["Provider Name Text"]} - Connected North`
      : "";
    setSession(sess);

    if (sess) {
      if (sess["Grade(s)"] && sess["Grade(s)"].length) {
        const gs = [...sess["Grade(s)"]];
        gs.sort((a, b) => {
          const x = a === "K" ? 0 : parseInt(a);
          const y = b === "K" ? 0 : parseInt(b);
          if (x > y) return 1;
          return -1;
        });
        setGrades(gs);
      }
      if (
        sess["Provider Name Text"] &&
        sess["Provider Name Text"].length > 0
      ) {
        const sp = await Airtable.providers.select(sess["Provider"][0]);
        setProvider(sp);

        const ss = await Airtable.providerSessions.getProviderSessions(
          sess["Provider"][0],
          sess["Provider Name Text"][0],
          { field: "Session Title", direction: "asc" },
          true
        );
        if (ss && ss.length) {
          setOtherSessions(ss.filter((s) => s.id !== session?.id));
        }
      }
    }
    setLoading(false);

    if (sess?.Reviews && sess?.Reviews.length) {
      for (let rId of sess.Reviews) {
        const review = await Airtable.reviews.select(rId);
        const newReviews = [...reviews, review];
        setReviews(newReviews);
      }
    }
    if (sess?.["Resource Videos"] && sess?.["Resource Videos"].length) {
      const vs = await Airtable.videos.getVideosByIds(sess["Resource Videos"]);
      setVideos(vs);
    }
  };

  const toggleFavorite = async () => {
    if (favoriteState === "loading") return;

    if (favoriteState === "favorite") {
      setFavoriteState("loading");
      const favorites1 = [...myFavorites].filter((v) => v !== session.id);
      await Airtable.teachers.update(userInfo["id"], {
        Favourites: favorites1,
      });

      setFavoriteState("unfavorite");
      dispatch(setMyFavorites(favorites1));
    } else if (favoriteState === "unfavorite") {
      setFavoriteState("loading");
      const favorites2 = [...myFavorites, session.id];
      await Airtable.teachers.update(userInfo["id"], {
        Favourites: favorites2,
      });
      setFavoriteState("favorite");
      dispatch(setMyFavorites(favorites2));
    }
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ minHeight: 500 }}>
          <LoadingBar />
        </div>
      );
    }

    if (!session) {
      return (
        <div
          style={{ minHeight: 500 }}
          className="nolist-placeholder"
          align="center"
        >
          <span>{t("session-link-invalid")}</span>
        </div>
      );
    }

    return (
      <SessionContent
        session={session}
        provider={provider}
        otherSessions={otherSessions.filter((s) => s.id !== session.id)}
        similarSessions={similarSessions}
        reviews={reviews}
        videos={videos}
        supply={supply}
        curriculumLinks={curriculumLinks}
        setOpenSuggestModal={setOpenSuggestModal}
        showBookSessionModal={() => setOpenBookModal(true)}
      />
    );
  };

  const renderSessionStatus = () => {
    if (!supply) return null;

    let warnings = [];

    if (session["Availability End Date"]) {
      warnings.push(
        <div key="availability" className="session-warning warn-msg-red">
          <span>
            <AccessTimeIcon style={{ fontSize: '1.1em', marginRight: '4px', verticalAlign: 'text-bottom' }} />
            The Content Provider has made this Session available until {getFullDateString(session["Availability End Date"])}
          </span>
        </div>
      );
    }

    if (
      supply?.["Essential?"] === "Yes" &&
      (supply?.Status === "Sioux Lookout Storage" || supply?.Status === "Toronto Office Storage" || supply?.Status === "Ottawa Storage") &&
      (supply?.["Quantity Remaining"] || 0) === 0
    ) {
      warnings.push(
        <div key="supply" className="session-warning">
          <span>{t("session-warning1")}</span>
        </div>
      );
    }

    return warnings.length > 0 ? warnings : null;
  };

  if (!session) return null;

  return (
    <div className="main-container">
      {renderSessionStatus()}
      <Row className="mt-5">
        <SessionSideBar session={session} grades={grades} />
        <SessionBio
          session={session}
          sessionId={sessionId}
          toggleFavorite={toggleFavorite}
          favoriteState={favoriteState}
          loading={loading}
          requestSession={() => showRequestModal(true)}
          showBookSessionModal={() => setOpenBookModal(true)}
          showAssignTagModal={setShowAssignTagModal}
          showSendToTeacher={setShowSendToTeacherModal}
        />
      </Row>
      {renderContent()}
      {openSuggestModal && (
        <CurriculumSuggestion
          session={session}
          onToggle={() => setOpenSuggestModal(!openSuggestModal)}
          onSuggest={() => {
            setOpenSuggestModal(false);
            refreshSession();
          }}
        />
      )}

      {openBookModal && (
        <BookSessionModal
          providerSessionId={session.id}
          onToggle={() => setOpenBookModal(false)}
        />
      )}

      {showAssignTagModal && (
        <AssignTagForSession
          session={session}
          onToggle={() => setShowAssignTagModal(!showAssignTagModal)}
        />
      )}

      {showSendToTeacherModal && (
        <SendToTeacher
          data={session}
          onToggle={() => setShowSendToTeacherModal(!showSendToTeacherModal)}
          type="Session"
        />
      )}
    </div>
  );
};

export default SessionDetail;
