import * as React from "react";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export const NumberMuiInput = React.forwardRef(
	function CustomNumberInput(props, ref) {
		const {
			unit,
			filledInput,
			onInputChange,
			value = "",
			error = false,
			disabled,
			placeholder,
			sx = {},
			...otherProps
		} = props;

		const handleInputChange = (e) => {
			const inputValue = e.target.value;
			if (onInputChange) {
				onInputChange(e, inputValue);
			}
		};

		return (
			<StyledTextField
				type="number"
				value={
					value === "" || value === null || value === undefined ? "" : value
				}
				onChange={handleInputChange}
				placeholder={placeholder}
				disabled={disabled}
				error={error}
				InputProps={{
					startAdornment:
						unit === "$" ? (
							<InputAdornmentStyled position="start">
								{unit}
							</InputAdornmentStyled>
						) : null,
					endAdornment:
						unit && unit !== "$" ? (
							<InputAdornmentStyled position="end">{unit}</InputAdornmentStyled>
						) : null,
				}}
				className={`${filledInput ? "filledInput" : ""} ${
					error ? "base--error" : ""
				}`}
				sx={sx}
				ref={ref}
				{...otherProps}
			/>
		);
	},
);

const StyledTextField = styled(TextField)(({ theme }) => ({
	"& .MuiInputBase-root": {
		border: "none",
		backgroundColor: "#fff",
		boxSizing: "border-box",
		padding: "0.5rem 0.75rem",
		"&:hover:not(.Mui-disabled)": {
			borderColor: "#005fa8",
		},
		"&.Mui-focused": {
			borderColor: "#0E6BA8",
			boxShadow: "0 0 8px rgba(14, 107, 168, 0.5)",
		},
		"&.Mui-error": {
			borderColor: "#EB5757",
		},
	},

	"& .MuiInputBase-input": {
		border: "none",
		padding: "0.75rem",
		backgroundColor: "transparent",
	},

	"&.filledInput .MuiInputBase-root": {
		backgroundColor: "#F3F9FF",
		border: "none",
	},

	"&.emptyInput .MuiInputBase-root": {
		backgroundColor: "#fff",
	},

	"&.base--error .MuiInputBase-root": {
		backgroundColor: "#FFEBEB",
	},
}));

const InputAdornmentStyled = styled(InputAdornment)(({ theme }) => ({
	margin: "0 0.5rem",
	display: "flex",
	alignItems: "center",
}));
