import React, { useState, useEffect } from "react";
import InputWrapper from "./InputWrapper";
import { NumberMuiInput } from "./NumberMui";

const NumberInput = ({
	id,
	max_value,
	min_value = 0,
	decimal_places,
	disabled,
	value = "",
	onChange = () => {},
	error = false,
	unit,
	sx = {},
	heading,
	description,
	placeholder,
	helperText,
}) => {
	const [inputValue, setInputValue] = useState(value);
	const [inputError, setInputError] = useState(error);

	useEffect(() => {
		if (value !== inputValue) {
			setInputValue(
				value === null || value === undefined ? "" : value === 0 ? "0" : value.toString(),
			);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const filledInput =
		inputValue !== "" && inputValue !== null && inputValue !== undefined;

	const handleInputChange = (e, val) => {
		if (val === "" || val === null || val === undefined) {
			setInputValue("");
			setInputError(false);
			onChange("");
			return;
		}

		let newValue = val.replace(/[^0-9.]/g, "");

		const decimalCount = (newValue.match(/\./g) || []).length;
		if (decimalCount > 1) {
			return;
		}

		let numericValue = parseFloat(newValue);

		if (isNaN(numericValue) || numericValue < min_value) {
			numericValue = min_value;
		}

		if (decimal_places !== null && decimal_places >= 0) {
			numericValue =
				Math.floor(numericValue * Math.pow(10, decimal_places)) /
				Math.pow(10, decimal_places);
		}

		if (max_value !== null && numericValue > max_value) {
			numericValue = max_value;
		}

		newValue = numericValue.toString();

		setInputValue(newValue);
		setInputError(false);
		onChange(newValue);
	};

	return (
		<InputWrapper
			title={heading}
			description={description}
			id={id}
			inputError={inputError}
			filledInput={filledInput}
			helperText={helperText}
		>
			<NumberMuiInput
				id={id}
				name={id}
				aria-describedby="helper-text"
				decimal_places={decimal_places}
				min={min_value}
				max={max_value}
				value={inputValue || ""}
				sx={{
					maxWidth: "12rem",
					...sx,
				}}
				placeholder={placeholder}
				onInputChange={(e) => handleInputChange(e, e.target.value)}
				error={inputError}
				filledInput={filledInput}
				unit={unit}
				disabled={disabled}
			/>
		</InputWrapper>
	);
};

export default NumberInput;
